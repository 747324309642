import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  openNewPasswordModal,
  openTimeoutMessageModal,
} from '../../../actions/modals_actions';
import { saveRouteData } from '../../../actions/global_actions';

class ResetPassword extends Component {
  componentDidMount() {
    const { route, match, location } = this.props;
    /* save global route data */
    this.props.saveRouteData(route, match, location);

    /* We extract just the apploi token from query string and send it to
    NewPasswordModal so we can reset the password */
    const queryString = this.props.location.search;
    let token = null;
    if (queryString.indexOf('token=') >= 0) {
      token = queryString.substring(queryString.indexOf('=') + 1);
    }

    if (token) {
      this.props.openNewPasswordModal({ token });
    } else {
      this.props.openTimeoutMessageModal({
        headerMessage: 'ERROR',
        bodyMessage: 'No password reset token present!',
        footerMessage: null,
        timeout: null,
        pageReload: false,
      });
    }
  }

  render() {
    return <Fragment />;
  }
}

const mapStateToProps = (state) => ({});

ResetPassword.propTypes = {
  location: PropTypes.object, // route prop (from react-router). Represent where the app is now.
  match: PropTypes.object, // route prop (from react-router). Contains information about how a <Route path> matched the URL. match objects.
  openNewPasswordModal: PropTypes.func, // used to open NewPasswordModal
  route: PropTypes.object, // comes from react-router-config. We are free to add any additional props we would like to a route inside of 'router.js'.
  saveRouteData: PropTypes.func, // save to redux global route data that we get from react-router and react-router-config to use it across the app.
  openTimeoutMessageModal: PropTypes.func, // used to open TimeoutMessageModal.
};

export default connect(mapStateToProps, {
  saveRouteData,
  openNewPasswordModal,
  openTimeoutMessageModal,
})(ResetPassword);
