import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid, Icon } from 'semantic-ui-react';
import Scroll from 'react-scroll';
import * as Styled from 'jobDescriptionFullApply_styled';
import JobDescriptionFullApplyForm from 'JobDescriptionFullApplyForm';
import JobDescriptionFullApplyDescription from 'JobDescriptionFullApplyDescription';
import { withTranslation } from 'react-i18next';
import ErrorBoundary from '../../../../sharedComponents/errorPages/ErrorBoundary';

const JobDescriptionFullApplyFormScroll = Scroll.Element;

class JobDescriptionFullApply extends Component {
  state = {
    hidedescriptioncolumnmobile: false,
    hideformcolumnmobile: false,
    position: 'leftSideExpanded',
    firstColumn: '2',
    secondColumn: '12',
    thirdColumn: '1',
    fourthColumn: '1',
    columnName: {
      descriptionColumn: 'block',
      formColumn: 'none',
    },
  };

  expandColumns(position) {
    switch (position) {
      case 'rightSideButtonControl':
        this.setState(
          (prevState) => ({
            position:
              prevState.position === 'leftSideExpanded'
                ? 'equal'
                : 'leftSideExpanded',
            firstColumn: prevState.firstColumn === '2' ? '1' : '2',
            secondColumn: prevState.secondColumn === '12' ? '7' : '12',
            thirdColumn: prevState.thirdColumn === '1' ? '7' : '1',
          }),
          () => {
            if (this.state.position !== 'equal') {
              setTimeout(() => {
                this.hideColumnText('formColumn');
              }, 1000);
            } else {
              this.hideColumnText('formColumn');
            }
          },
        );
        break;

      case 'leftSideButtonControl':
        this.setState((prevState) => ({
          position:
            prevState.position === 'rightSideExpanded'
              ? 'equal'
              : 'rightSideExpanded',
          secondColumn: prevState.secondColumn === '7' ? '2' : '7',
          thirdColumn: prevState.thirdColumn === '7' ? '11' : '7',
          fourthColumn: prevState.fourthColumn === '1' ? '2' : '1',
        }));
        break;

      default:
    }
  }

  /* Desktop dynamic expand */

  hideColumnText(column) {
    const columnName = {
      descriptionColumn: 'block',
      formColumn: 'none',
    };

    if (
      typeof window !== 'undefined' &&
      window.screen &&
      window.screen.width &&
      window.screen.width >= 768
    ) {
      if (column === 'descriptionColumn') {
        if (this.state.position === 'rightSideExpanded') {
          columnName.descriptionColumn = 'none';
          columnName.formColumn = 'block';
        } else {
          columnName.descriptionColumn = 'block';
          columnName.formColumn = 'block';
        }
      }

      if (column === 'formColumn') {
        if (this.state.position === 'leftSideExpanded') {
          columnName.descriptionColumn = 'block';
          columnName.formColumn = 'none';
        } else {
          columnName.descriptionColumn = 'block';
          columnName.formColumn = 'block';
        }
      }

      this.setState({
        columnName,
      });
    }

    return columnName;
  }

  /* Mobile dynamic expand */

  hideDescriptionColumnMobile() {
    this.setState((prevState) => ({
      hidedescriptioncolumnmobile: !prevState.hidedescriptioncolumnmobile,
    }));
  }

  hideFormColumnMobile() {
    this.setState((prevState) => ({
      hideformcolumnmobile: !prevState.hideformcolumnmobile,
    }));
  }

  render() {
    const { t } = this.props;
    return (
      <Grid stackable>
        <ErrorBoundary tags={{ component: 'JobDescriptionFullApply' }}>
          <Styled.ComponentRow>
            {/* First Column
            ===================== */}

            <Styled.ColumnContainer
              width={this.state.firstColumn}
              only="tablet computer"
            >
              <Styled.InnerContainer>&nbsp;</Styled.InnerContainer>
            </Styled.ColumnContainer>

            {/* Second Column
            ===================== */}

            <Styled.DescriptionColumnContainer
              width={this.state.secondColumn}
              hidedescriptioncolumnmobile={this.state.hidedescriptioncolumnmobile.toString()} // must send string to styled components or we get error
              position={this.state.position}
            >
              {this.state.position !== 'leftSideExpanded' ? (
                <Styled.LeftSideButtonControl
                  onClick={() => {
                    if (this.state.position !== 'leftSideExpanded') {
                      this.expandColumns('leftSideButtonControl');
                    } else {
                      this.expandColumns('rightSideButtonControl');
                    }
                  }}
                >
                  {this.state.position !== 'rightSideExpanded' ? (
                    <div>
                      <Icon name="caret up" />
                      {t('collapse')}
                    </div>
                  ) : (
                    <div>
                      <Icon name="caret down" />
                      {t('expand')}
                    </div>
                  )}
                </Styled.LeftSideButtonControl>
              ) : null}

              <Styled.ReduceInnerContainer
                descriptionColumn={this.state.columnName.descriptionColumn}
                descriptionColumnMobile={this.state.hidedescriptioncolumnmobile.toString()}
              >
                <Grid.Row columns={2}>
                  <Grid.Column>
                    <JobDescriptionFullApplyDescription
                      position={this.state.position}
                      handleExpandColumns={(prop) => this.expandColumns(prop)}
                      matchData={this.props.matchData}
                      interviewGuide={this.props.interviewGuide}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Styled.ReduceInnerContainer>
            </Styled.DescriptionColumnContainer>

            {/* Third Column
            ===================== */}
            <Styled.FormColumnContainer
              width={this.state.thirdColumn}
              position={this.state.position}
            >
              <Styled.ReduceInnerContainer
                formColumn={this.state.columnName.formColumn}
                formColumnMobile={this.state.hideformcolumnmobile.toString()}
              >
                <Grid.Row>
                  <ErrorBoundary
                    sectionName="Apply Form"
                    tags={{ component: 'JobDescriptionFullApplyForm' }}
                  >
                    <Grid.Column>
                      <JobDescriptionFullApplyFormScroll name="jobDescriptionFullApplyFormScroll" />
                      <JobDescriptionFullApplyForm
                        interviewGuide={this.props.interviewGuide}
                      />
                    </Grid.Column>
                  </ErrorBoundary>
                </Grid.Row>
              </Styled.ReduceInnerContainer>
            </Styled.FormColumnContainer>
            {/* Fourth Column
            ===================== */}
            <Styled.ColumnContainer
              width={this.state.fourthColumn}
              only="tablet computer"
            >
              <Styled.InnerContainer>&nbsp;</Styled.InnerContainer>
            </Styled.ColumnContainer>
          </Styled.ComponentRow>
        </ErrorBoundary>
      </Grid>
    );
  }
}

JobDescriptionFullApply.propTypes = {
  matchData: PropTypes.object, // comes from parent 'JobDescription' component. It's a route prop 'match' coming from react-router.
  interviewGuide: PropTypes.bool, // tells us if we should show resume.
};

export default withTranslation('jobDescriptionFullApply')(
  JobDescriptionFullApply,
);
