import React from 'react';
import { useSelector } from 'react-redux';
import { JobDescriptionField } from 'jobDescriptionField_styled';
import { useTranslation } from 'react-i18next';

const experience = {
  0: 'none',
  1: '1 year',
  2: '2 years',
  3: '3 years',
  4: '4 years',
  5: '5 years',
  6: '6 years',
  7: '7 years',
  8: '8 years',
  9: '9 years',
  10: '10+ years',
};

const JobDescriptionExperience = (props) => {
  const { t } = useTranslation('jobExperience');
  const jobDetail = useSelector(
    (state) => state.jobDescriptionReducer.jobDescription?.job_detail,
  );
  const years = jobDetail?.years_of_experience;

  return (
    <JobDescriptionField>
      <h3>{t('experienceTitle')}</h3>
      <p>
        {years === 0
          ? t('none')
          : `${years < 10 ? years : `${years}+`} ${years > 1 ? t('years') : t('year')}`}
      </p>
    </JobDescriptionField>
  );
};

export default JobDescriptionExperience;
