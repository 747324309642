import React from 'react';

const ErrorShieldIcon = () => {
  return (
    <svg
      width="32"
      height="40"
      viewBox="0 0 32 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 6.29907L15.88 0L31.76 6.29907V19.6559C31.7612 23.064 30.8649 26.4123 29.1614 29.3641C27.4578 32.3159 25.0071 34.7671 22.0556 36.4711L15.88 40L9.70445 36.4711C6.75296 34.7671 4.30221 32.3159 2.59866 29.3641C0.895115 26.4123 -0.0011515 23.064 0 19.6559V6.29907Z"
        fill="#DF3826"
      />
      <path
        d="M12.4563 13.851L10.6919 15.6155L14.1149 19.0208L10.7095 22.4439L12.474 24.2083L15.8794 20.7676L19.3024 24.1907L21.0668 22.4262L17.6438 19.0208L21.0668 15.5978L19.3024 13.8334L15.8794 17.274L12.4563 13.851Z"
        fill="white"
      />
    </svg>
  );
};

export default ErrorShieldIcon;
