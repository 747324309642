import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import * as Styled from 'getVerified_styled';
import { useTranslation } from 'react-i18next';
import SuccessShieldIcon from '../../../../../../../../../public/assets/svg/SuccessShieldIcon';

const GetVerifiedNursys = (props) => {
  const { verificationType, verificationEngine } = props;

  const verificationEnginei18n =
    verificationEngine.charAt(0).toLowerCase() +
    verificationEngine.substring(1);
  const [state] = useState({
    icon: <SuccessShieldIcon />,
    titleText: `${verificationEnginei18n}TitleText`,
    subtitleText: `${verificationEnginei18n}SubtitleText`,
    descriptionText: `${verificationEnginei18n}DescriptionText`,
    footerTitle: `${verificationEnginei18n}FooterTitle`,
    footerText: `${verificationEnginei18n}FooterText`,
  });

  const handleChangeActiveStep = (activeStep) => {
    props.changeActiveStep(activeStep, null);
  };

  const { t } = useTranslation('getVerified');

  return (
    <Styled.GetVerifiedContainer data-testid="get-verified">
      <Styled.TitleText>{t(state.titleText)}</Styled.TitleText>
      <Styled.SubTitleText data-testid="subtitle">
        {t(state.subtitleText, {
          verificationType: verificationType.toUpperCase(),
        })}
      </Styled.SubTitleText>
      <Styled.IconContainer>
        <CSSTransition
          timeout={1500}
          classNames="status-icon-animation"
          in={!!state.icon}
        >
          <SuccessShieldIcon />
        </CSSTransition>
      </Styled.IconContainer>
      <Styled.DescriptionText>
        {t(state.descriptionText, { state: props.longStateName })}
      </Styled.DescriptionText>
      <Styled.ButtonContainer>
        <Styled.ButtonAction
          data-testid="get-verified-button"
          onClick={() => handleChangeActiveStep('licenseVerification')}
        >
          {t('buttonText')}
        </Styled.ButtonAction>
      </Styled.ButtonContainer>
      <Styled.FooterText>
        {t(state.footerTitle)}
        <br />
        {t(state.footerText)}
      </Styled.FooterText>
    </Styled.GetVerifiedContainer>
  );
};

GetVerifiedNursys.propTypes = {
  // used to change activeStep which changes the verification screen
  changeActiveStep: PropTypes.func,
  // lets us know wich verification type we are one (LPN, RN...)
  verificationType: PropTypes.string,
  // lets us know which verification engine we are Nursys or Streamline
  verificationEngine: PropTypes.string,
  longStateName: PropTypes.string, // full name of the state
};

export default GetVerifiedNursys;
