import styled from 'styled-components';
import { Image } from 'semantic-ui-react';

export const BrandLogo = styled(Image)`
  box-sizing: border-box;
  margin-right: 24px;

  @media (max-width: 991px) {
    height: 80px !important;
    width: 80px !important;
  }
`;
