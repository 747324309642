import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
/* Styled Components */
import * as Styled from 'jobDescriptionBrandName_styled';

class JobDescriptionBrandName extends Component {
  render() {
    const {
      jobDescription: { language, name, job_translation },
    } = this.props;
    return (
      <Styled.NameContainer>
        <Styled.JobName>
          {this.props.jobDescription && language && job_translation
            ? job_translation[`name_${language}`]
            : name}
        </Styled.JobName>
        <Styled.BrandName>
          {this.props.jobDescription && this.props.jobDescription.brand_name}
        </Styled.BrandName>
      </Styled.NameContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  jobDescription: state.jobDescriptionReducer.jobDescription,
});

JobDescriptionBrandName.propTypes = {
  jobDescription: PropTypes.object, // contains data about current active job.
};

export default connect(mapStateToProps, {})(JobDescriptionBrandName);
