import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import * as Styled from 'verificationError_styled';
import { useTranslation } from 'react-i18next';
import WarningTriangleIcon from '../../../../../../../../../public/assets/svg/WarningTriangleIcon';
import ErrorShieldIcon from '../../../../../../../../../public/assets/svg/ErrorShieldIcon';

const SVVerificationError = (props) => {
  const { verificationType, verificationStatus } = props;

  const handleChangeActiveStep = (activeStep) => {
    props.changeActiveStep(activeStep, null);
  };

  const { t } = useTranslation('svVerificationError');
  return (
    <Styled.VerificationErrorContainer data-testid="verification-error">
      <Styled.IconContainer>
        <CSSTransition timeout={1500} classNames="status-icon-animation" in>
          <div>
            {verificationStatus === 'error' ? (
              <WarningTriangleIcon />
            ) : (
              <ErrorShieldIcon />
            )}
          </div>
        </CSSTransition>
      </Styled.IconContainer>
      <Styled.TitleText>{t(`${verificationStatus}TitleText`)}</Styled.TitleText>
      <Styled.DescriptionText>
        {t('descriptionText', { verificationType })}
      </Styled.DescriptionText>
      <Styled.DescriptionText>
        {t(`${verificationStatus}FooterText`, { verificationType })}
      </Styled.DescriptionText>
      <Styled.ButtonContainer>
        <Styled.ButtonAction
          data-testid="try-again-button"
          onClick={() => handleChangeActiveStep('licenseVerification')}
        >
          {t('buttonText')}
        </Styled.ButtonAction>
      </Styled.ButtonContainer>
    </Styled.VerificationErrorContainer>
  );
};

SVVerificationError.propTypes = {
  //  used to change activeStep which changes the verification screen
  changeActiveStep: PropTypes.func,
  // lets us know which verification type we are one (LPN, RN...)
  verificationType: PropTypes.string,
  // lets us know which verification status we're Error or Not found
  verificationStatus: PropTypes.string,
};

export default SVVerificationError;
