import React, { Component, Fragment, createRef } from 'react';
import { connect } from 'react-redux';
import {
  Menu,
  Sidebar,
  Icon,
  Container,
  Ref,
  Dropdown,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import Scroll from 'react-scroll';
import { renderRoutes } from 'react-router-config';
import * as Styled from 'navigation_styled';
import AccountName from 'AccountName';
import { withTranslation } from 'react-i18next';
import queryString from 'query-string';
import routes from '../../../routes/routes';
import { openShareModal } from '../../../actions/modals_actions';
import {
  desktopDesign,
  onlyDesktopDesign,
} from '../../../helperFunctions/helperFunctions';

const { scroller } = Scroll;

class Navigation extends Component {
  state = {
    visible: false,
    parsedQueryString: null,
  };

  containerRef = createRef();

  componentDidMount() {
    // Extract all the query parameters from URL
    const parsedQueryString = queryString.parse(window.location.search);
    this.setState({
      parsedQueryString,
    });
  }

  onPusherClick = () => {
    const { visible } = this.state;
    if (visible) this.setState({ visible: false });
  };

  onToggle = () =>
    this.setState((prevState) => ({ visible: !prevState.visible }));

  checkIfInterviewGuide = () => {
    /* Check if we are gonna automatically show resume instead of job description. */

    let interviewGuide = false;
    if (
      typeof window !== 'undefined' &&
      window.interviewGuideEnabled &&
      this.props.applicant &&
      onlyDesktopDesign()
    ) {
      interviewGuide = true;
    }
    return interviewGuide;
  };

  checkToRender = (property) => {
    const {
      companyProfile,
      companyProfileJobs,
      jobDescription,
      fullApplyFormActivate,
    } = this.props;
    switch (property) {
      case 'companyProfileJobs': {
        const renderStatusJobs = !!(
          companyProfileJobs && companyProfileJobs.length > 0
        );
        return renderStatusJobs;
      }
      case 'companyDescription': {
        const renderStatusCompanyDescription = !!(
          companyProfile && companyProfile.description
        );
        return renderStatusCompanyDescription;
      }
      case 'jobDescription': {
        const renderStatusJobDescription = !!(
          jobDescription && jobDescription.description
        );
        return renderStatusJobDescription;
      }
      case 'jobLocation': {
        const renderStatusJobLocation = !!(
          jobDescription && jobDescription.address
        );
        return renderStatusJobLocation;
      }
      case 'skills': {
        const renderStatusSkills = !!(
          companyProfile && companyProfile.skills.length > 0
        );
        return renderStatusSkills;
      }
      case 'uploaded_files': {
        const renderStatusPhotos = !!(
          companyProfile &&
          companyProfile.uploaded_files.length > 0 &&
          this.props.jobDescription &&
          this.props.jobDescription.show_company_logo
        );
        return renderStatusPhotos;
      }
      case 'video': {
        const renderStatusVideo = !!(
          companyProfile &&
          companyProfile.video &&
          this.props.jobDescription &&
          this.props.jobDescription.show_company_logo
        );
        return renderStatusVideo;
      }
      case 'fullApplyFormActivate': {
        /*  show this only if user already clicked the button on mobile to Apply for the form.
        When user clicks the button startFullApplyFormActivate in redux becomes true and then
        we can show this APPLY in sidebar menu. */
        const renderStatusFullApplyForm = !!fullApplyFormActivate;
        return renderStatusFullApplyForm;
      }
      default:
        return false;
    }
  };

  renderScrollItems = () => {
    const { routeData, t } = this.props;
    /* We put here the names of the element that we scroll to */
    const scrollItems = [
      {
        content: t('openPositions'),
        scrollelement: 'companyProfileJobsScroll',
        property: 'companyProfileJobs',
        key: ['companyProfile'],
      },
      {
        content: t('companyDescription'),
        scrollelement: 'companyProfileDescriptionScroll',
        property: 'companyDescription',
        key: ['companyProfile'],
      },
      {
        content: t('jobDescription'),
        scrollelement: 'jobDescriptionScroll',
        property: 'jobDescription',
        key: ['jobDescription'],
      },
      {
        content: t('location'),
        scrollelement: 'jobLocationScroll',
        property: 'jobLocation',
        key: ['jobDescription'],
      },
      {
        content: t('skills'),
        scrollelement: 'companyProfileSkillsScroll',
        property: 'skills',
        key: ['companyProfile'],
      },
      {
        content: t('videos'),
        scrollelement: 'companyProfileVideosScroll',
        property: 'video',
        key: ['companyProfile', 'jobDescription'],
      },
      {
        content: t('photos'),
        scrollelement: 'companyProfilePhotosScroll',
        property: 'uploaded_files',
        key: ['companyProfile', 'jobDescription'],
      },
      {
        content: t('apply'),
        scrollelement: 'fullApplyFormScroll',
        property: 'fullApplyFormActivate',
        key: ['companyProfile', 'jobDescription'],
      },
    ];

    return scrollItems.map((item) => {
      let renderScrollElement = false;

      /* First we check if `routeData.key` property exists(if it was saved to redux)
      and then we check if the current route that user is on exist inside the `key`
      array property. This key's are the same ones as the key's in `routes.js` file.
      By checking this we know that specific route can contain some of this titles in
      a pull out side menu in navbar. */

      if (routeData && routeData.key && item.key.includes(routeData.key)) {
        /* Here we check to see if there is data for property's. If there aren't we
        shouldnt render title in pull out side menu in navbar. For example maybe video
        is not uploaded so there is no need to show video title to scroll to. */
        renderScrollElement = this.checkToRender(item.property);
      }

      if (renderScrollElement === true) {
        return (
          <Menu.Item
            as="h4"
            {...item}
            key={item.scrollelement}
            onClick={() => {
              this.onPusherClick();
              scroller.scrollTo(item.scrollelement, {
                duration: 1000,
                delay: 50,
                smooth: true,
              });
            }}
          />
        );
      }
      return null;
    });
  };

  renderNavigationTitle = (key) => {
    const { companyProfile, jobDescription, t } = this.props;
    /* Every route has it's own 'key'. We are using those keys to render different titles in
    Navigation depending on what route we are. */
    switch (key) {
      case 'companyProfile': {
        const companyProfileNavigationTitle = `${t('jobsAt')} ${
          companyProfile && companyProfile.name ? companyProfile.name : ''
        }`;
        return companyProfileNavigationTitle;
      }
      case 'jobDescription': {
        /* In case interviewGuide is active we show job name instead of company name */
        if (this.checkIfInterviewGuide() && jobDescription) {
          return jobDescription.name;
        }
        /* If show_comapny_logo is fals it means it's a blind job add and we hide company name */
        if (jobDescription && jobDescription.show_company_logo) {
          const jobDescriptionNavigationTitle = `${
            companyProfile && companyProfile.name ? companyProfile.name : ''
          }`;

          return jobDescriptionNavigationTitle;
        }
        return null;
      }
      case 'verification': {
        const verificationNavigationTitle =
          this.state.parsedQueryString && this.state.parsedQueryString.job_name
            ? this.state.parsedQueryString.job_name
            : null;

        return verificationNavigationTitle;
      }
      default:
        return null;
    }
  };

  renderHamburgerMenu = (key) => {
    /* Every route has it's own 'key'. We are using those keys to render AccountName on routes that we want.
    For example we don't want to show it on Unknown page route. */
    switch (key) {
      case 'companyProfile': {
        return (
          <Styled.HamburgerItem onClick={() => this.onToggle()}>
            <Icon name="sidebar" size="large" />
          </Styled.HamburgerItem>
        );
      }
      case 'jobDescription': {
        return (
          <Styled.HamburgerItem onClick={() => this.onToggle()}>
            <Icon name="sidebar" size="large" />
          </Styled.HamburgerItem>
        );
      }
      default:
        return null;
    }
  };

  renderAccountName = (key) => {
    const { applicant, jobDescription } = this.props;
    const { public_features_options } = jobDescription?.team ?? {};

    // Hide login button by feature toggle
    if (public_features_options?.public_hide_login_button) {
      return null;
    }

    /* Every route has it's own 'key'. We are using those keys to render AccountName on routes that we want.
    For example we don't want to show it on Unknown page route. */
    switch (key) {
      case 'companyProfile': {
        return <AccountName />;
      }
      case 'search': {
        return <AccountName />;
      }
      case 'jobDescription': {
        if (this.checkIfInterviewGuide() && jobDescription) {
          /* In case interviewGuide is active we don't need dropdown for logout and we need to show full name
          instead of just first name */
          return (
            <Styled.InterviewGuideAccountName>
              {`${applicant.value.first_name} ${applicant.value.last_name}`}
            </Styled.InterviewGuideAccountName>
          );
        }

        return <AccountName />;
      }
      case 'search': {
        return <AccountName />;
      }
      default:
        return null;
    }
  };

  renderShareModalIcon = (key) => {
    /* Every route has it's own 'key'. We are using those keys to render ShareModalIcon on routes that we want.
    For example we don't want to show it on Unknown page route. */
    switch (key) {
      case 'companyProfile': {
        return (
          <Styled.ShareIcon>
            <Icon
              name="share alternate"
              onClick={() => this.props.openShareModal()}
            />
          </Styled.ShareIcon>
        );
      }
      case 'jobDescription': {
        /* If interviewGuide is active we don't show share icon or if Second stage job */
        if (
          this.checkIfInterviewGuide() ||
          (this.props.jobDescription &&
            this.props.jobDescription.job_stage_num === 2)
        ) {
          return null;
        }
        return (
          <Styled.ShareIcon>
            <Icon
              name="share alternate"
              onClick={() => this.props.openShareModal()}
            />
          </Styled.ShareIcon>
        );
      }
      default:
        return null;
    }
  };

  renderLangSwitch = (key, i18n) => {
    switch (key) {
      case 'search': {
        return (
          <Dropdown
            options={[
              {
                value: 'en',
                text: 'English',
              },
              {
                value: 'es',
                text: 'Español',
              },
            ]}
            value={i18n.language}
            onChange={(e, data) => i18n.changeLanguage(data.value)}
          />
        );
      }
      case 'jobDescription': {
        const { jobDescription } = this.props;
        const isActive =
          jobDescription?.team?.public_features_options?.public_translation ===
          true;
        const isNotPresent =
          typeof jobDescription?.team?.public_features_options
            ?.public_translation === 'undefined';

        return (
          (isActive || isNotPresent) && (
            <Dropdown
              options={[
                {
                  value: 'en',
                  text: 'English',
                },
                {
                  value: 'es',
                  text: 'Español',
                },
              ]}
              value={i18n.language}
              onChange={(e, data) => i18n.changeLanguage(data.value)}
            />
          )
        );
      }

      /* Enable when Spanish translations are added for Verification flow */
      // case 'verification': {
      //   return (
      //     <Dropdown
      //       options={[
      //         {
      //           value: 'en',
      //           text: 'English',
      //         },
      //         {
      //           value: 'es',
      //           text: 'Español',
      //         },
      //       ]}
      //       value={i18n.language}
      //       onChange={(e, data) => i18n.changeLanguage(data.value)}
      //     />
      //   )
      // }
      default:
        return null;
    }
  };

  render() {
    const { visible } = this.state;
    const { companyProfile, routeData, i18n } = this.props;
    return (
      <Sidebar.Pushable>
        <Styled.SidebarMenuContainer>
          <Sidebar
            as={Menu}
            animation="overlay"
            icon="labeled"
            vertical
            visible={visible}
            direction="left"
          >
            {companyProfile ? (
              <Fragment>
                <Styled.SubMenu>
                  <Styled.SidebarCloseIcon>
                    <Icon name="close" onClick={() => this.onPusherClick()} />
                  </Styled.SidebarCloseIcon>
                  {this.renderScrollItems()}
                  {/* We are checking if renderAccountName return us something before rendering what it returns
                  because if it doesn't return nothing we are left SidebarAccountName that takes space in Sidebar */}
                  {this.renderAccountName(
                    routeData && routeData.key ? routeData.key : '',
                  ) ? (
                    <Styled.SidebarAccountName>
                      {this.renderAccountName(
                        routeData && routeData.key ? routeData.key : '',
                      )}
                    </Styled.SidebarAccountName>
                  ) : null}
                </Styled.SubMenu>
              </Fragment>
            ) : null}
          </Sidebar>
        </Styled.SidebarMenuContainer>
        <Styled.Pusher onClick={() => this.onPusherClick()}>
          {/* navBar */}
          <Styled.NavbarMenu attached="top" inverted borderless>
            <Ref innerRef={this.containerRef}>
              <Container>
                {/* Render Hamburger menu only if there is data in company profile. This way we can stop it from render on Unknown page */}
                <Styled.LeftMenuItem position="left">
                  {this.renderHamburgerMenu(
                    routeData && routeData.key ? routeData.key : '',
                  )}
                  <Styled.JobTitle
                    maxWidth={this.containerRef?.current?.clientWidth}
                  >
                    {this.renderNavigationTitle(
                      routeData && routeData.key ? routeData.key : '',
                    )}
                  </Styled.JobTitle>
                </Styled.LeftMenuItem>

                <Styled.RightMenuItem position="right">
                  {/* Render in navigation bar only on tablet and desktop screen */}
                  {desktopDesign() &&
                    this.renderAccountName(
                      routeData && routeData.key ? routeData.key : '',
                    )}

                  {this.renderShareModalIcon(
                    routeData && routeData.key ? routeData.key : '',
                  )}
                  <React.Fragment>
                    &nbsp;&nbsp;
                    {this.renderLangSwitch(
                      routeData && routeData.key ? routeData.key : '',
                      i18n,
                    )}
                  </React.Fragment>
                </Styled.RightMenuItem>
              </Container>
            </Ref>
          </Styled.NavbarMenu>

          {desktopDesign() ? <Styled.LeftNavbarDecoration /> : ''}
          {desktopDesign() ? <Styled.RightNavbarDecoration /> : ''}

          {/* Routes */}
          {renderRoutes(routes, {
            interviewGuide: this.checkIfInterviewGuide(), // send if interviewGuide is enabled to top level components
          })}
        </Styled.Pusher>
      </Sidebar.Pushable>
    );
  }
}

const mapStateToProps = (state) => ({
  applicant: state.authenticationReducer.applicant,
  companyProfile: state.companyProfileReducer.companyProfile,
  companyProfileJobs: state.companyProfileReducer.companyProfileJobs,
  fullApplyFormActivate: state.jobApplicationReducer.startFullApplyFormActivate,
  jobDescription: state.jobDescriptionReducer.jobDescription,
  routeData: state.globalReducer.routeData,
  numberOfQuestions: state.jobDescriptionReducer.numberOfQuestions,
});

Navigation.propTypes = {
  applicant: PropTypes.object, // Contains data about the current logged in user.
  companyProfile: PropTypes.object, // comes from companyProfileReducer. Contains data about current active company.
  companyProfileJobs: PropTypes.array, // list of jobs
  fullApplyFormActivate: PropTypes.bool, // when fullApplyFormActive is true we start the autosave interval and show full apply form.
  jobDescription: PropTypes.object, // comes from jobDescriptionReducer. Contains data about current active job.
  numberOfQuestions: PropTypes.object, // Contains data about number of questions the current active job has.
  openShareModal: PropTypes.func, // used to open share modal
  routeData: PropTypes.object,
};

export default connect(mapStateToProps, { openShareModal })(
  withTranslation('navigation')(Navigation),
);
