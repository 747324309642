import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as Styled from 'accountName_styled';
import { Dropdown } from 'semantic-ui-react';
import {
  openLoginModal,
  openSignOutModal,
} from '../../../actions/modals_actions';
import { desktopDesign } from '../../../helperFunctions/helperFunctions';
import { withTranslation } from 'react-i18next';

class AccountName extends Component {
  state = {
    accountName: 'LOGIN',
    dropdownMenu: false,
    segmentData: {
      openLoginData: {
        entry_method: 'login',
        user_type: 'applicant',
      },
    },
  };

  componentDidMount() {
    /* First we check if there is applicant data inside local Storage.
    That means that user has already register or logged in into their account.
    If they did we are setting the name of account in state and we enable
    dropdownMenu that renders dropdown with option to Sign out */
    if (typeof window !== 'undefined' && window.localStorage) {
      const appliedStatus = JSON.parse(localStorage.getItem('applicant'));
      const appliedToken = JSON.parse(localStorage.getItem('token'));
      if (appliedStatus && appliedToken) {
        if (appliedStatus.value.first_name) {
          this.setState({
            accountName: appliedStatus.value.first_name,
            dropdownMenu: true,
          });
        } else {
          /* if there is no first name available we set account name to 'MY ACCOUNT' */
          this.setState({
            accountName: null,
            dropdownMenu: true,
          });
        }
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { applicant, t } = this.props;
    /* If prevProps are null that means that user has not login/register in jet.
    This means that there is no applicant property still in redux store.
    We save login response and register response under same name in redux and under
    same properties in local storage since responses are the same.
    If user logins or registers the only way to do it again
    is to Sign out. At that point we reload the page and empty redux store */
    if (
      (prevProps.applicant === null && applicant) ||
      (applicant &&
        JSON.stringify(prevProps.applicant.value.first_name) !==
          JSON.stringify(applicant.value.first_name))
    ) {
      this.setState({
        accountName: (applicant && applicant.value.first_name) || null,
        dropdownMenu: true,
      });
    }
  }

  renderAccountNameDesktop() {
    /* if dropdownMenu is true means that user already signed into his account so we render account name with the option to Signout */
    const { dropdownMenu, accountName } = this.state;
    const { t } = this.props;
    if (dropdownMenu) {
      const dropdownAccountMenu = [
        {
          key: 'signout',
          text: (
            <div onClick={() => this.props.openSignOutModal()}>
              <Styled.DesktopDropdownText>
                {t('signOut')}
              </Styled.DesktopDropdownText>
            </div>
          ),
        },
      ];

      return (
        <Styled.AccountNameContainer>
          <Dropdown
            inline
            options={dropdownAccountMenu}
            trigger={accountName || t('myAccount')}
          />
        </Styled.AccountNameContainer>
      );
    }
    return (
      <Styled.AccountNameContainer
        onClick={() =>
          this.props.openLoginModal(this.state.segmentData.openLoginData)
        }
      >
        {accountName || t('myAccount')}
      </Styled.AccountNameContainer>
    );
  }

  renderAccountNameMobile() {
    /* if dropdownMenu is true means that user already signed into his account so we render account name with the option to Signout */
    const { dropdownMenu, accountName } = this.state;
    const { t } = this.props;
    if (dropdownMenu) {
      return (
        <Styled.AccountNameContainer>
          <div onClick={() => this.props.openSignOutModal()}>
            {t('signOut')}
          </div>
        </Styled.AccountNameContainer>
      );
    }
    return (
      <Styled.AccountNameContainer
        onClick={() =>
          this.props.openLoginModal(this.state.segmentData.openLoginData)
        }
      >
        {accountName || t('myAccount')}
      </Styled.AccountNameContainer>
    );
  }

  render() {
    return desktopDesign() ? (
      <div>{this.renderAccountNameDesktop()}</div>
    ) : (
      <div>{this.renderAccountNameMobile()}</div>
    );
  }
}

const mapStateToProps = (state) => ({
  applicant: state.authenticationReducer.applicant,
});

AccountName.propTypes = {
  applicant: PropTypes.object,
  openLoginModal: PropTypes.func,
};

export default connect(mapStateToProps, { openLoginModal, openSignOutModal })(
  withTranslation('account')(AccountName),
);
