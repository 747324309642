import styled from 'styled-components';
import { Container } from 'semantic-ui-react';

export const Container404 = styled.div`
  background-color: #f1f2f2;
  height: 100vh !important;
  margin: 0 auto;
  text-align: center;
`;

export const MessageContainer = styled.div`
  background-color: #fff;
  color: rgb(66, 63, 61);
  margin: 0 auto;
  padding: 50px;
  text-align: left !important;
  width: 85%;

  h3,
  p {
    font-family: 'Neutraface2TextBold' !important;
    text-transform: uppercase;
  }

  p {
    color: #000;
  }
`;

export const UnknownTitle = styled.h3`
  color: #58595b;
  @media (max-width: 767px) {
    font-size: 40px !important;
  }
  @media (min-width: 768px) {
    font-size: 48px !important;
  }

  @media (min-width: 1200px) {
    font-size: 56px !important;
  }
`;

export const UnknownSubTitle = styled.p`
  color: #58595b;
  font-family: 'Neutraface' !important;
  font-size: 20px !important;
`;
