/* This InfiniteScroll npm package doesn't offer ability to reset page number
after choosing new location or new keyword so we are extending it and adding
ability to it reset the pages based on "pageLoaded" parameter inside InfiniteScroll app */
import InfiniteScroll from 'react-infinite-scroller';

export default class InfiniteScrollExtend extends InfiniteScroll {
  componentDidUpdate(prevProps) {
    if (prevProps.pageStart !== this.props.pageStart) {
      this.pageLoaded = 1;
    }
    this.attachScrollListener();
  }
}
