import {
  authorizationModalOpenRudderstack,
  authorizationModalExitRudderstack,
} from '../rudderstack/rudderstack';

const modalsDefaultState = {
  segmentData: {
    authModalEntry: {
      entry_method: '',
      user_type: '',
    },
    authModalExit: {
      login_attempts: 0,
      register_attempts: 0,
      forgot_attempts: 0,
      // facebook_attempts: 0,
      password_is_valid: false,
      exit_method: 'close_button',
      user_type: 'applicant',
    },
  },
  timeoutMessageModal: {
    open: false,
    modalProps: {},
  },
  timeoutImageModal: {
    open: false,
    modalProps: {},
  },
  registerModal: {
    open: false,
  },
  loginModal: {
    open: false,
  },
  signOutModal: {
    open: false,
  },
  submitModal: {
    open: false,
    modalProps: {},
  },
  resetPasswordModal: {
    open: false,
  },
  shareModal: {
    open: false,
  },
  newPasswordModal: {
    open: false,
    modalProps: {},
  },
};

const modalsReducer = (state = modalsDefaultState, action) => {
  switch (action.type) {
    case 'OPEN_TIMEOUT_MESSAGE_MODAL':
      return {
        ...state,
        timeoutMessageModal: {
          ...state.timeoutMessageModal,
          open: true,
          modalProps: {
            ...state.timeoutMessageModal.modalProps,
            ...action.payload.modalProps,
          },
        },
      };
    case 'CLOSE_TIMEOUT_MESSAGE_MODAL':
      return {
        ...state,
        timeoutMessageModal: {
          ...state.timeoutMessageModal,
          open: false,
          modalProps: {},
        },
      };
    case 'OPEN_TIMEOUT_IMAGE_MODAL':
      return {
        ...state,
        timeoutImageModal: {
          ...state.timeoutImageModal,
          open: true,
          modalProps: {
            ...state.timeoutImageModal.modalProps,
            ...action.payload.modalProps,
          },
        },
      };
    case 'CLOSE_TIMEOUT_IMAGE_MODAL':
      return {
        ...state,
        timeoutImageModal: {
          ...state.timeoutImageModal,
          open: false,
          modalProps: {},
        },
      };
    case 'OPEN_REGISTER_MODAL': {
      let newOpenRegisterModalState = JSON.parse(JSON.stringify(state));

      /* RUDDERSTACK */
      /* ===================================== */
      if (action.payload && action.payload.segmentData) {
        newOpenRegisterModalState = {
          ...newOpenRegisterModalState,
          segmentData: {
            ...newOpenRegisterModalState.segmentData,
            authModalEntry: {
              ...newOpenRegisterModalState.segmentData.authModalEntry,
              ...action.payload.segmentData,
            },
          },
        };
        authorizationModalOpenRudderstack(
          action.payload.segmentData,
          action.type,
        );
      }
      /* ===================================== */
      newOpenRegisterModalState.registerModal.open = true;

      return {
        ...state,
        ...newOpenRegisterModalState,
      };
    }
    case 'CLOSE_REGISTER_MODAL':
      authorizationModalExitRudderstack(action.type);
      return {
        ...state,
        registerModal: { ...state.registerModal, open: false },
      };
    case 'OPEN_LOGIN_MODAL': {
      let newOpenLoginModalState = JSON.parse(JSON.stringify(state));

      /* RUDDERSTACK */
      /* ===================================== */
      if (action.payload && action.payload.segmentData) {
        newOpenLoginModalState = {
          ...newOpenLoginModalState,
          segmentData: {
            ...newOpenLoginModalState.segmentData,
            authModalEntry: {
              ...newOpenLoginModalState.segmentData.authModalEntry,
              ...action.payload.segmentData,
            },
          },
        };
        authorizationModalOpenRudderstack(
          action.payload.segmentData,
          action.type,
        );
      }
      /* ===================================== */
      newOpenLoginModalState.loginModal.open = true;

      return {
        ...state,
        ...newOpenLoginModalState,
      };
    }
    case 'CLOSE_LOGIN_MODAL':
      authorizationModalExitRudderstack(action.type);
      return {
        ...state,
        loginModal: { ...state.loginModal, open: false },
      };
    case 'OPEN_SIGN_OUT_MODAL':
      return {
        ...state,
        signOutModal: {
          ...state.signOutModal,
          open: true,
        },
      };
    case 'CLOSE_SIGN_OUT_MODAL':
      return {
        ...state,
        signOutModal: { ...state.signOutModal, open: false },
      };
    case 'OPEN_SUBMIT_MODAL':
      return {
        ...state,
        submitModal: {
          ...state.submitModal,
          open: true,
          modalProps: {
            ...state.submitModal.modalProps,
            ...action.payload.modalProps,
          },
        },
      };
    case 'CLOSE_SUBMIT_MODAL':
      return {
        ...state,
        submitModal: { ...state.submitModal, open: false, modalProps: {} },
      };
    case 'OPEN_RESET_PASSWORD_MODAL': {
      let newOpenResetPasswordModalState = JSON.parse(JSON.stringify(state));

      /* RUDDERSTACK */
      /* ===================================== */
      if (action.payload && action.payload.segmentData) {
        newOpenResetPasswordModalState = {
          ...newOpenResetPasswordModalState,
          segmentData: {
            ...newOpenResetPasswordModalState.segmentData,
            authModalEntry: {
              ...newOpenResetPasswordModalState.segmentData.authModalEntry,
              ...action.payload.segmentData,
            },
          },
        };
        authorizationModalOpenRudderstack(
          action.payload.segmentData,
          action.type,
        );
      }
      /* ===================================== */
      newOpenResetPasswordModalState.resetPasswordModal.open = true;

      return {
        ...state,
        ...newOpenResetPasswordModalState,
      };
    }
    case 'CLOSE_RESET_PASSWORD_MODAL':
      authorizationModalExitRudderstack(action.type);
      return {
        ...state,
        resetPasswordModal: { ...state.resetPasswordModal, open: false },
      };
    case 'OPEN_SHARE_MODAL':
      return {
        ...state,
        shareModal: {
          ...state.shareModal,
          open: true,
        },
      };
    case 'CLOSE_SHARE_MODAL':
      return {
        ...state,
        shareModal: { ...state.shareModal, open: false },
      };
    case 'OPEN_NEW_PASSWORD_MODAL':
      return {
        ...state,
        newPasswordModal: {
          ...state.newPasswordModal,
          open: true,
          modalProps: {
            ...state.newPasswordModal.modalProps,
            ...action.payload.modalProps,
          },
        },
      };
    case 'CLOSE_NEW_PASSWORD_MODAL':
      return {
        ...state,
        newPasswordModal: {
          ...state.newPasswordModal,
          open: false,
          modalProps: {},
        },
      };
    default:
      return state;
  }
};

export default modalsReducer;
