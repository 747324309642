import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal, Form } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import * as Styled from 'authorizationModals_styled';
import {
  closeRegisterModal,
  openLoginModal,
  openTimeoutImageModal,
} from '../../../../actions/modals_actions';
import { postRegisterApplicantAction } from '../../../../actions/api_actions';
import spinner from '../../../../../../public/assets/img/spinner.gif';

class RegisterModal extends Component {
  state = {
    email: '',
    password: '',
    /* test password condition */
    lowercase: false,
    uppercase: false,
    numeral: false,
    length: false,
    /* test inputs on submit */
    passwordSubmit: null,
    emailSubmit: null,
    /* show error message if submit not good */
    showRegisterErrorMsg: false,
    /* spinner */
    showSpinner: false,
    segmentData: {
      openLoginData: {
        entry_method: 'login',
        user_type: 'applicant',
      },
    },
  };

  handleFormSubmit = () => {
    const { password } = this.state;

    const testPasswordSubmit = () =>
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{8,}$/g.test(password);
    const testEmailSubmit = () => this.emailValidation();

    const testInputsOnSubmit = async () => {
      const [passwordSubmit, emailSubmit] = await Promise.all([
        testPasswordSubmit(),
        testEmailSubmit(),
      ]);
      this.setState(
        {
          passwordSubmit,
          emailSubmit,
        },
        () => {
          if (this.state.passwordSubmit && this.state.emailSubmit) {
            this.finishFormSubmit();
          }
        },
      );
    };

    testInputsOnSubmit();
  };

  emailValidation = () =>
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g.test(
      this.state.email,
    );

  testEmail() {
    const { email } = this.state;

    const testEmail = this.emailValidation();

    /* First we check if emailSubmit state is not null. We only change input
      colors after user clicks Submit for the first time.
      At that point emailSubmit is false/true */
    if (this.state.emailSubmit !== null) {
      /* If user clicked "Submit" but email conditions were not met field becomes
          red as a warning. When all conditions are good we remove red color by setting
          emailSubmit state to false */
      if (testEmail) {
        this.setState({
          emailSubmit: true,
        });
      } else {
        this.setState({
          emailSubmit: false,
        });
      }
    }
  }

  handleInputChange(value, property) {
    this.setState(
      {
        [property]: value,
      },
      () => {
        if (property === 'password') {
          this.testPassword();
        }
        if (property === 'email') {
          this.testEmail();
        }
      },
    );
  }

  finishFormSubmit() {
    const { email, password } = this.state;
    const { t } = this.props;

    /* Set spinner while data is loading */
    this.setState({
      showSpinner: true,
    });

    this.props
      .postRegisterApplicantAction(window.activeEnvironment, email, password)
      .then((response) => {
        /* Add welcome modal on register */
        // this.props.openTimeoutImageModal({
        //   image: 'success',
        //   bodyMessage: 'WELCOME',
        //   timeout: 3000,
        //   pageReload: false,
        //   redirect: false,
        //   removeCloseModalOptions: false,
        // })
        this.props.closeRegisterModal();
      })
      .catch((error) => {
        this.setState({
          showSpinner: false,
        });
        /* If there is property email in error message during register
        it means the email is already taken */
        if (error.errors.hasOwnProperty('email')) {
          this.setState({
            showRegisterErrorMsg: t('emailValidation1'),
          });
        }
      });
  }

  testPassword() {
    const { password } = this.state;

    const testLowercase = () => /[a-z]/g.test(password); // test if there is lowercase
    const testUppercase = () => /[A-Z]/g.test(password); // test if there is uppercase
    const testNumeral = () => /[0-9]/g.test(password); // test if there is numeral
    const testLength = () => /.{8}$/g.test(password); // // test if length is more then 8

    const testPasswordCondition = async () => {
      /* Test password conditions and set state after all tests are done */
      const [lowercase, uppercase, numeral, length] = await Promise.all([
        testLowercase(),
        testUppercase(),
        testNumeral(),
        testLength(),
      ]);
      this.setState(
        {
          lowercase,
          uppercase,
          numeral,
          length,
        },
        () => {
          /* First we check if passwordSubmit state is not null. We only change input
          colors after user clicks Submit for the first time.
          At that point passwordSubmit is false/true */
          if (this.state.passwordSubmit !== null) {
            /* If user clicked "Submit" but password conditions were not met field becomes
          red as a warning. When all conditions are good we remove red color by setting
          passwordSubmit state to false */

            if (
              this.state.lowercase &&
              this.state.uppercase &&
              this.state.numeral &&
              this.state.length
            ) {
              this.setState({
                passwordSubmit: true,
              });
            } else {
              this.setState({
                passwordSubmit: false,
              });
            }
          }
        },
      );
    };

    testPasswordCondition();
  }

  render() {
    const {
      email,
      password,
      lowercase,
      uppercase,
      numeral,
      length,
      passwordSubmit,
      emailSubmit,
    } = this.state;
    const { t } = this.props;
    return (
      <Styled.ModalContainer
        size="mini"
        open
        onClose={this.props.closeRegisterModal}
        closeIcon
        data-gtm="initial-apply"
      >
        <Styled.ModalContent>
          <Modal.Description>
            <Styled.ModalHeader>{t('apply')}</Styled.ModalHeader>

            {this.state.showRegisterErrorMsg && (
              <Styled.PopupWarning>
                {this.state.showRegisterErrorMsg}
              </Styled.PopupWarning>
            )}
            <Form onSubmit={this.handleFormSubmit}>
              <Styled.FormField>
                <Styled.FormInputs
                  placeholder={t('email')}
                  onChange={(e) =>
                    this.handleInputChange(e.target.value, 'email')
                  }
                  value={email}
                  passTest={emailSubmit}
                />
                {this.state.emailSubmit === false ? (
                  <Styled.PopupWarning passTest={this.state.emailSubmit}>
                    {t('emailValidation2')}
                  </Styled.PopupWarning>
                ) : null}
              </Styled.FormField>
              <Styled.FormField>
                <Styled.FormInputs
                  placeholder={t('password')}
                  onChange={(e) =>
                    this.handleInputChange(e.target.value, 'password')
                  }
                  value={password}
                  passTest={passwordSubmit}
                  type="password"
                />
              </Styled.FormField>
              <Styled.PasswordTestText>
                {t('passwordText1')}
                <Styled.PasswordLengthTest passTest={length}>
                  {t('passwordText2')}
                </Styled.PasswordLengthTest>
                {t('passwordText3')}
                <Styled.PasswordNumeralTest passTest={numeral}>
                  {t('passwordText4')}
                </Styled.PasswordNumeralTest>
                <Styled.PasswordLowercaseTest passTest={lowercase}>
                  {t('passwordText5')}
                </Styled.PasswordLowercaseTest>
                {t('passwordText6')}
                <Styled.PasswordUppercaseTest passTest={uppercase}>
                  {t('passwordText7')}
                </Styled.PasswordUppercaseTest>
                {t('passwordText8')}
              </Styled.PasswordTestText>
              {this.state.showSpinner ? (
                <img src={spinner} width="50" />
              ) : (
                <Styled.ButtonModal type="submit" authentication="success">
                  {t('continueBtn')}
                </Styled.ButtonModal>
              )}
            </Form>
          </Modal.Description>
        </Styled.ModalContent>
        {this.state.showSpinner ? null : (
          <Styled.ModalActions
            onClick={() => {
              this.props.openLoginModal(this.state.segmentData.openLoginData);
              this.props.closeRegisterModal();
            }}
          >
            <Styled.ModalActionsLoginLink>LOGIN</Styled.ModalActionsLoginLink>
          </Styled.ModalActions>
        )}
      </Styled.ModalContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  companyProfile: state.companyProfileReducer.companyProfile,
});

RegisterModal.propTypes = {
  openLoginModal: PropTypes.func,
  closeRegisterModal: PropTypes.func,
};

export default connect(mapStateToProps, {
  closeRegisterModal,
  openLoginModal,
  openTimeoutImageModal,
  postRegisterApplicantAction,
})(withTranslation('registerModal')(RegisterModal));
