import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal } from 'semantic-ui-react';
import * as Styled from 'timeoutImageModal_styled';
import { closeTimeoutImageModal } from '../../../../actions/modals_actions';
import spinner from '../../../../../../public/assets/img/spinner.gif';

/* If we send `null` instead of some props, this will not be rendered.
If we send `null` instead of `timeout` prop then modal will not get close automatically.
We can only close it manually. */

class TimeoutImageModal extends Component {
  componentDidMount() {
    const { modalProps } = this.props;
    /* Close modal after specific time */
    if (modalProps.timeout) {
      this.timeout();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  timeout = () => {
    const { modalProps } = this.props;
    setTimeout(() => {
      this.props.closeTimeoutImageModal();
      /* If we have a value for redirect we redirect to that place after timeout. We use 'window.location.replace'
      instead of  'window.location.href' because with href browser "back" button still works and then user can return
      to the page that dowsnt call API on "back" button */
      if (modalProps.redirect) {
        window.location.replace(modalProps.redirect);
      }
      /* If pageReload is true and we didn't redirect no where, reload the page after timeout is done */
      if (!modalProps.redirect && modalProps.pageReload) {
        window.location.reload();
      }
    }, modalProps.timeout);
  };

  renderImageComponent(modal) {
    switch (modal) {
      case 'signout':
        return <SignOutImageModal />;
        break;
      case 'success':
        return <SuccessImageModal />;
        break;
      case 'error':
        return <ErrorImageModal />;
        break;
      default:
        return null;
    }
  }

  render() {
    const { modalProps } = this.props;
    return (
      <Styled.TimeoutImageModal
        size="mini"
        open
        onClose={this.props.closeTimeoutImageModal}
        closeIcon={modalProps.removeCloseModalOptions ? false : true}
        closeOnDimmerClick={modalProps.removeCloseModalOptions ? false : true}
        data-gtm={modalProps.gtm ? modalProps.gtm : undefined}
      >
        <Styled.TimeoutImageModalContent>
          <Modal.Description>
            <Styled.ModalHeader>
              {modalProps.image
                ? this.renderImageComponent(modalProps.image)
                : null}
            </Styled.ModalHeader>

            {modalProps.bodyMessage ? (
              <Styled.TimeoutImageModalBody>
                {modalProps.bodyMessage}
              </Styled.TimeoutImageModalBody>
            ) : null}
          </Modal.Description>
        </Styled.TimeoutImageModalContent>
      </Styled.TimeoutImageModal>
    );
  }
}

const mapStateToProps = (state) => ({
  companyProfile: state.companyProfileReducer.companyProfile,
});

TimeoutImageModal.propTypes = {
  modalProps: PropTypes.shape({
    image: PropTypes.string,
    bodyMessage: PropTypes.string,
    timeout: PropTypes.number,
    pageReload: PropTypes.bool,
    redirect: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    removeCloseModalOptions: PropTypes.bool,
    gtm: PropTypes.string,
  }),
  closeTimeoutImageModal: PropTypes.func,
};

const SignOutImageModal = () => (
  <div>
    <img src={spinner} width="100" alt="spinner" />
  </div>
);

const SuccessImageModal = () => (
  <Styled.StateImageModal>
    <Styled.StateImageModalIcon name="check" />
  </Styled.StateImageModal>
);

const ErrorImageModal = () => (
  <Styled.StateImageModal>
    <Styled.StateImageModalIcon name="close" />
  </Styled.StateImageModal>
);

export default connect(mapStateToProps, { closeTimeoutImageModal })(
  TimeoutImageModal,
);
