/* Custom Rudderstack events */

export function rudderTrackEventBolivar(eventName, data) {
  const finalData = {
    // we use it like this since SSR would break the app because there is no window object
    app_source: 'bolivar',
    source:
      typeof window === 'undefined'
        ? (global.window = {})
        : window.location.host,
    ...data,
  };
  const context = { traits: null };
  window.rudderanalytics.track(eventName, finalData, context);
}

export function companyProfileRudderstack() {
  /* On first page load there is no data in companyProfileJobsFilters since we are searching
    this first job based on ipInfo. In case latitude and longitude are not saved in
    companyProfileJobsFilters on time in first load, use the same data from ipInfo  */
  /* -------------------------------------- */
  const storeData = store.getState().companyProfileReducer;

  let companyData = {};

  companyData = {
    ...storeData.companyProfileJobsFilters,
    team_id: storeData.companyProfile.team_id,
  };

  if (companyData.lat === '') {
    companyData = {
      lat: storeData.ipInfo.latitude,
      lng: storeData.ipInfo.longitude,
      searchbar: '',
      team_id: storeData.companyProfile.team_id,
    };
  }
  /* -------------------------------------- */

  rudderTrackEventBolivar('JOB_SEARCH', {
    page: 'Company profile Bolivar',
    team_id: companyData.team_id,
    latitude: companyData.lat,
    longitude: companyData.lng,
    text: companyData.searchbar,
  });
}

/* We call this rudderstack event when page is loaded because on Churchill it was when modal is open
but in bolivar we don't use modal anymore. */
export function quickApplyEntryRudderstack(easyApplyData, jobDescriptionData) {
  rudderTrackEventBolivar('EASY_APPLY_MODAL_ENTRY', {
    device: 'Web',
    page: 'Job Description Bolivar',
    apply_method: easyApplyData.applyMethod,
    first_name: easyApplyData.firstName,
    last_name: easyApplyData.lastName,
    email: easyApplyData.email,
    resume_file_name: easyApplyData.resume?.file?.fileName,
    employer_team_id: jobDescriptionData?.team_id,
    job_id: jobDescriptionData?.id,
    job_title: jobDescriptionData?.name,
  });
}

/* We call this rudderstack event when we clear the data in quick apply form in order to apply again */
export function quickApplyClearRudderstack(easyApplyData, jobDescriptionData) {
  rudderTrackEventBolivar('EASY_APPLY_MODAL_CLEAR_APPLICANT', {
    device: 'Web',
    page: 'Job Description Bolivar',
    apply_method: easyApplyData.applyMethod,
    first_name: easyApplyData.firstName,
    last_name: easyApplyData.lastName,
    email: easyApplyData.email,
    resume_file_name: easyApplyData.resume?.file?.fileName,
    employer_team_id: jobDescriptionData?.team_id,
    job_id: jobDescriptionData?.id,
    job_title: jobDescriptionData?.name,
  });
}

/* We call this rudderstack event after we successfully submitted quick apply form.
In Churchill it was a modal and method was called after modal closed (after successful submit).
Here we don't have modal no more so we just call it after successful submit. */
export function quickApplyExitRudderstack(
  easyApplyData,
  jobDescriptionData,
  easyApplyApplicant,
  exitMethod,
) {
  rudderTrackEventBolivar('EASY_APPLY_MODAL_EXIT', {
    device: 'Web',
    page: 'Job Description Bolivar',
    apply_method: easyApplyData.applyMethod,
    email: easyApplyData.email,
    employer_team_id: jobDescriptionData?.team_id,
    exit_method: exitMethod,
    first_name: easyApplyData.firstName,
    last_name: easyApplyData.lastName,
    job_id: jobDescriptionData?.id,
    job_title: jobDescriptionData?.name,
    person_id: easyApplyApplicant.value.person_id,
    resume_file_name: easyApplyData.resume?.file?.fileName,
  });
}

export function authorizationModalOpenRudderstack(
  authorizationData,
  action_type,
) {
  rudderTrackEventBolivar(
    'AUTH_MODAL_ENTRY',
    {
      action_clicked: action_type,
      entry_method: authorizationData.entry_method, // register || login || reset_password
      user_type: authorizationData.user_type, // applicant - hardcoded
    },
    { traits: null },
  );
}

export function authorizationModalExitRudderstack(action_type) {
  rudderTrackEventBolivar(
    'AUTH_MODAL_EXIT',
    {
      action_clicked: action_type,
      exit_method: 'close_button',
      user_type: 'applicant',
    },
    { traits: null },
  );
}

export function validPhoneNumberToBeInE164FormatRudderstack(applicationData) {
  rudderTrackEventBolivar('PHONE_NUMBER_ERROR', {
    device: 'Web',
    page: 'Job Description Bolivar',
    job_id: applicationData.job_id,
    job_title: applicationData.job_title,
    email: applicationData.email,
    country_code: applicationData.country_code,
    phone_number: applicationData.phone_number,
  });
}
