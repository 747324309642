import styled from 'styled-components';

export const NameContainer = styled.div`
  /* text-align: center; */
`;

export const JobName = styled.div`
  font-family: 'Neutraface2TextBold';
  font-size: 14px;
  letter-spacing: 2.4px;
  line-height: 17px;
  text-align: left;

  @media (min-width: 768px) {
    font-size: 20px;
    line-height: 36px;
  }

  @media (min-width: 992px) {
    font-size: 25px;
  }

  @media (min-width: 1280px) {
    font-size: 30px;
  }
`;

export const BrandName = styled.div`
  font-family: 'Neutraface2TextBook';
  font-size: 14px;
  letter-spacing: 2.4px;
  line-height: 34px;
  text-align: left;

  @media (min-width: 768px) {
    font-size: 18px;
  }

  @media (min-width: 992px) {
    font-size: 23px;
  }

  @media (min-width: 1280px) {
    font-size: 28px;
  }
`;
