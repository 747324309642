const saveJobDescription = (jobDescription) => ({
  type: 'SAVE_JOB_DESCRIPTION',
  payload: {
    jobDescription,
  },
});
const saveShortenJobDescriptionUrl = (shortenJobDescriptionUrl) => ({
  type: 'SAVE_SHORTEN_JOB_DESCRIPTION_URL',
  payload: {
    shortenJobDescriptionUrl,
  },
});
const saveNumberOfQuestions = (numberOfQuestions) => ({
  type: 'SAVE_NUMBER_OF_QUESTIONS',
  payload: {
    numberOfQuestions,
  },
});
const setJobDescription = (jobDescription) => ({
  type: 'SET_JOB_DESCRIPTION',
  payload: {
    jobDescription,
  },
});
export {
  saveJobDescription,
  saveShortenJobDescriptionUrl,
  saveNumberOfQuestions,
  setJobDescription,
};
