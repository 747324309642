import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as Styled from 'jobDescriptionFullApplyFormResumeRender_styled';

class JobDescriptionFullApplyFormResumeRender extends Component {
  state = {
    fileFormat: null,
    showOriginalLink: false,
  };

  componentDidMount() {
    if (this.props.uploadedResumeFile.resume_link) {
      const checkIfPdf =
        this.props.uploadedResumeFile.resume_link.indexOf('pdf');

      /* If file on the backend is not .pdf but something like .doc/.docx we still get in response .pdf file but
      this is not original uploaded resume but some kind of template.
      In this case we also get back property 'can_download_resume_link'. If this property is 'true' that means that resume on the backend is
      not .pdf so we show extra message that user can see original resume by clicking on the link from 'download_resume_link' property.
      In case resume on the backend is .pdf we get back original .pdf in response so we don't need to show that message. */

      if (checkIfPdf >= 0) {
        this.setState({
          fileFormat: 'pdf',
          showOriginalLink:
            this.props.uploadedResumeFile.can_download_resume_link,
        });
      } else {
        /* We are always getting back .pdf but in case we get something else we use iframe to show it which is not reliable. */
        this.setState({
          fileFormat: 'other',
          showOriginalLink:
            this.props.uploadedResumeFile.can_download_resume_link,
        });
      }
    }
  }

  /* Since <object/> property can only be used for pdf resumes and it's more stable we use it for pdf.
  For everything else we use iframe that can sometimes fail to load */
  renderResume() {
    if (this.state.fileFormat === 'pdf') {
      return (
        <object
          data={this.props.uploadedResumeFile.resume_link}
          type="application/pdf"
          width="100%"
          height="1200px"
        >
          Resume file
        </object>
      );
    }

    if (this.state.fileFormat === 'other') {
      return (
        <iframe
          height="1200px"
          width="100%"
          name="resume"
          src={`https://docs.google.com/gview?url=${encodeURIComponent(
            this.props.uploadedResumeFile.resume_link,
          )}&embedded=true`}
          frameBorder="0"
          scrolling="no"
          loading="lazy"
          title="resume"
        />
      );
    }
    return 'There is no resume uploaded!';
  }

  render() {
    return (
      <div>
        {this.state.showOriginalLink &&
        this.props.uploadedResumeFile.download_resume_link ? (
          <Styled.ShowOriginalResumeLink>
            To download resume, click{' '}
            <a
              href={this.props.uploadedResumeFile.download_resume_link}
              target="_blank"
            >
              here
            </a>
          </Styled.ShowOriginalResumeLink>
        ) : null}
        {this.renderResume()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  uploadedResumeFile: state.jobApplicationReducer.uploadedResumeFile,
});

JobDescriptionFullApplyFormResumeRender.propTypes = {
  uploadedResumeFile: PropTypes.object, // link to resume that was uploaded by user. Can be different file type formats
};

export default connect(
  mapStateToProps,
  {},
)(JobDescriptionFullApplyFormResumeRender);
