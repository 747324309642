import React from 'react';
import { CSSTransition } from 'react-transition-group';
import * as Styled from 'verificationSuccess_styled';
import { useTranslation } from 'react-i18next';
import SuccessShieldIcon from '../../../../../../../../../public/assets/svg/SuccessShieldIcon';

const SVVerificationSuccess = () => {
  const { t } = useTranslation('svVerificationSuccess');

  return (
    <Styled.VerificationSuccessContainer data-testid="verification-success">
      <Styled.IconContainer>
        <CSSTransition timeout={1500} classNames="status-icon-animation" in>
          <SuccessShieldIcon />
        </CSSTransition>
      </Styled.IconContainer>

      <Styled.TitleText>{t('titleText')}</Styled.TitleText>

      <Styled.DescriptionText>{t('descriptionText')}</Styled.DescriptionText>
    </Styled.VerificationSuccessContainer>
  );
};

SVVerificationSuccess.propTypes = {};

export default SVVerificationSuccess;
