import styled from 'styled-components';
import { Button } from 'semantic-ui-react';

export const VerificationSuccessContainer = styled.div`
  text-align: center;
  max-width: 620px;
  padding: 35px 50px;
  margin: 72px 15px 15px;
  box-shadow: 0px 4px 4px 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;

  @media only screen and (max-width: 767px) {
    margin: 30px 15px 15px;
  }
`;

export const TitleText = styled.div`
  font-size: 20px;
  font-weight: 400;
  margin: 25px 0;
  line-height: 28px;
`;

export const IconContainer = styled.div`
  font-weight: 400;
  font-size: 20px;

  .status-icon-animation-appear {
    transform: rotateY(-90deg);
  }

  .status-icon-animation-appear-active {
    transform: rotateY(0);
    transition: transform 1500ms;
  }
`;

export const DescriptionText = styled.div`
  font-weight: 400;
  font-size: 18px;
  margin: 30px 0px 35px;
  line-height: 24px;
  white-space: pre-line;
`;

export const ButtonContainer = styled.div`
  font-size: 16px;
  font-weight: 500;
  display: flex;
  justify-content: center;
`;
