import React from 'react';
import PropTypes from 'prop-types';
import { NursysVerificationSuccess, SVVerificationSuccess } from './components';

const VerificationSuccess = (props) => {
  const component = {
    nursysapi: (
      <NursysVerificationSuccess
        verificationType={props.verificationType}
        verificationData={props.verificationData}
      />
    ),
    svapi: <SVVerificationSuccess />,
  };

  return component[props.verificationEngine.toLowerCase()] || null;
};

VerificationSuccess.propTypes = {
  // used to change activeStep which changes the verification screen
  changeActiveStep: PropTypes.func,
  // data extracted from URL string
  verificationData: PropTypes.object,
  // lets us know which verification type we are one (LPN, RN...)
  verificationType: PropTypes.string,
  // full name of the state
  longStateName: PropTypes.string,
  // Verification engine used (Streamline Verify or Nursys APIs)
  verificationEngine: PropTypes.string,
};

export default VerificationSuccess;
