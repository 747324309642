import styled from 'styled-components';

export const AccountNameContainer = styled.span`
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  /*  padding: 5px; */

  @media (min-width: 768px) {
    font-size: 18px !important;
  }

  @media (min-width: 1200px) {
    font-size: 20px !important;
  }

  i {
    padding-left: 5px;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const DesktopDropdownText = styled.span`
  font-size: 14px;
`;
