import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal, Form } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import * as Styled from 'authorizationModals_styled';
import {
  openResetPasswordModal,
  openRegisterModal,
  closeLoginModal,
  openTimeoutImageModal,
} from '../../../../actions/modals_actions';
import { postLoginApplicantAction } from '../../../../actions/api_actions';
import spinner from '../../../../../../public/assets/img/spinner.gif';

class LoginModal extends Component {
  state = {
    email: '',
    password: '',
    /* test inputs on submit */
    passwordSubmit: null,
    emailSubmit: null,
    showLoginErrorMsg: false,
    /* spinner */
    showSpinner: false,
    segmentData: {
      openRegisterData: {
        entry_method: 'register',
        user_type: 'applicant',
      },
      openResetPasswordData: {
        entry_method: 'reset_password',
        user_type: 'applicant',
      },
      closeLoginModalData: {
        exit_method: 'close_button',
        user_type: 'applicant',
      },
    },
  };

  handleFormSubmit = () => {
    const { password } = this.state;

    const testPasswordSubmit = () => /.{1}$/g.test(password);
    const testEmailSubmit = () => this.emailValidation();

    const testInputsOnSubmit = async () => {
      const [passwordSubmit, emailSubmit] = await Promise.all([
        testPasswordSubmit(),
        testEmailSubmit(),
      ]);
      this.setState(
        {
          passwordSubmit,
          emailSubmit,
        },
        () => {
          if (this.state.passwordSubmit > 0 && this.state.emailSubmit) {
            this.finishFormSubmit();
          }
        },
      );
    };

    testInputsOnSubmit();
  };

  emailValidation = () =>
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g.test(
      this.state.email,
    );

  handleInputChange(value, property) {
    this.setState(
      {
        [property]: value,
      },
      () => {
        if (property === 'password') {
          this.testPassword();
        }
        if (property === 'email') {
          this.testEmail();
        }
      },
    );
  }

  testEmail() {
    const testEmail = this.emailValidation();

    /* First we check if emailSubmit state is not null. We only change input
      colors after user clicks Submit for the first time.
      At that point emailSubmit is false/true */
    if (this.state.emailSubmit !== null) {
      /* If user clicked "Submit" but email conditions were not met field becomes
          red as a warning. When all conditions are good we remove red color by setting
          emailSubmit state to false */
      if (testEmail) {
        this.setState({
          emailSubmit: true,
        });
      } else {
        this.setState({
          emailSubmit: false,
        });
      }
    }
  }

  finishFormSubmit() {
    /* Set spinner while data is loading */
    this.setState({
      showSpinner: true,
    });
    const { t } = this.props;
    this.props
      .postLoginApplicantAction(
        window.activeEnvironment,
        this.state.email,
        this.state.password,
      )
      .then((response) => {
        /* Add welcome back modal on Login */
        // this.props.openTimeoutImageModal({
        //   image: 'success',
        //   bodyMessage: 'WELCOME BACK',
        //   timeout: 3000,
        //   pageReload: false,
        //   redirect: false,
        //   removeCloseModalOptions: false,
        // })
        this.props.closeLoginModal();
      })
      .catch((error) => {
        this.setState({
          showSpinner: false,
          showLoginErrorMsg: t('incorrectLogin'),
        });
      });
  }

  testPassword() {
    const { password } = this.state;

    const testPasswordCondition = async () => {
      if (this.state.passwordSubmit !== null) {
        if (password === '') {
          this.setState({
            passwordSubmit: false,
          });
        } else {
          this.setState({
            passwordSubmit: true,
          });
        }
      }
    };
    testPasswordCondition();
  }

  render() {
    const { email, password, passwordSubmit, emailSubmit } = this.state;
    const { t } = this.props;
    return (
      <Styled.ModalContainer
        size="mini"
        open
        onClose={this.props.closeLoginModal}
        closeIcon
      >
        <Styled.ModalContent>
          <Modal.Description>
            <Styled.ModalHeader>LOGIN</Styled.ModalHeader>
            {this.state.showLoginErrorMsg && (
              <Styled.PopupWarning>
                {this.state.showLoginErrorMsg}
              </Styled.PopupWarning>
            )}
            <Form onSubmit={this.handleFormSubmit}>
              <Styled.FormField>
                <Styled.FormInputs
                  placeholder={t('email')}
                  onChange={(e) =>
                    this.handleInputChange(e.target.value, 'email')
                  }
                  value={email}
                  passTest={emailSubmit}
                />
                {this.state.emailSubmit === false ? (
                  <Styled.PopupWarning passTest={this.state.emailSubmit}>
                    {t('emailValidation')}
                  </Styled.PopupWarning>
                ) : null}
              </Styled.FormField>
              <Styled.FormField>
                <Styled.FormInputs
                  placeholder={t('password')}
                  onChange={(e) =>
                    this.handleInputChange(e.target.value, 'password')
                  }
                  value={password}
                  passTest={passwordSubmit}
                  type="password"
                />
              </Styled.FormField>
              {this.state.showSpinner ? (
                <img src={spinner} width="50" />
              ) : (
                <Styled.ButtonModal type="submit" authentication="warning">
                  LOGIN
                </Styled.ButtonModal>
              )}
            </Form>
          </Modal.Description>
        </Styled.ModalContent>
        {this.state.showSpinner ? null : (
          <Styled.ModalActions>
            <Styled.ModalActionsLinks
              onClick={() => {
                this.props.openResetPasswordModal(
                  this.state.segmentData.openResetPasswordData,
                );
                this.props.closeLoginModal();
              }}
            >
              {t('forgotPassword')}
            </Styled.ModalActionsLinks>
            <Styled.ModalActionsLinks
              onClick={() => {
                this.props.openRegisterModal(
                  this.state.segmentData.openRegisterData,
                );
                this.props.closeLoginModal();
              }}
            >
              {t('apply')}
            </Styled.ModalActionsLinks>
          </Styled.ModalActions>
        )}
      </Styled.ModalContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  companyProfile: state.companyProfileReducer.companyProfile,
});

LoginModal.propTypes = {
  openResetPasswordModal: PropTypes.func,
  openRegisterModal: PropTypes.func,
  closeLoginModal: PropTypes.func,
};

export default connect(mapStateToProps, {
  openRegisterModal,
  closeLoginModal,
  openTimeoutImageModal,
  openResetPasswordModal,
  postLoginApplicantAction,
})(withTranslation('loginModal')(LoginModal));
