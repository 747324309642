import styled from 'styled-components';
import { Image, Grid, Icon, Input, Button } from 'semantic-ui-react';

export const GridRow = styled(Grid.Row)`
  margin-top: 15px;
`;

export const BannerImageContainer = styled.div`
  background-image: url('${(props) => props.link}');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  min-height: 300px;

  /* Since background image is also in container we need to add negative margins in order to make it from edge to edge on mobile screen */
  @media (max-width: 767px) {
    background-image: none;
    margin-left: -15px;
    margin-right: -15px;
    background-color: #f0f0f0;
    min-height: auto;
  }
`;

export const BannerImage = styled(Image)`
  display: none !important;

  @media (max-width: 767px) {
    display: block !important;
    height: auto;
    max-width: 100%;
    width: 100%;
  }
`;

export const SocialIconImage = styled(Icon)`
  color: #006cdf;
  margin-right: 5px !important;
  &:hover {
    cursor: pointer;
    transform: scale(1.3);
  }
`;

export const MobileLogoImage = styled(Image)`
  -moz-box-shadow: 3px 5px 10px -1px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 3px 5px 10px -1px rgba(0, 0, 0, 0.75);

  background-color: #fff !important;
  border-radius: 5px;
  box-shadow: 3px 5px 10px -1px rgba(0, 0, 0, 0.75);
  box-sizing: content-box;
  margin-bottom: 30px;
  margin-top: -15%;
  width: 150px;
`;

export const JobsButton = styled(Button)`
  background-color: ${(props) => props.customcolor} !important;
  border: 1px solid #636363 !important;
  color: #fff !important;
  font-size: 16px !important;
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.5) !important;
  width: 220px;
`;

export const InfoText = styled.p`
  margin-top: 20px !important;

  span:first-of-type {
    font-family: 'NeutrafaceBold';
    font-size: 22px;
  }

  span:nth-of-type(2) {
    font-size: 14px;
    word-break: break-word;
  }
  span:nth-of-type(1) a {
    color: #1077e2 !important;
  }

  @media (max-width: 767px) {
    text-align: center;
  }
`;

export const MobileDisplay = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
    display: none !important;
  }
`;

export const DesktopDisplay = styled.div`
  @media (max-width: 767px) {
    display: none !important;
  }
`;
export const LogoImage = styled(Image)`
  margin-bottom: 20px;
`;
