import React from 'react';
import PropTypes from 'prop-types';
import {} from 'semantic-ui-react';
import { CSSTransition } from 'react-transition-group';
import GetVerified from 'GetVerified';
import * as Styled from 'verificationFlow_styled';
import LicenseVerification from './components/LicenseVerification/LicenseVerification';
import VerificationStatus from './components/VerificationStatus';
import VerificationError from './components/VerificationError';
import VerificationSuccess from './components/VerificationSuccess';

const VerificationFlow = (props) => {
  /* Step 1 */
  const renderGetVerified = () => (
    <CSSTransition
      timeout={{ exit: 500, enter: 500, appear: 500 }}
      classNames="item-mount"
      in={props.activeStep === 'getVerified'}
      unmountOnExit
      appear
    >
      <GetVerified
        verificationType={props.verificationType}
        changeActiveStep={props.changeActiveStep}
        longStateName={props.longStateName}
        verificationEngine={props.verificationEngine}
      />
    </CSSTransition>
  );

  /* Step 2 */
  const renderLicenseVerification = () => (
    <CSSTransition
      timeout={{ exit: 500, enter: 500 }}
      classNames="item-mount"
      in={props.activeStep === 'licenseVerification'}
      unmountOnExit
      appear
    >
      <LicenseVerification
        verificationType={props.verificationType}
        verificationData={props.verificationData}
        changeActiveStep={props.changeActiveStep}
        longStateName={props.longStateName}
        lastFourSsnEnabled={props.lastFourSsnEnabled}
        verificationEngine={props.verificationEngine}
      />
    </CSSTransition>
  );

  /* Step 3 */
  const renderVerificationStatus = () => (
    <CSSTransition
      timeout={{ exit: 500, enter: 1500 }}
      classNames="item-mount"
      in={props.activeStep === 'verificationStatus'}
      unmountOnExit
      appear
    >
      <VerificationStatus
        verificationType={props.verificationType}
        verificationData={props.verificationData}
        verificationStatus={props.verificationStatus}
      />
    </CSSTransition>
  );

  /* Step 4 */
  const renderVerificationError = () => (
    <CSSTransition
      timeout={{ exit: 500, enter: 1500 }}
      classNames="item-mount"
      in={props.activeStep === 'verificationError'}
      unmountOnExit
      appear
    >
      <VerificationError
        verificationType={props.verificationType}
        changeActiveStep={props.changeActiveStep}
        verificationStatus={props.verificationStatus}
        verificationEngine={props.verificationEngine}
      />
    </CSSTransition>
  );

  const renderVerificationSuccess = () => (
    <CSSTransition
      timeout={{ exit: 500, enter: 1500 }}
      classNames="item-mount"
      in={props.activeStep === 'verificationSuccess'}
      unmountOnExit
      appear
    >
      <VerificationSuccess
        verificationType={props.verificationType}
        verificationStatus={props.verificationStatus}
        verificationData={props.verificationData}
        changeActiveStep={props.changeActiveStep}
        longStateName={props.longStateName}
        verificationEngine={props.verificationEngine}
      />
    </CSSTransition>
  );

  return (
    <Styled.VerificationFlowWrapper data-testid="verification-flow">
      {renderGetVerified()}
      {renderLicenseVerification()}
      {renderVerificationStatus()}
      {renderVerificationError()}
      {renderVerificationSuccess()}
    </Styled.VerificationFlowWrapper>
  );
};

VerificationFlow.propTypes = {
  activeStep: PropTypes.string, // tells us which step of verification we show
  changeActiveStep: PropTypes.func, // we use this function to change activeStep
  verificationData: PropTypes.object, // data extracted from URL string
  verificationStatus: PropTypes.string, // when activeStep is verificationStatus, this tells us which verification status is active
  verificationType: PropTypes.string, // tells us which type we are making verification for
  longStateName: PropTypes.string, // full name of the state
  lastFourSsnEnabled: PropTypes.bool,
  verificationEngine: PropTypes.string, // engine used to make the verification
};

export default VerificationFlow;
