import React from 'react';
import { useSelector } from 'react-redux';
import { JobDescriptionField } from 'jobDescriptionField_styled';
import { useTranslation } from 'react-i18next';

const rate = {
  PER_HOUR: 'perHour',
  PER_DIEM: 'perDiem',
  PER_MONTH: 'perMonth',
  PER_YEAR: 'perYear',
};

const JobDescriptionSalary = (props) => {
  const { t } = useTranslation('jobSalary');
  const jobDetail = useSelector(
    (state) => state.jobDescriptionReducer.jobDescription?.job_detail,
  );

  return (
    <JobDescriptionField>
      <h3>{t('compensation')}</h3>
      <p>
        {jobDetail?.salary_type === 'UP_TO' && `${t('upTo')} `}
        {`$${jobDetail?.salary_min?.toFixed(2)}`}
        {jobDetail?.salary_type === 'RANGE' &&
          ` - $${jobDetail?.salary_max?.toFixed(2)}`}
        {` ${t(rate[jobDetail?.salary_rate])}`}
      </p>
    </JobDescriptionField>
  );
};

export default JobDescriptionSalary;
