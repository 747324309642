import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import PersonalInformationQuestion from 'PersonalInformationQuestion';
import EducationQuestion from 'EducationQuestion';
import ExperienceQuestion from 'ExperienceQuestion';
import TextQuestion from 'TextQuestion';
import SliderQuestion from 'SliderQuestion';
import CalendarQuestion from 'CalendarQuestion';
import VideoQuestion from 'VideoQuestion';
import TimeAvailabilityQuestion from 'TimeAvailabilityQuestion';
import MultipleChoiceQuestion from 'MultipleChoiceQuestion';
import SignatureQuestion from 'SignatureQuestion';
import PersonalQuestionsResumeUpload from 'PersonalQuestionsResumeUpload';

class JobDescriptionFullApplyFormQuestions extends Component {
  renderPersonalQuestions() {
    return (
      <Fragment>
        {this.props.interviewGuide ? null : <PersonalInformationQuestion />}
        {this.props.showExpandQuestionButton}
        {this.props.showAutosaveCounter}
        {this.props.interviewGuide ? null : <EducationQuestion />}
        {this.props.interviewGuide ? null : <ExperienceQuestion />}
      </Fragment>
    );
  }

  renderQuestions() {
    const {
      answers,
      fullApplicationForm,
      jobDescription: { language, questions_translation },
    } = this.props;

    return fullApplicationForm.application_schema.pages[0].questions.map(
      (questionItem) => {
        const questionText =
          language === 'es' && questions_translation
            ? questions_translation[`questions_${language}`][questionItem.id]
                ?.title || questionItem.question.question_text
            : questionItem.question.question_text;
        if (
          questionItem.answer.answer_type === 'Text' &&
          answers // if it's a new user and there is no answers, then answers property will be filled with default data
        ) {
          /* Original properties that came from API response. We save them under different property names.
            This are the names that we need for sending POST request when submitting. */
          const questionData = {
            question_id: questionItem.id,
            question_text: questionText,
          };

          return (
            <TextQuestion
              questionData={questionData}
              questionType="textQuestion"
              key={questionData.question_id}
            />
          );
        }
        if (questionItem.answer.answer_type === 'Video' && answers) {
          const questionData = {
            question_id: questionItem.id,
            question_text: questionText,
            time_limit: questionItem.answer.time_limit || 0,
          };

          return (
            <VideoQuestion
              questionData={questionData}
              questionType="videoQuestion"
              key={questionData.question_id}
            />
          );
        }
        if (questionItem.answer.answer_type === 'Slider' && answers) {
          const questionData = {
            question_id: questionItem.id,
            question_text: questionText,
          };

          return (
            <SliderQuestion
              questionData={questionData}
              questionType="sliderQuestion"
              key={questionData.question_id}
            />
          );
        }

        if (questionItem.answer.answer_type === 'Calendar' && answers) {
          const questionData = {
            question_id: questionItem.id,
            question_text: questionText,
          };
          return (
            <CalendarQuestion
              questionData={questionData}
              questionType="calendarQuestion"
              key={questionData.question_id}
            />
          );
        }

        if (questionItem.answer.answer_type === 'TimeAvailability' && answers) {
          const questionData = {
            question_id: questionItem.id,
            question_text: questionText,
          };

          return (
            <TimeAvailabilityQuestion
              questionData={questionData}
              questionType="timeAvailabilityQuestion"
              key={questionData.question_id}
            />
          );
        }

        if (questionItem.answer.answer_type === 'MultipleChoice' && answers) {
          const questionData = {
            question_id: questionItem.id,
            question_text: questionText,
            allow_multiple_answers: questionItem.answer.allow_multiple_answers,
            options:
              language === 'es' && questions_translation
                ? questions_translation[`questions_${language}`][
                    questionItem.id
                  ]?.offered_options || questionItem.answer.options
                : questionItem.answer.options,
          };

          return (
            <MultipleChoiceQuestion
              questionData={questionData}
              questionType="multipleChoiceQuestion"
              key={questionData.question_id}
            />
          );
        }

        if (questionItem.answer.answer_type === 'SingleChoice' && answers) {
          const questionData = {
            question_id: questionItem.id,
            question_text: questionText,
            allow_multiple_answers: questionItem.answer.allow_multiple_answers,
            options:
              language === 'es' && questions_translation
                ? questions_translation[`questions_${language}`][
                    questionItem.id
                  ]?.offered_options || questionItem.answer.options
                : questionItem.answer.options,
          };

          return (
            <MultipleChoiceQuestion
              questionData={questionData}
              questionType="singleChoiceQuestion"
              key={questionData.question_id}
            />
          );
        }
        if (questionItem.answer.answer_type === 'Signature' && answers) {
          const questionData = {
            question_id: questionItem.id,
            question_text: questionText,
          };

          return (
            <SignatureQuestion
              questionData={questionData}
              questionType="signatureQuestion"
              key={questionData.question_id}
            />
          );
        }
      },
    );
  }

  render() {
    return (
      <Fragment>
        {this.props.interviewGuide ? null : <PersonalQuestionsResumeUpload />}
        {this.renderPersonalQuestions()}
        {this.props.fullApplicationForm &&
          this.props.fullApplicationForm.application_schema &&
          this.props.fullApplicationForm.application_schema.pages &&
          this.props.fullApplicationForm.application_schema.pages[0] &&
          this.props.fullApplicationForm.application_schema.pages[0]
            .questions &&
          this.props.fullApplicationForm.application_schema.pages[0].questions
            .length > 0 &&
          this.renderQuestions()}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  answers: state.jobApplicationReducer.answers,
  fullApplicationForm: state.jobApplicationReducer.fullApplicationForm,
  questionExpand: state.jobApplicationReducer.questionExpand,
  jobDescription: state.jobDescriptionReducer.jobDescription,
});

JobDescriptionFullApplyFormQuestions.propTypes = {
  answers: PropTypes.object, // comes from parent 'jobApplicationReducer'. Contains all the answers for the questions coming from truman, type of the question and id.
  showAutosaveCounter: PropTypes.element, // comes from parent "JobDescriptionFullApplyForm" component. All the functionality is in the parent component. We are passing it here for design purpose so we can render it between PersonalInformation and EducationQuestion.
  showExpandQuestionButton: PropTypes.element, // comes from parent "JobDescriptionFullApplyForm" component. All the functionality is in the parent component. We are passing it here for design purpose so we can render it between PersonalInformation and EducationQuestion
  interviewGuide: PropTypes.bool, // tells us if we should show resume.
  fullApplicationForm: PropTypes.object, // original response that we get back from API when retrieve full apply questions form data (not changed or adjusted).
};

export default connect(
  mapStateToProps,
  {},
)(JobDescriptionFullApplyFormQuestions);
