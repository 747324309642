import styled from 'styled-components';
import { Sidebar, Menu, Image } from 'semantic-ui-react';

// Semantic styles rewrite

export const PusherLogo = styled(Image)`
  margin: 30px 0;
  border: 1px solid #cccccc;
  padding: 3px;
`;
export const SidebarMenuContainer = styled.div`
  .sidebar {
    -webkit-box-shadow: 9px 0px 12px -4px rgba(0, 0, 0, 0.4) !important;
    border: none !important;
  }
`;
export const SidebarCloseIcon = styled.div`
  background-color: #423f3d;
  color: #fff;
  font-size: 18px;
  padding: 12px 12px 0 0;
  text-align: right;

  &:hover {
    cursor: pointer;
  }
`;

export const SidebarAccountName = styled.div`
  background-color: #423f3d;
  color: #fff;
  font-family: 'Neutraface2TextBold' !important;
  padding: 25px 25px !important;
  &:hover {
    cursor: pointer;
  }
`;

export const SubMenu = styled(Menu.Menu)`
  h4 {
    font-family: 'Neutraface2TextBold' !important;
    text-align: center !important;
    margin: 0 !important;
    padding: 25px 25px !important;
    font-size: 16px !important;
    font-weight: bold !important;
    color: #fff !important;
    background-color: #423f3d !important;
    border-bottom: 0.2px solid #b2b0b0 !important;
  }

  h4:hover {
    cursor: pointer;
  }
`;
export const Pusher = styled(Sidebar.Pusher)`
  min-height: 100vh !important;
`;
export const NavbarMenu = styled(Menu)`
  border-radius: unset !important;
  background-color: #423f3d !important;
`;

export const LeftNavbarDecoration = styled.div`
  border-right: 60px solid transparent !important;
  border-top: 15px solid #423f3d !important;
  display: inline-block;
  float: left;
  height: 0 !important;
  visibility: visible !important;
  width: 0 !important;

  @media (max-width: 767px) {
    display: none;
  }
`;

export const RightNavbarDecoration = styled.div`
  border-left: 60px solid transparent !important;
  border-top: 15px solid #423f3d !important;
  display: inline-block;
  float: right;
  height: 0 !important;
  visibility: visible !important;
  width: 0 !important;

  @media (max-width: 767px) {
    display: none;
  }
`;
export const LeftMenuItem = styled(Menu.Item)``;

export const HamburgerItem = styled.div`
  /* height: 100%; */
  @media (min-width: 768px) {
    display: none !important;
  }
`;

export const RightMenuItem = styled(Menu.Item)``;

export const JobTitle = styled.h3`
  margin-left: 20px;
  margin-top: 0px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (max-width: 767px) {
    font-size: 14px !important;
    max-width: 150px;
  }
  @media (min-width: 768px) {
    font-size: 28px !important;
    max-width: 300px;
  }

  @media (min-width: 1200px) {
    font-size: 32px !important;
  }
  & {
    ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}px;`}
  }
`;

export const ShareIcon = styled.div`
  margin-left: 40px;
  &:hover {
    cursor: pointer;
  }
  i {
    color: #fff;
    font-size: 16px !important;
  }

  @media (min-width: 768px) {
    i {
      color: #fff;
      font-size: 24px !important;
    }
  }
`;

export const InterviewGuideAccountName = styled.div`
  color: #fff;
  font-size: 16px;
  font-weight: bold;

  @media (min-width: 768px) {
    font-size: 18px !important;
  }

  @media (min-width: 1200px) {
    font-size: 20px !important;
  }
`;
