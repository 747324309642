/* in this reducer we save global data that are not specific for any route */

const globalReducerDefaultState = {
  routeData: null, // Object
  matchData: null, // Object
  location: null, // Object
  industries: null, // Object
  filteredIndustries: null, // Object
  cityCenter: null, // Object
  city: null, // Object
  loading: false,
};

const globalReducer = (state = globalReducerDefaultState, action) => {
  switch (action.type) {
    case 'SAVE_ROUTE_DATA':
      return {
        ...state,
        routeData: action.payload.route,
        matchData: action.payload.match,
        location: action.payload.location,
      };
    case 'SAVE_INDUSTRIES':
      return {
        ...state,
        industries: action.payload.industries,
      };
    case 'SAVE_FILTERED_INDUSTRIES':
      return {
        ...state,
        filteredIndustries: action.payload.filteredIndustries,
      };
    case 'SET_LOADING':
      return {
        ...state,
        loading: action.payload.loading,
      };
    case 'SET_CITY':
      return {
        ...state,
        city: action.payload.city,
      };
    case 'SET_CITY_CENTER':
      return {
        ...state,
        cityCenter: action.payload.cityCenter,
      };
    default:
      return state;
  }
};

export default globalReducer;
