import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import * as Styled from 'companyProfileImageGallery_styled';
import { withTranslation } from 'react-i18next';

class CompanyProfileImageGallery extends Component {
  state = {
    photoIndex: 0,
    isOpen: false,
  };

  renderPhotos = () =>
    this.props.uploaded_files.map((photo, index) => (
      <Styled.ImageWrapper
        key={photo.uploaded_file.id}
        link={photo.uploaded_file.external_link}
        onClick={() => this.setState({ isOpen: true, photoIndex: index })}
      ></Styled.ImageWrapper>
    ));

  render() {
    const { uploaded_files, t } = this.props;
    const { photoIndex } = this.state;
    return (
      <Fragment>
        {uploaded_files && uploaded_files.length > 0 ? (
          <div>
            {/* We only reander "PHOTOS" header on 'jobDescription' route */}
            {this.props.routeData &&
              this.props.routeData.key === 'jobDescription' && (
                <Styled.SectionHeader>{t('photos')}</Styled.SectionHeader>
              )}

            <Styled.ImageContainer>
              {this.renderPhotos()}
              {this.state.isOpen && (
                <Lightbox
                  mainSrc={
                    uploaded_files[photoIndex].uploaded_file.external_link
                  }
                  nextSrc={
                    uploaded_files[(photoIndex + 1) % uploaded_files.length]
                      .uploaded_file.external_link
                  }
                  prevSrc={
                    uploaded_files[
                      (photoIndex + uploaded_files.length - 1) %
                        uploaded_files.length
                    ].uploaded_file.external_link
                  }
                  onCloseRequest={() => this.setState({ isOpen: false })}
                  onMovePrevRequest={() =>
                    this.setState({
                      photoIndex:
                        (photoIndex + uploaded_files.length - 1) %
                        uploaded_files.length,
                    })
                  }
                  onMoveNextRequest={() =>
                    this.setState({
                      photoIndex: (photoIndex + 1) % uploaded_files.length,
                    })
                  }
                />
              )}
            </Styled.ImageContainer>
          </div>
        ) : null}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  routeData: state.globalReducer.routeData,
  uploaded_files:
    state.companyProfileReducer.companyProfile &&
    state.companyProfileReducer.companyProfile.uploaded_files,
});

CompanyProfileImageGallery.propTypes = {
  // redux
  uploaded_files: PropTypes.array,
};

export default connect(
  mapStateToProps,
  {},
)(withTranslation('jobDescription')(CompanyProfileImageGallery));
