const authenticationDefaultState = {
  applicant: null, // Object
  easyApplyApplicant: null, // Object
};

const authenticationReducer = (state = authenticationDefaultState, action) => {
  switch (action.type) {
    case 'SAVE_AUTHENTICATION_DATA':
      return {
        ...state,
        [action.payload.type]: {
          ...state[action.payload.type],
          ...action.payload.authenticationData,
        },
      };
    default:
      return state;
  }
};

export default authenticationReducer;
