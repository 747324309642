import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
/* Styled Components */
import * as Styled from 'jobDescriptionSummary_styled';

function JobDescriptionSummary(props) {
  const {
    jobDescription: { language, description, job_translation },
  } = props;
  const { t } = useTranslation('jobDescription');
  return (
    <Styled.SummaryContainer>
      <Styled.SectionHeader>{t('descriptionTitle')}</Styled.SectionHeader>
      {props.jobDescription && props.jobDescription.description ? (
        <Styled.DangerousDiv
          dangerouslySetInnerHTML={{
            __html:
              language && job_translation
                ? job_translation[`description_${language}`]
                : description,
          }}
        />
      ) : null}
    </Styled.SummaryContainer>
  );
}

const mapStateToProps = (state) => ({
  jobDescription: state.jobDescriptionReducer.jobDescription,
});

JobDescriptionSummary.propTypes = {
  jobDescription: PropTypes.object, // contains data about current active job.
};

export default connect(mapStateToProps, {})(JobDescriptionSummary);
