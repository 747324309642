/* We put actions here that are not specific for any route but can be used across the app */

export const saveRouteData = (route, match) => ({
  type: 'SAVE_ROUTE_DATA',
  payload: {
    route,
    match,
    location,
  },
});

export const saveIndustries = (industries) => ({
  type: 'SAVE_INDUSTRIES',
  payload: {
    industries,
  },
});

export const saveFilteredIndustries = (filteredIndustries) => ({
  type: 'SAVE_FILTERED_INDUSTRIES',
  payload: {
    filteredIndustries,
  },
});

export const setLoading = (loading) => ({
  type: 'SET_LOADING',
  payload: {
    loading,
  },
});

export const setCity = (city) => ({
  type: 'SET_CITY',
  payload: {
    city,
  },
});

export const setCityCenter = (cityCenter) => ({
  type: 'SET_CITY_CENTER',
  payload: {
    cityCenter,
  },
});
