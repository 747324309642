import styled from 'styled-components';
import { Modal, Icon, Header, Image, Input, Button } from 'semantic-ui-react';

export const ModalContainer = styled(Modal)`
  max-width: 400px;
`;

export const CloseIcon = styled(Icon)`
  top: 0px !important;
  right: 0px !important;
  color: #000 !important;
  margin: 10px;
`;

export const ModalContent = styled(Modal.Content)`
  text-align: center;
  font-family: 'Neutraface';
  font-size: 18px;
  padding: 50px 50px 30px 50px !important;
  background-color: #f6f3f3 !important;
  color: #000;
  border-radius: 0px !important;

  input {
    border: 0.5px solid #000 !important;
  }
  @media only screen and (max-width: 767px) {
    &&&& {
      padding: 50px !important;
    }
  }
`;

/* Image modal components */
/* ============================= */

export const SocialInput = styled(Input)`
  width: 100%;
  input {
    border-radius: 0px !important;
  }

  @media only screen and (max-width: 767px) {
    input {
      width: 50% !important;
    }
  }

  button {
    background-color: ${(props) => props.customcolor} !important;
    border-radius: 0px !important;
  }

  i {
    color: #fff;
  }

  .label {
    background-color: transparent !important;
    color: #000 !important;
    font-family: 'OpenSansSemiBold';
    padding-left: 0px !important;
  }
`;
export const CopyButton = styled(Button)`
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
  background-color: ${(props) => props.customcolor} !important;

  span {
    visibility: hidden;
    width: 120px;
    background-color: #fff;
    color: rgba(34, 36, 38, 0.85);
    text-align: center;
    border-radius: 6px;
    border: 1px solid rgba(34, 36, 38, 0.15);
    padding: 5px 0;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    width: 150px;
    top: 140%;
    left: 50%;
    margin-left: -60px;
  }

  &:hover span {
    visibility: visible;
  }
`;

export const SocialButtons = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  outline-style: none !important;

  .SocialMediaShareButton {
    margin: 20px 10px 0 10px;

    div {
      &:hover {
        cursor: pointer;
      }
    }
  }
`;
