import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as Styled from 'jobDescriptionFullApplyForm_styled';
import { Checkbox, Accordion, Input, Dropdown } from 'semantic-ui-react';
import AnimateHeight from 'react-animate-height';
import DatePicker, { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import { withTranslation } from 'react-i18next';
import {
  savePersonalInformationQuestions,
  savePersonalQuestionsSubmitRequirements,
  addNewQuestion,
  deleteQuestion,
  changeQuestionExpand,
} from '../../../../../../../actions/jobApplication_actions';
import { qualifications } from '../../../../../../../../constants/dataConstants';

registerLocale('es', es);

class EducationQuestion extends Component {
  state = {
    questionType: 'educationQuestion',
    inputSuccessCheck: [],
    optionalInputSuccessCheck: [
      /* Put here inputs that are optional */
      'majorInput',
    ],
  };

  componentDidMount() {
    this.checkOptionalConditions();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    /* When we add or remove education question we must update the state array so it has the same
    number of objects in array as there are questions in redux store.  We need to have at least
    one as a default in redux store.
    getDerivedStateFromProps is invoked right before calling the render method, both on the
    initial mount and on subsequent updates.
    */

    if (
      nextProps.educationQuestion.length !== prevState.inputSuccessCheck.length
    ) {
      const educationQuestionNumber = [];

      for (let i = 0; i < nextProps.educationQuestion.length; i += 1) {
        educationQuestionNumber.push({
          schoolInput: false,
          majorInput: false,
          qualificationInput: false,
          startDateInput: false,
          endDateInput: false,
        });
      }

      /* By returning the object we are setting the state */
      return {
        inputSuccessCheck: educationQuestionNumber,
      };
    }
    return null;
  }

  componentDidUpdate(prevProps) {
    const { educationQuestion } = this.props;

    const checkChangedInputs = () => {
      let schoolInput = false;
      let majorInput = false;
      let qualificationInput = false;
      let startDateInput = false;
      let endDateInput = false;

      /* Since we can delete or add education question we need to pick the shorter length between prevProps and this.props. Example we have 2 items in array and we delete one, then in prevProps we still have 2 items but in this.props we have 1 item. If we loop through prevProps always we will get an error because index will be bigger then the number of items. */

      const lengthChoice =
        prevProps.educationQuestion.length <= educationQuestion.length
          ? prevProps.educationQuestion.length
          : educationQuestion.length;

      for (let i = 0; i < lengthChoice; i += 1) {
        /* Compare previous state and current for all monitored inputs and if its changed we
      call the function */

        if (
          JSON.stringify(prevProps.educationQuestion[i].school) !==
          JSON.stringify(educationQuestion[i].school)
        ) {
          schoolInput = true;
        }
        if (
          JSON.stringify(prevProps.educationQuestion[i].major) !==
          JSON.stringify(educationQuestion[i].major)
        ) {
          majorInput = true;
        }
        if (
          JSON.stringify(prevProps.educationQuestion[i].qualification) !==
          JSON.stringify(educationQuestion[i].qualification)
        ) {
          qualificationInput = true;
        }

        if (
          JSON.stringify(prevProps.educationQuestion[i].start_date) !==
          JSON.stringify(educationQuestion[i].start_date)
        ) {
          startDateInput = true;
        }

        if (
          JSON.stringify(prevProps.educationQuestion[i].end_date) !==
          JSON.stringify(educationQuestion[i].end_date)
        ) {
          endDateInput = true;
        }
      }

      /* In parenthesis we put all the inputs that could have changed and if any of them has
      we call the function */
      if (
        schoolInput ||
        majorInput ||
        qualificationInput ||
        startDateInput ||
        endDateInput ||
        // we must have this because by deleting and adding the questions we are changing the length of array but not the values. So in order to trigger 'checkOptionalConditions' we must also compare the length. We aee this for example when we have one filled out question and one empty. After deleting the empty question design color is not changed back to success.
        prevProps.educationQuestion.length !== educationQuestion.length
      ) {
        this.checkOptionalConditions();
      }
    };

    checkChangedInputs();
  }

  checkOptionalConditions = () => {
    const { educationQuestion } = this.props;

    const newInputSuccessCheck = this.state.inputSuccessCheck.slice();

    /* Make conditions when specific inputs are considered true and successfully filled out */
    for (let i = 0; i < educationQuestion.length; i += 1) {
      /* check every property individually and set it to true based on custom condition */

      /* schoolInput */
      /* ------------------------------- */
      if (
        educationQuestion[i].school &&
        educationQuestion[i].school.trim().length > 0
      ) {
        newInputSuccessCheck[i].schoolInput = true;
      } else {
        newInputSuccessCheck[i].schoolInput = false;
      }

      /* majorInput */
      /* ------------------------------- */
      if (
        educationQuestion[i].major &&
        educationQuestion[i].major.trim().length > 0
      ) {
        newInputSuccessCheck[i].majorInput = true;
      } else {
        newInputSuccessCheck[i].majorInput = false;
      }

      /* qualificationInput */
      /* ------------------------------- */
      if (
        educationQuestion[i].qualification &&
        educationQuestion[i].qualification.trim().length > 0
      ) {
        newInputSuccessCheck[i].qualificationInput = true;
      } else {
        newInputSuccessCheck[i].qualificationInput = false;
      }

      /* startDateInput */
      /* ------------------------------- */
      if (
        educationQuestion[i].start_date &&
        educationQuestion[i].start_date.trim().length > 0
      ) {
        newInputSuccessCheck[i].startDateInput = true;
      } else {
        newInputSuccessCheck[i].startDateInput = false;
      }

      /* endDateInput */
      /* ------------------------------- */
      if (
        educationQuestion[i].end_date &&
        educationQuestion[i].end_date.trim().length > 0
      ) {
        newInputSuccessCheck[i].endDateInput = true;
      } else {
        newInputSuccessCheck[i].endDateInput = false;
      }
    }

    /* Set new state after checking conditions for the inputs */
    this.setState(
      {
        inputSuccessCheck: newInputSuccessCheck,
      },
      () => {
        let submitReady = true;
        /* If one of the values in the array is false, it means one of the inputs is not filled correctly and we set submitReady to false.
          Until submit ready is true we can not submit application. */
        for (let i = 0; i < this.state.inputSuccessCheck.length; i += 1) {
          /* In this part we are checking if there are any optional Inputs that don't need
          to be filled out in order to submit the form. If there are any optional Inputs
          we delete them from cloneObject so we don't check them. */
          /* ----------------------------------------------- */
          const cloneObject = { ...this.state.inputSuccessCheck[i] };

          this.state.optionalInputSuccessCheck.forEach((prop) => {
            delete cloneObject[prop];
          });
          /* ----------------------------------------------- */

          if (Object.values(cloneObject).indexOf(false) > -1) {
            submitReady = false;
          }
        }

        /* We save if the question is submitReady or not to redux store */
        this.props.savePersonalQuestionsSubmitRequirements(
          this.state.questionType,
          submitReady,
        );
      },
    );
  };

  handleDateChangeRaw = (e) => {
    /* We use this for stopping user from using keyboard or pasting data in input field so we don't get an error */
    e.preventDefault();
  };

  handleDeleteQuestion = (property, index) => {
    this.props.deleteQuestion(property, index);
  };

  handleAddNewQuestion = (property) => {
    this.props.addNewQuestion(property);
  };

  handleQuestionExpand = (questionType) => {
    this.props.changeQuestionExpand({ questionType, id: '1-education' });
  };

  handleInputChange = (questionType, property, value, index) => {
    /* "isClearable" property in DatePicker is used to clear the input. When we click it "handleInputChange" is called
    automatically. "Invalid date" value is sent, so we check if this string value is sent and reset input to empty string if it is. */
    if (value === 'Invalid date' || value === '') {
      this.props.savePersonalInformationQuestions(
        questionType,
        property,
        null,
        index,
      );
    } else {
      this.props.savePersonalInformationQuestions(
        questionType,
        property,
        value,
        index,
      );
    }
  };

  checkQuestionDesign(property, index) {
    const { firstTimeSubmitButtonClicked } = this.props;
    const { inputSuccessCheck } = this.state;

    /* Depending if input or some other element on the page is filled out correctly, wrongly or nothing we change design */
    let warning;
    let success;
    let neutral;

    const returnDesignPattern = () => {
      if (warning) {
        return warning;
      }

      if (success) {
        return success;
      }

      return neutral;
    };

    /* check if any of the property in inputSuccessCheck is 'false' */
    const checkIfFalse = () => {
      let falseCheck = false;

      for (let i = 0; i < inputSuccessCheck.length; i += 1) {
        if (Object.values(inputSuccessCheck[i]).indexOf(false) > -1) {
          /* In this part we are checking if there are any optional inputs that don't need
          to be checked for false. If there are any optional Inputs
          we delete them from cloneObject so question container doesn't become red if it's not
          filled out. */
          /* ----------------------------------------------- */
          const cloneObject = { ...this.state.inputSuccessCheck[i] };

          this.state.optionalInputSuccessCheck.forEach((prop) => {
            delete cloneObject[prop];
          });
          /* ----------------------------------------------- */

          if (Object.values(cloneObject).indexOf(false) > -1) {
            falseCheck = true;
          }
        }
      }

      return falseCheck;
    };

    /* Checks for which design we are gonna use on which element */
    /* ---------------------------------------------- */

    const checkInputWarning = (name) => {
      /* Warning input design can only show up after we clicked the submit button if
      input is not filled out correctly */
      if (
        firstTimeSubmitButtonClicked &&
        inputSuccessCheck[index][name] === false
      ) {
        /* In this part we are checking if the name of the input is contained in
        optionalInputSuccessCheck. If it's included we don't return 'warning' for that input
        so it doesn't get red border if it's not filled out when we click submit */
        if (!this.state.optionalInputSuccessCheck.includes(name)) {
          return 'warning';
        }
      }
    };

    const checkInputSuccess = (name) => {
      /* We show success input design before we hit submit button for the first
      time and after also so the only condition is that input is filled out correctly. */
      if (inputSuccessCheck[index][name] === true) {
        return 'success';
      }
    };

    /* We show warning design only after submit button is clicked for the first TimeRanges,
    if one of the inputs is not filled out correctly.
    If there is a 'false' in property we get back 'true'.
    */
    const checkQuestionContainerWarning = () => {
      if (firstTimeSubmitButtonClicked && checkIfFalse()) {
        return 'warning';
      }
    };

    /* Check the whole question container design. If all the inputs are filled out correctly we
    show success design */

    const checkQuestionContainerSuccess = () => {
      /* check if all the properties in inputSuccessCheck are true. If there isn't a false property
      we get back in response 'false'. We turn it around with "!" and trigger success  */
      if (!checkIfFalse()) {
        return 'success';
      }
    };
    /* ---------------------------------------------- */

    /* based on the input that we are checking we are passing that property to check design */
    switch (property) {
      case 'schoolInput':
      case 'majorInput':
      case 'qualificationInput':
      case 'startDateInput':
      case 'endDateInput':
        warning = checkInputWarning(property);
        success = checkInputSuccess(property);
        neutral = 'neutral';

        return returnDesignPattern();

      case 'questionContainer':
        warning = checkQuestionContainerWarning();
        success = checkQuestionContainerSuccess();
        neutral = 'neutral';

        return returnDesignPattern();
      default:
        return returnDesignPattern();
    }
  }

  renderEducationQuestions() {
    const { educationQuestion, t, i18n } = this.props;
    return educationQuestion.map((data, index) => (
      <Styled.QuestionBlock key={index}>
        <Styled.FormGroup>
          <Styled.FormField>
            <Styled.InputDesignWrapper
              statedesign={this.checkQuestionDesign('schoolInput', index)}
            >
              <input
                placeholder={t('school')}
                onChange={(e) =>
                  this.handleInputChange(
                    this.state.questionType,
                    'school',
                    e.target.value,
                    index,
                  )
                }
                value={educationQuestion[index].school || ''}
              />
            </Styled.InputDesignWrapper>
          </Styled.FormField>
        </Styled.FormGroup>
        <Styled.FormGroup>
          <Styled.FormField>
            <Styled.InputDesignWrapper
              statedesign={this.checkQuestionDesign('majorInput', index)}
            >
              <input
                placeholder={t('major')}
                onChange={(e) =>
                  this.handleInputChange(
                    this.state.questionType,
                    'major',
                    e.target.value,
                    index,
                  )
                }
                value={educationQuestion[index].major || ''}
              />
            </Styled.InputDesignWrapper>
          </Styled.FormField>
          <Styled.FormField>
            <Styled.InputDesignWrapper
              statedesign={this.checkQuestionDesign(
                'qualificationInput',
                index,
              )}
            >
              {/* When using dropdown and uploading from resume from pdf. we get back data that is not as
              a choice in dropdown so it doesn't get filled out */}

              {/* <Dropdown
                placeholder="Education Level"
                fluid
                search
                selection
                options={qualifications}
                onChange={(e, value) =>
                  this.handleInputChange(
                    this.state.questionType,
                    'qualification',
                    value.value,
                    index
                  )
                }
                value={educationQuestion[index].qualification || ''}
              /> */}

              {/* If we wanna text input instead of dropdown */}
              <input
                placeholder={t('educationLevel')}
                onChange={(e) =>
                  this.handleInputChange(
                    this.state.questionType,
                    'qualification',
                    e.target.value,
                    index,
                  )
                }
                value={educationQuestion[index].qualification || ''}
              />
            </Styled.InputDesignWrapper>
          </Styled.FormField>
        </Styled.FormGroup>
        <Styled.FormGroup>
          <Styled.FormFieldContainer>
            <Styled.FormFieldInputTitle>
              {t('startDate')}
            </Styled.FormFieldInputTitle>
            <Styled.DatePickerWrapper
              statedesign={this.checkQuestionDesign('startDateInput', index)}
            >
              <DatePicker
                locale={i18n.language}
                dateFormat="MM/dd/yyyy"
                showYearDropdown
                selectsStart
                startDate={
                  (educationQuestion[index].start_date &&
                    new Date(educationQuestion[index].start_date)) ||
                  null
                }
                endDate={
                  (educationQuestion[index].end_date &&
                    new Date(educationQuestion[index].end_date)) ||
                  null
                }
                maxDate={
                  (educationQuestion[index].end_date &&
                    new Date(educationQuestion[index].end_date)) ||
                  null
                }
                isClearable
                showPopperArrow={false}
                onChangeRaw={this.handleDateChangeRaw}
                customInput={
                  <Input icon="calendar alternate" iconPosition="left" />
                }
                placeholderText={t('startDate')}
                onChange={(value, e) =>
                  this.handleInputChange(
                    this.state.questionType,
                    'start_date',
                    moment(value).format(),
                    index,
                  )
                }
                selected={
                  (educationQuestion[index].start_date &&
                    new Date(educationQuestion[index].start_date)) ||
                  null
                }
              />
            </Styled.DatePickerWrapper>
          </Styled.FormFieldContainer>
          <Styled.FormFieldContainer>
            <Styled.FormFieldInputTitle>
              {t('endDate')}
            </Styled.FormFieldInputTitle>
            <Styled.DatePickerWrapper
              statedesign={this.checkQuestionDesign('endDateInput', index)}
            >
              <DatePicker
                locale={i18n.language}
                dateFormat="MM/dd/yyyy"
                showYearDropdown
                selectEnd
                startDate={
                  (educationQuestion[index].start_date &&
                    new Date(educationQuestion[index].start_date)) ||
                  null
                }
                endDate={
                  (educationQuestion[index].end_date &&
                    new Date(educationQuestion[index].end_date)) ||
                  null
                }
                minDate={
                  (educationQuestion[index].start_date &&
                    new Date(educationQuestion[index].start_date)) ||
                  null
                }
                isClearable
                showPopperArrow={false}
                onChangeRaw={this.handleDateChangeRaw}
                customInput={
                  <Input icon="calendar alternate" iconPosition="left" />
                }
                placeholderText={t('endDate')}
                onChange={(value, e) =>
                  this.handleInputChange(
                    this.state.questionType,
                    'end_date',
                    moment(value).format(),
                    index,
                  )
                }
                selected={
                  (educationQuestion[index].end_date &&
                    new Date(educationQuestion[index].end_date)) ||
                  null
                }
              />
            </Styled.DatePickerWrapper>
          </Styled.FormFieldContainer>
        </Styled.FormGroup>
        <Styled.FormField>
          <Checkbox
            label={t('educationGraduated')}
            onChange={() =>
              this.handleInputChange(
                this.state.questionType,
                'dates_opt_out',
                !educationQuestion[index].dates_opt_out || false,
                index,
              )
            }
            checked={
              educationQuestion[index].dates_opt_out
                ? educationQuestion[index].dates_opt_out
                : false
            }
          />
        </Styled.FormField>
        <Styled.FormField>
          {/* Dont show the trash can if only one educationQuestion is left */}
          {educationQuestion.length > 1 ? (
            <Styled.DeleteIcon
              onClick={() =>
                this.handleDeleteQuestion('educationQuestion', index)
              }
            >
              {t('remove')}
            </Styled.DeleteIcon>
          ) : null}
        </Styled.FormField>

        {/* Only show Add more on the last Question in array */}
        {educationQuestion.length - 1 === index && (
          <Styled.AddMoreButton
            onClick={() => this.handleAddNewQuestion('educationQuestion')}
          >
            {t('educationAddMore')}
          </Styled.AddMoreButton>
        )}
      </Styled.QuestionBlock>
    ));
  }

  render() {
    const { t } = this.props;
    return (
      <Styled.PersonalQuestionContainer
        statedesign={this.checkQuestionDesign('questionContainer')}
      >
        <Accordion>
          <Styled.AccordionTitle
            active={
              this.props.questionExpand[this.state.questionType][0].expand
            }
            onClick={() => this.handleQuestionExpand(this.state.questionType)}
          >
            <Styled.AccordionTitleTextContainer>
              <Styled.QuestionIconBorder>
                <Styled.QuestionIcon name="graduation" />{' '}
              </Styled.QuestionIconBorder>

              <Styled.AccordionTitleText>
                {t('educationAddDetailsTitle')}
                <br />
                <span>{t('educationAddDetailsSubTitle')}</span>
              </Styled.AccordionTitleText>
            </Styled.AccordionTitleTextContainer>

            {this.props.questionExpand[this.state.questionType][0].expand ? (
              <Styled.ExpandIcon name="chevron circle up" />
            ) : (
              <Styled.ExpandIcon name="chevron circle down" />
            )}
          </Styled.AccordionTitle>
          <Styled.AccordionContent
            active={
              this.props.questionExpand[this.state.questionType][0].expand
            }
          >
            <AnimateHeight
              animateOpacity
              duration={300}
              height={
                this.props.questionExpand[this.state.questionType][0].expand
                  ? 'auto'
                  : 0
              }
            >
              {this.renderEducationQuestions()}
            </AnimateHeight>
          </Styled.AccordionContent>
        </Accordion>
      </Styled.PersonalQuestionContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  educationQuestion:
    state.jobApplicationReducer.fullApplicationPersonalQuestions
      .educationQuestion,
  firstTimeSubmitButtonClicked:
    state.jobApplicationReducer.submitRequirements.firstTimeSubmitButtonClicked,
  questionExpand: state.jobApplicationReducer.questionExpand,
});

EducationQuestion.propTypes = {
  addNewQuestion: PropTypes.func, // used to add new question to the list of questions in redux
  deleteQuestion: PropTypes.func, // used to delete a question from the list of questions in redux
  changeQuestionExpand: PropTypes.func, // used to expand or collapse question accordion.
  educationQuestion: PropTypes.array, // array of all the questions that we added
  firstTimeSubmitButtonClicked: PropTypes.bool, // if true it means that "Submit" form button was clicked at least once.
  questionExpand: PropTypes.object, // contains all the questions and tells if question accordion is expanded or not.
  savePersonalInformationQuestions: PropTypes.func, // used to save personal information questions input data to redux store
  savePersonalQuestionsSubmitRequirements: PropTypes.func, // used to save if personal questions are submit ready
};

export default connect(mapStateToProps, {
  savePersonalInformationQuestions,
  savePersonalQuestionsSubmitRequirements,
  addNewQuestion,
  deleteQuestion,
  changeQuestionExpand,
})(withTranslation('jobDescriptionFullApply')(EducationQuestion));
