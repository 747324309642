/* @flow */

import RouteComponents from 'RouteComponents';
import CompanyProfile from 'CompanyProfile';
import JobDescription from 'JobDescription';
import AutoLogin from 'AutoLogin';
import ResetPassword from 'ResetPassword';
import Search from 'Search';
import Verification from 'Verification';
import Page404 from 'Page404';

const routes = [
  {
    component: RouteComponents,
    routes: [
      /* We redirect to this routes if company profile/job doesn't exist and give custom messages */
      {
        path: '/profile/company-profile-unknown',
        component: Page404,
        key: 'page404',
      },
      {
        path: '/view/job-description-unknown',
        component: Page404,
        key: 'page404',
      },
      {
        path: '/verification/state-role-not-supported',
        component: Page404,
        key: 'page404StateRoleNotSupported',
      },
      /* ================================ */
      {
        path: '/profile/:slug',
        component: CompanyProfile,
        key: 'companyProfile',
        exact: true,
      },
      {
        path: '/view/:slug',
        component: JobDescription,
        key: 'jobDescription',
        exact: true,
      },
      {
        path: '/apply/:slug',
        component: AutoLogin,
        key: 'autoLogin',
        exact: true,
      },
      {
        path: '/resetpassword',
        component: ResetPassword,
        key: 'resetPassword',
        exact: true,
      },
      {
        path: '/internal',
        component: Search,
        key: 'search',
        exact: true,
      },
      {
        path: '/verification/:slug',
        component: Verification,
        key: 'verification',
        exact: true,
      },
      /* This is catch all Unknown page route */
      /* ========================================= */
      {
        component: Page404,
        key: 'page404',
      },
    ],
  },
];

export default routes;
