import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import { withTranslation } from 'react-i18next';
import * as Styled from 'verificationStatus_styled';
import SuccessShieldIcon from '../../../../../../../public/assets/svg/SuccessShieldIcon';
import ErrorShieldIcon from '../../../../../../../public/assets/svg/ErrorShieldIcon';
import { CustomLinkButton } from './components';

/* Rendered text for different verification and type and status */
const iconStatusObj = {
  success: <SuccessShieldIcon />,
  expired: <ErrorShieldIcon />,
  notActive: <ErrorShieldIcon />,
};

const titleTextObj = {
  success: 'successTitleText',
  expired: 'expiredTitleText',
  notActive: 'notActiveTitleText',
};

const subtitleTextObj = {
  success: {
    lpn: 'lpnSuccessSubtitleText',
    rn: 'rnSuccessSubtitleText',
    cna: 'cnaSuccessSubtitleText',
  },
  expired: {
    lpn: 'lpnExpiredSubtitleText',
    rn: 'rnExpiredSubtitleText',
    cna: 'cnaExpiredSubtitleText',
  },
  notActive: {
    lpn: 'lpnNotActiveSubtitleText',
    rn: 'rnNotActiveSubtitleText',
    cna: 'cnaNotActiveSubtitleText',
  },
};

const descriptionTextObj = {
  success: {
    lpn: 'lpnSuccessDescriptionText',
    rn: 'rnSuccessDescriptionText',
    cna: 'cnaSuccessDescriptionText',
  },
  expired: {
    lpn: 'lpnExpiredDescriptionText',
    rn: 'rnExpiredDescriptionText',
    cna: 'cnaExpiredDescriptionText',
  },
  notActive: {
    lpn: 'lpnNotActiveDescriptionText',
    rn: 'rnNotActiveDescriptionText',
    cna: 'cnaNotActiveDescriptionText',
  },
};

const VerificationStatus = (props) => {
  const { t, verificationData, verificationStatus, verificationType } = props;

  const [state] = useState({
    icon: iconStatusObj[verificationStatus],
    titleText: titleTextObj[verificationStatus],
    subtitleText: subtitleTextObj[verificationStatus][verificationType],
    descriptionText: descriptionTextObj[verificationStatus][verificationType],
    subDescriptionText: 'subDescriptionText',
    boldText: 'boldText',
    buttonText: 'buttonText',
  });

  const {
    icon,
    titleText,
    subtitleText,
    descriptionText,
    subDescriptionText,
    boldText,
    buttonText,
  } = state;

  const fromEmployeeProfile = verificationData.source === 'employee_profile';

  return (
    <Styled.VerificationStatusContainer>
      <Styled.IconContainer>
        <CSSTransition
          timeout={1500}
          classNames="status-icon-animation"
          in={!!icon}
        >
          <div>{icon}</div>
        </CSSTransition>
      </Styled.IconContainer>
      <Styled.TitleText data-testid="status-title">
        {t(titleText)}
      </Styled.TitleText>
      <Styled.SubTitleText>{t(subtitleText)}</Styled.SubTitleText>
      <Styled.DescriptionText>
        {t(descriptionText, { state: props.licenseVerification.state })}
        {verificationStatus !== 'expired' && (
          <React.Fragment>
            {' '}
            {fromEmployeeProfile
              ? t('verification:employeeExtraText')
              : t('verification:applicantExtraText')}
          </React.Fragment>
        )}
      </Styled.DescriptionText>
      {!fromEmployeeProfile && (
        <React.Fragment>
          <Styled.SubDescriptionText>
            {t(subDescriptionText)}
          </Styled.SubDescriptionText>
          <Styled.BoldText>{t(boldText)}</Styled.BoldText>
        </React.Fragment>
      )}
      <Styled.ButtonContainer>
        <CustomLinkButton
          external={fromEmployeeProfile}
          url={
            fromEmployeeProfile
              ? `${window.hiringURL}/employees/profile/${verificationData.employee_id}`
              : `/profile/${verificationData.company_profile}`
          }
          text={
            fromEmployeeProfile
              ? t('verification:backToEmployee')
              : t(buttonText)
          }
        />
      </Styled.ButtonContainer>
    </Styled.VerificationStatusContainer>
  );
};

const mapStateToProps = (state) => ({
  licenseVerification: state.licenseVerificationReducer.licenseVerification,
});

VerificationStatus.propTypes = {
  licenseVerification: PropTypes.object, // response from verification API endpoint that we save to redux
  verificationStatus: PropTypes.string, // when activeStep is verificationStatus, this tells us which verification status is active
  verificationType: PropTypes.string, // tells us which type we are making verification for
  verificationData: PropTypes.object, // data extracted from URL string
};

export default connect(
  mapStateToProps,
  {},
)(withTranslation(['verificationStatus', 'verification'])(VerificationStatus));
