import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as Styled from 'timeoutMessageModal_styled';
import { closeTimeoutMessageModal } from '../../../../actions/modals_actions';

/* If we send `null` instead of some props, this will not be rendered.
If we send `null` instead of `timeout` prop then modal will not get close automatically.
We can only close it manually. */

class TimeoutMessageModal extends Component {
  componentDidMount() {
    const { modalProps } = this.props;
    /* Close modal after specific time */
    if (modalProps.timeout) {
      this.timeout();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  timeout = () => {
    const { modalProps } = this.props;
    setTimeout(() => {
      this.props.closeTimeoutMessageModal();
      /* If pageReload is true reload the page after timeout is done */
      if (modalProps.pageReload) {
        window.location.reload();
      }
    }, modalProps.timeout);
  };

  render() {
    const { modalProps } = this.props;
    return (
      <Styled.TimeoutMessageModal
        open
        onClose={this.props.closeTimeoutMessageModal}
        closeIcon
        data-gtm={modalProps.gtm ? modalProps.gtm : undefined}
      >
        <Styled.TimeoutMessageModalContent>
          <Styled.TimeoutMessageModalDescription>
            {modalProps.headerMessage ? (
              <Styled.TimeoutMessageModalHeader>
                <div>{modalProps.headerMessage}</div>
              </Styled.TimeoutMessageModalHeader>
            ) : null}

            {modalProps.bodyMessage ? (
              <Styled.TimeoutMessageModalBody>
                <div>{modalProps.bodyMessage}</div>
              </Styled.TimeoutMessageModalBody>
            ) : null}

            {modalProps.footerMessage ? (
              <Styled.TimeoutMessageModalFooter>
                <div>{modalProps.footerMessage}</div>
              </Styled.TimeoutMessageModalFooter>
            ) : null}
          </Styled.TimeoutMessageModalDescription>
        </Styled.TimeoutMessageModalContent>
      </Styled.TimeoutMessageModal>
    );
  }
}

const mapStateToProps = (state) => ({
  companyProfile: state.companyProfileReducer.companyProfile,
});

TimeoutMessageModal.propTypes = {
  modalProps: PropTypes.shape({
    headerMessage: PropTypes.string,
    bodyMessage: PropTypes.string,
    timeout: PropTypes.number,
    pageReload: PropTypes.bool,
    gtm: PropTypes.string,
  }),
  closeTimeoutMessageModal: PropTypes.func,
};

export default connect(mapStateToProps, { closeTimeoutMessageModal })(
  TimeoutMessageModal,
);
