/* This is a top level component and we should put styles
here that will be used throughout the whole app */

import styled from 'styled-components';

export const ScenesContainer = styled.div``;

export const BottomPage = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;

  & span {
    display: block;
    font-weight: bold;
    text-align: center;
    width: 70%;
  }
`;
