const saveCompanyProfile = (companyProfile) => ({
  type: 'SAVE_COMPANY_PROFILE',
  payload: {
    companyProfile,
  },
});

const saveCompanyProfileJobs = (companyProfileJobs) => ({
  type: 'SAVE_COMPANY_PROFILE_JOBS',
  payload: {
    companyProfileJobs,
  },
});

const saveMoreCompanyProfileJobs = (moreCompanyProfileJobs) => ({
  type: 'SAVE_MORE_COMPANY_PROFILE_JOBS',
  payload: {
    moreCompanyProfileJobs,
  },
});

const saveCompanyProfileJobsFilters = (filters) => ({
  type: 'SAVE_COMPANY_PROFILE_JOBS_FILTERS',
  payload: {
    filters,
  },
});

const saveIpInfo = (ipInfo) => ({
  type: 'SAVE_IP_INFO',
  payload: {
    ipInfo,
  },
});

const saveShortenUrl = (shortenUrl) => ({
  type: 'SAVE_SHORTEN_COMPANY_URL',
  payload: {
    shortenUrl,
  },
});

export {
  saveCompanyProfile,
  saveCompanyProfileJobs,
  saveMoreCompanyProfileJobs,
  saveCompanyProfileJobsFilters,
  saveIpInfo,
  saveShortenUrl,
};
