import React from 'react';
import PropTypes from 'prop-types';
import GetVerifiedNursys from './LicenseVerification/components/GetVerifiedNursys';
import GetVerifiedSV from './LicenseVerification/components/GetVerifiedSV';

const GetVerified = (props) => {
  const component = {
    nursysapi: (
      <GetVerifiedNursys
        verificationType={props.verificationType}
        verificationEngine={props.verificationEngine}
        changeActiveStep={props.changeActiveStep}
        longStateName={props.longStateName}
      />
    ),
    svapi: (
      <GetVerifiedSV
        verificationType={props.verificationType}
        changeActiveStep={props.changeActiveStep}
        longStateName={props.longStateName}
        verificationEngine={props.verificationEngine}
      />
    ),
  };

  return component[props.verificationEngine.toLowerCase()] || null;
};

GetVerified.propTypes = {
  // used to change activeStep which changes the verification screen
  changeActiveStep: PropTypes.func,
  // data extracted from URL string
  verificationData: PropTypes.object,
  // lets us know which verification type we are one (LPN, RN...)
  verificationType: PropTypes.string,
  // full name of the state
  longStateName: PropTypes.string,
  // Verification engine used (Streamline Verify or Nursys APIs)
  verificationEngine: PropTypes.string,
};

export default GetVerified;
