const jobDescriptionDefaultState = {
  jobDescription: null, // Object
  shortenJobDescriptionUrl: null, // Object
  numberOfQuestions: null,
};

const jobDescriptionReducer = (state = jobDescriptionDefaultState, action) => {
  switch (action.type) {
    case 'SAVE_JOB_DESCRIPTION':
      return { ...state, jobDescription: { ...action.payload.jobDescription } };
    case 'SET_JOB_DESCRIPTION':
      return {
        ...state,
        jobDescription: {
          ...state.jobDescription,
          ...action.payload.jobDescription,
        },
      };
    case 'SAVE_SHORTEN_JOB_DESCRIPTION_URL':
      return {
        ...state,
        shortenJobDescriptionUrl: {
          ...action.payload.shortenJobDescriptionUrl,
        },
      };
    case 'SAVE_NUMBER_OF_QUESTIONS':
      return { ...state, numberOfQuestions: action.payload.numberOfQuestions };
    default:
      return state;
  }
};

export default jobDescriptionReducer;
