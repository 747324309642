import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import * as Styled from 'jobDescriptionFullApplyForm_styled';
import { Accordion } from 'semantic-ui-react';
import AnimateHeight from 'react-animate-height';
import SignatureCanvas from 'react-signature-canvas';
import {
  changeQuestionExpand,
  saveTrumanQuestionsInformation,
  saveSubmitRequirements,
} from '../../../../../../../../actions/jobApplication_actions';

class SignatureQuestion extends Component {
  state = {
    signatureImage: false,
    inputSuccessCheck: {
      // all the properties here can be only true or false. We put null at beginning in order to not apply statedesign when app first renders
      signatureInput: false,
    },
  };

  sigPad = {};

  componentDidMount() {
    const { questionData, answers } = this.props;
    /* If there is external_link property it means that we already saved some kind of Signature */
    if (
      answers[questionData.question_id].uploaded_file &&
      answers[questionData.question_id].uploaded_file.external_link
    ) {
      this.setState({
        signatureImage: true,
      });
    }
    this.checkOptionalConditions();
  }

  componentDidUpdate(prevProps) {
    const { questionData, answers } = this.props;

    const checkChangedInputs = () => {
      /* Compare previous state and current for all monitored inputs and if its changed we
      call the function */
      /* -----signatureInput------ */
      let signatureInput;

      /* When we make signature we have "data" property that we need to send with payload but in API response we don't have it anymore.
      Instead we have "external_link" property. After we clear the signature we reset it back to "data" property but we don't
      save it unless user puts in a new Signature. This means that we have to track changes on two properties "data" and if we cleared the signature
      then change from "extrnal_link" to "data" */

      if (
        prevProps.answers[
          questionData.question_id
        ].uploaded_file.hasOwnProperty('data') &&
        answers[questionData.question_id].uploaded_file.hasOwnProperty(
          'data',
        ) &&
        JSON.stringify(
          prevProps.answers[questionData.question_id].uploaded_file.data,
        ) !==
          JSON.stringify(answers[questionData.question_id].uploaded_file.data)
      ) {
        signatureInput = true;
      } else if (
        prevProps.answers[
          questionData.question_id
        ].uploaded_file.hasOwnProperty('external_link') &&
        answers[questionData.question_id].uploaded_file.hasOwnProperty('data')
      ) {
        signatureInput = true;
      } else {
        signatureInput = false;
      }

      /* -----signatureInput END------ */
      if (signatureInput) {
        /* Even though we save it on separate endpoint we still need to check if it's submit ready when clicking submit button */
        this.checkOptionalConditions();
      }
    };

    checkChangedInputs();
  }

  checkOptionalConditions = () => {
    const { questionData, answers, answersRequirements } = this.props;

    /* Make conditions when specific inputs are considered true and successfully filled out */
    /* --------------------- */
    let signatureInput = false;
    /* if data or external_link length is more then 0 that means that we just uploaded the signature or we already have */
    if (
      (answers[questionData.question_id].uploaded_file.data &&
        answers[questionData.question_id].uploaded_file.data.length > 0) ||
      (answers[questionData.question_id].uploaded_file.external_link &&
        answers[questionData.question_id].uploaded_file.external_link.length >
          0)
    ) {
      signatureInput = true;
    }

    /* --------------------- */

    /* Set new state after checking conditions for the inputs */
    this.setState(
      {
        inputSuccessCheck: {
          ...this.state.inputSuccessCheck,
          signatureInput,
        },
      },
      () => {
        /* For questions that are not optional every input needs to be filled out
        correctly in order for us to be able to submit. */
        if (answersRequirements[questionData.question_id].optional === false) {
          /* We turn the object into array of boolean values */
          const inputSuccessList = Object.values(this.state.inputSuccessCheck);

          let submitReady = true;
          /* If one of the values in the array is false, it means one of the inputs
          is not filled correctly and we set submitReady to false.
          Until submit ready is true we can not submit application. */
          for (let i = 0; i < inputSuccessList.length; i += 1) {
            if (inputSuccessList[i] === false) {
              submitReady = false;
              break;
            }
          }
          /* We save if the question is submitReady or not to redux store */
          this.props.saveSubmitRequirements(
            questionData.question_id,
            submitReady,
          );
        }
        /* If question is optional inputs don't need to be filled out in order for us
        to submit so we set submitReady to true in redux store */
        if (answersRequirements[questionData.question_id].optional === true) {
          this.props.saveSubmitRequirements(questionData.question_id, true);
        }
      },
    );
  };

  trim = () => {
    const { questionData } = this.props;
    /* trim the white space, turn to base64 and save to redux store */
    const base64 = this.sigPad.getTrimmedCanvas().toDataURL('image/png');
    this.handleInputChange(questionData, 'signature', base64);
  };

  handleActionButton = () => {
    const { questionData } = this.props;
    const clearState = () => {
      this.setState({
        signatureImage: false,
      });

      /* reset "external_link" property and set it to "data" */
      const propertyValue = {
        uploaded_file: {
          mime_type: null,
          data: null,
        },
      };
      this.props.saveTrumanQuestionsInformation(
        questionData.question_id,
        propertyValue,
      );
    };
    /* If signatureImage is true that means that we are rendering an image of already made
     signature and not signaturePad for making new signature */
    if (this.state.signatureImage) {
      clearState();
    } else {
      /* Empty Signature pad for making new signature */
      this.sigPad.clear();
      clearState();
    }
  };

  handleInputChange = (questionData, property, value) => {
    /* Extract mime_type and data from generated base64 string, add notes as a tag for signature */
    const mime_type = value.substring(
      value.indexOf(':') + 1,
      value.indexOf(';'),
    );
    const data = value.substring(value.indexOf(',') + 1);

    const notes = 'bolivar_signature';

    const propertyValue = {
      uploaded_file: {
        mime_type,
        data,
        notes,
      },
    };
    this.props.saveTrumanQuestionsInformation(
      questionData.question_id,
      propertyValue,
    );
  };

  handleQuestionExpand = (questionType) => {
    const { questionData } = this.props;
    this.props.changeQuestionExpand({
      questionType,
      id: questionData.question_id,
    });
  };

  checkQuestionExpandState() {
    const { questionExpand, questionData } = this.props;
    let questionState = false;
    if (questionExpand[this.props.questionType].length > 0) {
      const filteredQuestion = questionExpand[this.props.questionType].filter(
        (question) => {
          if (question.id === questionData.question_id) {
            return question;
          }
        },
      );
      questionState = filteredQuestion[0].expand;
    }
    return questionState;
  }

  checkQuestionDesign(property) {
    const { firstTimeSubmitButtonClicked, answersRequirements, questionData } =
      this.props;
    const { inputSuccessCheck } = this.state;

    /* Depending if input or some other element on the page is filled out correctly,
    wrongly or nothing we change design */

    let warning;
    let success;
    let neutral;

    const returnDesignPattern = () => {
      if (warning) {
        return warning;
      }

      if (success) {
        return success;
      }

      return neutral;
    };

    /* Checks for which design we are gonna use on which element */
    /* ---------------------------------------------- */
    const checkInputWarning = (name) => {
      /* Warning input design can only show up after we clicked the submit button, if
      the question is not optional and input is not filled out correctly */
      if (
        firstTimeSubmitButtonClicked &&
        !answersRequirements[questionData.question_id].optional &&
        inputSuccessCheck[name] === false
      ) {
        return 'warning';
      }
    };

    const checkInputSuccess = (name) => {
      /* We show success input design before we hit submit button for the first
      time and after also so the only condition is that input is filled out correctly. */
      if (inputSuccessCheck[name] === true) {
        return 'success';
      }
    };

    /* We show warning design only after submit button is clicked for the first TimeRanges,
    if question is not optional and if one of the inputs is not filled out correctly */
    const checkQuestionContainerWarning = () => {
      if (
        firstTimeSubmitButtonClicked &&
        !answersRequirements[questionData.question_id].optional &&
        Object.keys(inputSuccessCheck).some((k) => !inputSuccessCheck[k]) // check if any of the property in inputSuccessCheck is false
      ) {
        return 'warning';
      }
    };

    /* Check the whole question container design. If all the inputs are filled out correctly we
    show success design */

    const checkQuestionContainerSuccess = () => {
      if (
        Object.keys(inputSuccessCheck).every(
          (k) => inputSuccessCheck[k] === true,
        )
      ) {
        return 'success';
      }
    };
    /* ---------------------------------------------- */

    /* based on the input that we are checking we are passing that property to check design */
    switch (property) {
      case 'signatureInput':
        warning = checkInputWarning(property);
        success = checkInputSuccess(property);
        neutral = 'neutral';

        return returnDesignPattern();

      case 'questionContainer':
        warning = checkQuestionContainerWarning();
        success = checkQuestionContainerSuccess();
        neutral = 'neutral';

        return returnDesignPattern();
      default:
        return returnDesignPattern();
    }
  }

  renderCanvasOrImage = () => {
    const { questionData, answers } = this.props;
    /* If signatureImage is true render the existing image of already made signature.
    If not render signature pad for making new signature */
    if (this.state.signatureImage) {
      return (
        <img
          src={
            answers[questionData.question_id] &&
            answers[questionData.question_id].uploaded_file &&
            answers[questionData.question_id].uploaded_file.external_link
          }
          alt="Signature"
        />
      );
    }

    return (
      <SignatureCanvas
        canvasProps={{ width: 253, height: 63 }}
        ref={(ref) => {
          this.sigPad = ref;
        }}
        onEnd={this.trim} /* onEnd activates when user releases the mouse */
      />
    );
  };

  renderTextQuestions() {
    return (
      <Styled.QuestionBlock>
        <Styled.FormGroup>
          <Styled.FormField>
            <Styled.SignatureCanvasWrapper
              statedesign={this.checkQuestionDesign('signatureInput')}
            >
              {this.renderCanvasOrImage()}
            </Styled.SignatureCanvasWrapper>
          </Styled.FormField>
        </Styled.FormGroup>
      </Styled.QuestionBlock>
    );
  }

  render() {
    const { questionData, answersRequirements, answers, t } = this.props;
    return (
      <Styled.QuestionContainer
        statedesign={this.checkQuestionDesign('questionContainer')}
      >
        <Accordion>
          <Styled.AccordionTitle
            active={this.checkQuestionExpandState()}
            onClick={() => this.handleQuestionExpand(this.props.questionType)}
          >
            <Styled.AccordionTitleTextContainer>
              <Styled.QuestionIconBorder>
                <Styled.QuestionIcon name="pencil" />{' '}
              </Styled.QuestionIconBorder>

              <Styled.AccordionTitleText>
                {questionData.question_text}
                <br />
                {answersRequirements[questionData.question_id].optional ? (
                  <span>({t('optional')})</span>
                ) : null}
              </Styled.AccordionTitleText>
            </Styled.AccordionTitleTextContainer>

            {this.checkQuestionExpandState() ? (
              <Styled.ExpandIcon name="chevron circle up" />
            ) : (
              <Styled.ExpandIcon name="chevron circle down" />
            )}
          </Styled.AccordionTitle>
          <Styled.AccordionContent active={this.checkQuestionExpandState()}>
            <AnimateHeight
              animateOpacity
              duration={300}
              height={this.checkQuestionExpandState() ? 'auto' : 0}
            >
              {this.renderTextQuestions()}
              <Styled.SignaturePostText>
                {!this.state.signatureImage && t('signatureIndication')}
              </Styled.SignaturePostText>
              {/* Only show CLEAR button when we uploaded the Signature already */}
              {(answers[questionData.question_id].uploaded_file.external_link &&
                answers[questionData.question_id].uploaded_file.external_link
                  .length > 0) ||
              (answers[questionData.question_id].uploaded_file.data &&
                answers[questionData.question_id].uploaded_file.data.length >
                  0) ? (
                <Styled.SignatureActionText>
                  <div onClick={() => this.handleActionButton()}>
                    {t('signatureClear')}
                  </div>
                </Styled.SignatureActionText>
              ) : null}
            </AnimateHeight>
          </Styled.AccordionContent>
        </Accordion>
      </Styled.QuestionContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  answers: state.jobApplicationReducer.answers,
  answersRequirements:
    state.jobApplicationReducer.submitRequirements.answersRequirements,
  firstTimeSubmitButtonClicked:
    state.jobApplicationReducer.submitRequirements.firstTimeSubmitButtonClicked,
  questionExpand: state.jobApplicationReducer.questionExpand,
});

SignatureQuestion.propTypes = {
  answers: PropTypes.object, // comes from parent 'jobApplicationReducer'. Contains all the answers for the questions coming from truman, type of the question and id.
  answersRequirements: PropTypes.object, // comes from parent 'jobApplicationReducer'. Contains if questions coming from truman are optional or not. It also contains submitReady property that tells us if specific question is filled out ok.
  changeQuestionExpand: PropTypes.func, // used to expand or collapse question accordion.
  firstTimeSubmitButtonClicked: PropTypes.bool, // if true it means that "Submit" form button was clicked at least once.
  questionData: PropTypes.object, // passed down from parent 'JobDescriptionFullApplyFormQuestions' component. It contains all the data about that specific question coming from truman.
  questionExpand: PropTypes.object, // contains all the questions and tells if question accordion is expanded or not.
  questionType: PropTypes.string, // passed down from parent 'JobDescriptionFullApplyFormQuestions' component. It says which questionType question belongs to.
  saveSubmitRequirements: PropTypes.func, // Used to save if the question is submitReady or not to redux store.
  saveTrumanQuestionsInformation: PropTypes.func, // used to save user answers to redux store for every question.
};

export default connect(mapStateToProps, {
  changeQuestionExpand,
  saveTrumanQuestionsInformation,
  saveSubmitRequirements,
})(withTranslation('jobDescriptionFullApply')(SignatureQuestion));
