/* Controls opening(rendering) and closing of modal components */
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import TimeoutMessageModal from 'TimeoutMessageModal';
import TimeoutImageModal from 'TimeoutImageModal';
import RegisterModal from 'RegisterModal';
import LoginModal from 'LoginModal';
import SignOutModal from 'SignOutModal';
import SubmitModal from 'SubmitModal';
import ResetPasswordModal from 'ResetPasswordModal';
import ShareModal from 'ShareModal';
import NewPasswordModal from 'NewPasswordModal';

const ModalManager = (props) => (
  <Fragment>
    {props.modalsReducer.timeoutMessageModal.open && (
      <TimeoutMessageModal
        modalProps={props.modalsReducer.timeoutMessageModal.modalProps}
      />
    )}
    {props.modalsReducer.timeoutImageModal.open && (
      <TimeoutImageModal
        modalProps={props.modalsReducer.timeoutImageModal.modalProps}
      />
    )}
    {props.modalsReducer.registerModal.open && <RegisterModal />}
    {props.modalsReducer.loginModal.open && <LoginModal />}
    {props.modalsReducer.signOutModal.open && <SignOutModal />}
    {props.modalsReducer.submitModal.open && (
      <SubmitModal modalProps={props.modalsReducer.submitModal.modalProps} />
    )}
    {props.modalsReducer.resetPasswordModal.open && <ResetPasswordModal />}
    {props.modalsReducer.shareModal.open && <ShareModal />}
    {props.modalsReducer.newPasswordModal.open && (
      <NewPasswordModal
        modalProps={props.modalsReducer.newPasswordModal.modalProps}
      />
    )}
  </Fragment>
);

const mapStateToProps = (state) => ({
  modalsReducer: state.modalsReducer,
});

export default connect(mapStateToProps, {})(ModalManager);
