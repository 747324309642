/* actions for closing and opening of modals */
/* to skip specific prop send null instead */

const openTimeoutMessageModal = ({
  headerMessage,
  bodyMessage,
  footerMessage,
  timeout,
  pageReload,
  gtm,
}) => ({
  type: 'OPEN_TIMEOUT_MESSAGE_MODAL',
  payload: {
    modalProps: {
      headerMessage,
      bodyMessage,
      footerMessage,
      timeout,
      pageReload,
      gtm,
    },
  },
});

const closeTimeoutMessageModal = () => ({
  type: 'CLOSE_TIMEOUT_MESSAGE_MODAL',
});

const openTimeoutImageModal = ({
  image,
  bodyMessage,
  timeout,
  pageReload,
  redirect,
  removeCloseModalOptions,
  gtm,
}) => ({
  type: 'OPEN_TIMEOUT_IMAGE_MODAL',
  payload: {
    modalProps: {
      image,
      bodyMessage,
      timeout,
      pageReload,
      redirect,
      removeCloseModalOptions,
      gtm,
    },
  },
});

const closeTimeoutImageModal = () => ({
  type: 'CLOSE_TIMEOUT_IMAGE_MODAL',
});

const openRegisterModal = (segmentData) => ({
  type: 'OPEN_REGISTER_MODAL',
  payload: {
    segmentData,
  },
});

const closeRegisterModal = () => ({
  type: 'CLOSE_REGISTER_MODAL',
});

const openLoginModal = (segmentData) => ({
  type: 'OPEN_LOGIN_MODAL',
  payload: {
    segmentData,
  },
});

const closeLoginModal = () => ({
  type: 'CLOSE_LOGIN_MODAL',
});

const openSignOutModal = () => ({
  type: 'OPEN_SIGN_OUT_MODAL',
});

const closeSignOutModal = () => ({
  type: 'CLOSE_SIGN_OUT_MODAL',
});

const openSubmitModal = ({ interviewGuide }) => ({
  type: 'OPEN_SUBMIT_MODAL',
  payload: {
    modalProps: {
      interviewGuide,
    },
  },
});

const closeSubmitModal = () => ({
  type: 'CLOSE_SUBMIT_MODAL',
});

const openResetPasswordModal = (segmentData) => ({
  type: 'OPEN_RESET_PASSWORD_MODAL',
  payload: {
    segmentData,
  },
});

const closeResetPasswordModal = () => ({
  type: 'CLOSE_RESET_PASSWORD_MODAL',
});

const openNewPasswordModal = ({ token }) => ({
  type: 'OPEN_NEW_PASSWORD_MODAL',
  payload: {
    modalProps: {
      token,
    },
  },
});

const closeNewPasswordModal = () => ({
  type: 'CLOSE_NEW_PASSWORD_MODAL',
});

const openShareModal = () => ({
  type: 'OPEN_SHARE_MODAL',
});

const closeShareModal = () => ({
  type: 'CLOSE_SHARE_MODAL',
});

export {
  openTimeoutMessageModal,
  closeTimeoutMessageModal,
  openTimeoutImageModal,
  closeTimeoutImageModal,
  openRegisterModal,
  closeRegisterModal,
  openLoginModal,
  closeLoginModal,
  openSignOutModal,
  closeSignOutModal,
  openSubmitModal,
  closeSubmitModal,
  openResetPasswordModal,
  closeResetPasswordModal,
  openShareModal,
  closeShareModal,
  openNewPasswordModal,
  closeNewPasswordModal,
};
