import React, { Component } from 'react';
import { connect } from 'react-redux';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { Button } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
/* Styled Components */
import * as Styled from 'companyProfileJobsFilters_styled';
import queryString from 'query-string';
import { getCompanyProfileJobsAction } from '../../../../../actions/api_actions';
import {
  saveCompanyProfileJobsFilters,
  saveCompanyProfileJobs,
} from '../../../../../actions/companyProfile_actions';
import { setCity, setCityCenter } from '../../../../../actions/global_actions';

class CompanyProfileJobsFilters extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.timeout = 0;

    this.handleSearchbarChange = this.handleSearchbarChange.bind(this);
  }

  componentDidMount() {
    const { ipInfo, allTeamsSearch } = this.props;
    if (!allTeamsSearch) {
      /* In case someone clicks search without changing location on first load we set it up in store */
      this.props.saveCompanyProfileJobsFilters({
        address: `${ipInfo?.city}, ${ipInfo?.country_name}`,
        lat: ipInfo?.latitude,
        lng: ipInfo?.longitude,
      });
    }
    this.props.saveCompanyProfileJobsFilters({ searchbar: '' });
  }

  componentDidUpdate = (prevProps) => {
    if (
      prevProps.companyProfileJobsFilters.searchbar !==
      this.props.companyProfileJobsFilters.searchbar
    ) {
      /* Don't call API until 400ms has passed. We do this so it doesn't trigger on every
      letter but when user stops typing */
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.getProfileJobs();
      }, 800);
    }
  };

  getProfileJobs = () => {
    const {
      allTeamsSearch,
      filteredIndustries,
      backfilledJobs,
      debug,
      i18n,
      city,
      utm,
    } = this.props;
    const { language } = i18n;
    this.props.scrollToTop();
    const hasSelectedCity = !!(city && city.city && city.state);

    this.props.getCompanyProfileJobsAction(
      window.activeEnvironment,
      1,
      this.props.companyProfileJobsFilters.searchbar,
      !hasSelectedCity ? this.props.companyProfileJobsFilters?.lat : null,
      !hasSelectedCity ? this.props.companyProfileJobsFilters?.lng : null,
      allTeamsSearch ? '' : this.props.companyProfile.teams_to_show,
      filteredIndustries,
      backfilledJobs,
      debug,
      language,
      city?.city,
      city?.state,
      this.props.companyProfileJobsFilters?.radius,
      utm,
    );
  };

  handleLocationChange = (address) => {
    /* We are deleting latitude and longitude value here so the search doesn't trigger on some non
    existent google place that we put in input */
    this.props.saveCompanyProfileJobsFilters({
      address,
      lat: '',
      lng: '',
    });
  };

  handleSearchbarChange = (e) => {
    this.props.saveCompanyProfileJobsFilters({ searchbar: e.target.value });
  };

  handleLocationSelect = (address) => {
    const { allTeamsSearch, setCity, setCityCenter, saveCompanyProfileJobs } =
      this.props;
    /* We are setting up the address and triggering latLang search only on select from offered
    google place in input dropdown */
    this.props.saveCompanyProfileJobsFilters({ address });
    this.props.scrollToTop();

    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        if (allTeamsSearch) {
          setCity(null);
          setCityCenter(null);
          saveCompanyProfileJobs([]);
        }
        this.props.saveCompanyProfileJobsFilters({
          ...latLng,
          radius: '15',
        });
      })
      .then(() => this.getProfileJobs())
      .catch((error) => console.error('Error', error));
  };

  render() {
    const {
      companyProfile,
      companyProfileJobsFilters,
      allTeamsSearch,
      updateJobs,
      debug,
      t,
      routeData,
    } = this.props;
    const headerMessage = t('searchTitle');
    return (
      <Styled.BlockContainer allTeamsSearch={allTeamsSearch}>
        {allTeamsSearch ? (
          <b>
            <h2>{headerMessage}</h2>
            <br />
          </b>
        ) : (
          <h4>{headerMessage}</h4>
        )}

        <Styled.SearchInput
          placeholder={t('searchInput')}
          value={companyProfileJobsFilters.searchbar}
          onChange={(e) => this.handleSearchbarChange(e)}
        />

        <PlacesAutocomplete
          value={companyProfileJobsFilters.address}
          onChange={this.handleLocationChange}
          onSelect={this.handleLocationSelect}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <Styled.LocationInputWrapper>
              <Styled.LocationInput
                customcolor={
                  !allTeamsSearch &&
                  companyProfile &&
                  companyProfile.primary_color
                    ? companyProfile.primary_color
                    : '#000'
                }
                action={
                  <Button onClick={this.getProfileJobs}>
                    {t('searchButton')}
                  </Button>
                }
                placeholder={t('searchLocation')}
                icon="map marker alternate"
                iconPosition="left"
                {...getInputProps({
                  placeholder: t('searchLocation'),
                  className: 'location-search-input',
                })}
              />
              {/* This Element is only to align autocomplete container below the input on
              desktop screen */}
              <Styled.EmptyElement minWidth={768} />
              <Styled.AutoCompleteDropdownContainer>
                {loading && <div>{t('searchLoading')}</div>}
                {suggestions.map((suggestion) => {
                  const className = suggestion.active
                    ? 'suggestion-item--active'
                    : 'suggestion-item';
                  // inline style for demonstration purpose
                  const style = suggestion.active
                    ? {
                        backgroundColor: '#fafafa',
                        cursor: 'pointer',
                      }
                    : {
                        backgroundColor: '#ffffff',
                        cursor: 'pointer',
                      };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </Styled.AutoCompleteDropdownContainer>
            </Styled.LocationInputWrapper>
          )}
        </PlacesAutocomplete>
        {routeData.key === 'search' && (
          <Styled.LabelCheckbox
            style={{
              float: 'right',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Styled.InputCheckbox
              checked={debug}
              onChange={updateJobs}
              type="checkbox"
            />
            Debug Mode
          </Styled.LabelCheckbox>
        )}
      </Styled.BlockContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  companyProfile: state.companyProfileReducer.companyProfile,
  companyProfileJobs: state.companyProfileReducer.companyProfileJobs,
  companyProfileJobsFilters:
    state.companyProfileReducer.companyProfileJobsFilters,
  ipInfo: state.companyProfileReducer.ipInfo,
  filteredIndustries: state.globalReducer.filteredIndustries,
  routeData: state.globalReducer.routeData,
  city: state.globalReducer.city,
});

export default connect(mapStateToProps, {
  saveCompanyProfileJobsFilters,
  getCompanyProfileJobsAction,
  setCity,
  setCityCenter,
  saveCompanyProfileJobs,
})(withTranslation('internal')(CompanyProfileJobsFilters));
