import styled from 'styled-components';
import { Input, Responsive } from 'semantic-ui-react';

export const BlockContainer = styled.div`
  /*   margin: 25px 0 0 0; */
  margin-top: 25px;
  width: ${({ allTeamsSearch }) => (allTeamsSearch ? '60' : '100')}%;
  ${({ allTeamsSearch }) =>
    allTeamsSearch &&
    `
    text-align: center;
  `}
`;

export const SearchInput = styled(Input).attrs({
  'data-cy': 'keyword-search-input',
})`
  width: 40%;

  input {
    border-radius: 0px !important;
  }

  @media (max-width: 992px) {
    width: 100%;
  }
`;

export const LocationInput = styled(Input).attrs({
  'data-cy': 'location-search-input',
})`
  width: 60%;
  input {
    border-radius: 0px !important;
  }

  button {
    color: #fff !important;
    background-color: ${(props) => props.customcolor} !important;
    border-radius: 0px !important;
    font-family: 'NeutrafaceBold' !important;
  }

  @media (max-width: 992px) {
    width: 100% !important;
  }
`;

export const LocationInputWrapper = styled.div`
  display: inline;
`;

export const AutoCompleteDropdownContainer = styled.div`
  display: inline-block;
  width: 60%;

  @media (max-width: 992px) {
    width: 100% !important;
  }
`;
export const EmptyElement = styled(Responsive)`
  display: inline-block;
  width: 40%;
`;

export const LabelCheckbox = styled.label`
  cursor: pointer;
  display: flex;
  font-weight: normal;
  margin-bottom: 0;
  word-break: break-all;
`;

export const InputCheckbox = styled.input`
  margin-right: 0.5rem;
`;
