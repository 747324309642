import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as Styled from 'jobDescriptionFullApplyDescription_styled';
import Scroll from 'react-scroll';
import { Checkbox, Grid } from 'semantic-ui-react';
import JobDescriptionBrandLogo from 'JobDescriptionBrandLogo';
import JobDescriptionBrandName from 'JobDescriptionBrandName';
import JobDescriptionSalary from 'JobDescriptionSalary';
import JobDescriptionExperience from 'JobDescriptionExperience';
import JobDescriptionLevelOfEducation from 'JobDescriptionLevelOfEducation';
import JobDescriptionSummary from 'JobDescriptionSummary';
import JobDescriptionFullApplyFormResumeRender from 'JobDescriptionFullApplyFormResumeRender';
import CompanyProfileImageGallery from 'CompanyProfileImageGallery';
import JobDescriptionCompanyData from 'JobDescriptionCompanyData';
import JobDescriptionMap from 'JobDescriptionMap';
import CompanyProfileVideo from 'CompanyProfileVideo';
import { withTranslation } from 'react-i18next';
import {
  openRegisterModal,
  openTimeoutImageModal,
} from '../../../../../../actions/modals_actions';
import {
  desktopDesign,
  mobileDesign,
  extractUTM,
} from '../../../../../../helperFunctions/helperFunctions';
import {
  postNewFullApplyFormAction,
  getFullApplyFormAction,
  getAppliedJobsListAction,
} from '../../../../../../actions/api_actions';
import {
  adjustFullApplyFormResponse,
  startFullApplyFormActivate,
} from '../../../../../../actions/jobApplication_actions';
import ErrorBoundary from '../../../../../sharedComponents/errorPages/ErrorBoundary';
import { sanitizeString } from '../../../../../../../util';
import { getWotcData } from '../../../../../../api/api';
// import { ErrorBoundary } from 'react-error-boundary';

const { scroller } = Scroll;

const CompanyProfileDescriptionScroll = Scroll.Element;
const CompanyProfilePhotosScroll = Scroll.Element;
const CompanyProfileVideosScroll = Scroll.Element;
const JobDescriptionScroll = Scroll.Element;
const JobLocationScroll = Scroll.Element;

class JobDescriptionFullApplyDescription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allowFullFormOpen: false,
      applyMethod: 'new',
      applyFormId: null,
      mobileBlock: false,
      segmentData: {
        openRegisterData: {
          entry_method: 'register',
          user_type: 'applicant',
        },
      },
      utmData: {
        utm_campaign: 'bolivar',
      },
      hasAgreedToTerms: false,
      wotcData: null,
    };
  }

  componentDidMount() {
    const { applicant } = this.props;
    /* Extract utm's from url string. We must do it before we run "checkApplyStatus" because if we are using interviewGuide,
    checkApplyStatus is triggered automatically and if it's the first time opening hte job we are making POST request to initialize it where we are sending UTMs in payload. */
    const utms = extractUTM();

    this.setState((prevState) => ({
      ...prevState,
      utmData: {
        ...prevState.utmData,
        ...utms,
      },
    }));

    /* We get 'applicant' in redux store and localStorage after user login/register/reset */
    if (applicant) {
      this.checkApplyStatus();
      this.setState({
        allowFullFormOpen: true,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.applicant === null && this.props.applicant) {
      this.setState({
        allowFullFormOpen: true,
      });
    }
    if (prevProps.applicant && this.props.applicant === null) {
      this.setState({
        allowFullFormOpen: false,
      });
    }

    /* There is some delay between saving applicant to redux store and saving
    appliedJobsList to store, So we must wait for appliedJobsList property to
    update before we check for apply status of specific job */
    if (
      JSON.stringify(prevProps.appliedJobsList) !==
      JSON.stringify(this.props.appliedJobsList)
    ) {
      this.checkApplyStatus();
    }
  }

  async checkApplyStatus() {
    /* Check if there is already applied job id that is the same as slug job id,
          among the list of applied jobs for specific user */
    if (this.props.appliedJobsList) {
      /* if applyMethod is "new", then applyFormId will be "null". In case we already
      tried to apply to this jobs form, then applyMethod will be "continue" and
      applyFormId will have a value of the that form id. */
      let applyMethod = 'new';
      let applyFormId = null;
      for (let i = 0; i < this.props.appliedJobsList.length; i += 1) {
        if (
          this.props.appliedJobsList[i].job_id ==
          this.props.matchData.params.slug
        ) {
          /* If form is already submited we block button from clicking, if not we change
          it to continue */
          applyMethod = this.props.appliedJobsList[i].submitted
            ? 'block'
            : 'continue';
          applyFormId = this.props.appliedJobsList[i].id;
          break;
        } else {
          applyMethod = 'new';
        }
      }
      this.setState(
        {
          applyMethod,
          applyFormId,
          wotcData: applyFormId
            ? await getWotcData(
                window.activeEnvironment,
                this.props.applicant.token,
                applyFormId,
              )
            : null,
        },
        () => {
          if (
            this.state.applyMethod === 'block' &&
            this.state.wotcData?.wotc_redirect_url &&
            this.state.wotcData?.wotc_status !== 'Completed'
          ) {
            window.location.replace(this.state.wotcData?.wotc_redirect_url);
          }

          if (
            /* Automatically trigger button press if
            1. there is interview_guide=1 query in url
            2. if apply method is not saveSubmitButtonClicked. When it's blocked it means we already applied for the job
            3. if position is not "equal". If appliedJobsList change in componentDidUpdate triggers more then once in a row, position will go from
            "leftSideExpanded" => "equal" => "rightSideExpanded". We wanna stop it at "equal".
            "checkApplyStatus" can be triggered from componentDidMount and componentDidUpdate. If we are opening job for the first time it wil be initialized and applyMethod from DidMount will be "new" but "checkApplyStatus" function can be triggered again from DidUpdate and at this point applyMethod will be "continue". So we must stop the second call to "applyButtonControl" function because it would overwrite things like if some questions are submitReady.
            On desktop we block it by setting position !== 'equal' which changes to after we call it for the first time.
            On mobile we block it with "mobileBlock" property which we change to 'true' after we call it first time on mobile design.
            */
            // this.props.interviewGuide === true &&
            this.state.applyMethod !== 'block' &&
            this.props.position !== 'equal' &&
            this.state.mobileBlock !== true
          ) {
            this.applyButtonControl('automatic_click');
          }
        },
      );
    }
  }

  decodeJobIdParam = () => {
    let jobId = String(this.props.matchData.params.slug);
    if (isNaN(jobId)) {
      const hexJobId = `${jobId.slice(0, 2)}${jobId.slice(3)}`;
      // eslint-disable-next-line radix
      const allDecodeNumber = String(parseInt(`0x${hexJobId}`));
      const lastCharacter = BigInt(allDecodeNumber[allDecodeNumber.length - 1]);
      const decodeNumber = BigInt(allDecodeNumber.slice(0, -1));
      // eslint-disable-next-line no-bitwise
      jobId = Number(decodeNumber >> lastCharacter);
    }
    return jobId;
  };

  getJobIdParamToRequest = () => {
    const jobIdToEvaluate = this.decodeJobIdParam();
    const jobIdToRequest = parseInt(jobIdToEvaluate, 10) % 100000000;
    return parseInt(jobIdToRequest, 10);
  };

  handleInitiateFullApplyForm() {
    if (this.state.applyMethod === 'new') {
      const job_id = this.getJobIdParamToRequest();
      this.props
        .postNewFullApplyFormAction(
          window.activeEnvironment,
          job_id,
          this.props.applicant.token,
          this.state.utmData,
          this.props.interviewGuide,
        )
        /* There can be a case where somebody logged in to app before and then went and clicked
        on a new job to apply. After sending post request for initialization we will not fetch
        new applied jobs with "getAppliedJobsAction" inside o "RouteComponents" because that
        one triggers when applicant property is saved to redux store (for example when somebody
        registers/logins). Because of that we are calling it here again to update the list of
        applied jobs. */
        .then((response) => {
          this.props.getAppliedJobsListAction(
            window.activeEnvironment,
            this.props.matchData.params.slug,
            this.props.applicant.value.id,
            this.props.interviewGuide,
            this.props.applicant.token,
          );
          this.props.adjustFullApplyFormResponse(response);
        })
        .catch((error) => {
          this.props.openTimeoutImageModal({
            image: 'error',
            bodyMessage: error.developer_message,
            timeout: 5000,
            pageReload: false,
            redirect: false,
            removeCloseModalOptions: false,
          });
        });
    } else if (this.state.applyMethod === 'continue') {
      this.props
        .getFullApplyFormAction(
          window.activeEnvironment,
          this.props.matchData.params.slug,
          this.state.applyFormId,
          this.props.applicant.token,
          this.props.interviewGuide,
          this.state.utmData,
        )
        .then((response) => {
          this.props.adjustFullApplyFormResponse(response);
        })
        .catch((error) => {
          this.props.openTimeoutImageModal({
            image: 'error',
            bodyMessage: 'Failed to get full apply form.' || error,
            timeout: 5000,
            pageReload: false,
            redirect: false,
            removeCloseModalOptions: false,
          });
        });
    }
  }

  applyButtonControl(clickType, scrollDown) {
    if (this.state.allowFullFormOpen) {
      if (desktopDesign()) {
        if (this.props.position !== 'leftSideExpanded') {
          this.props.handleExpandColumns('leftSideButtonControl');
        } else {
          this.props.handleExpandColumns('rightSideButtonControl');
        }
      }

      if (mobileDesign()) {
        if (this.props.position === 'leftSideExpanded') {
          this.setState({
            mobileBlock: true,
          });
        }
      }

      /* We check for click type here since we only must call this functions automatically and not on button click on mobile design */
      if (clickType === 'automatic_click') {
        this.handleInitiateFullApplyForm();
        this.props.startFullApplyFormActivate(true);
      }

      /* If we are on the phone scroll to form automatically after clicking Continue. Scroll only onClick
      and don't scroll on auto login */

      if (mobileDesign() && clickType === 'button_click' && scrollDown) {
        scroller.scrollTo('jobDescriptionFullApplyFormScroll', {
          duration: 1000,
          delay: 50,
          smooth: true,
        });
      }
    } else {
      this.props.openRegisterModal(this.state.segmentData.openRegisterData);
    }
  }

  renderApplyText() {
    const { applyMethod } = this.state;
    const { t, applicant } = this.props;
    if (this.state.applyMethod === 'block') {
      return <Fragment>{t('applyButtonText')}</Fragment>;
    }

    if (applyMethod === 'continue') {
      return <Fragment>{t('continueButtonText')}</Fragment>;
    }
    if (applyMethod === 'new' && applicant) {
      return <Fragment>{t('applyButtonText')}</Fragment>;
    }

    if (applyMethod === 'new') {
      return (
        <div>
          <div>{t('applyButtonText')}</div>
        </div>
      );
    }
  }

  renderApplyFormButtonControl() {
    const configureCustomColor = () => {
      if (this.state.applyMethod === 'block') {
        return '#D4D5D6';
      }
      return this.props.companyProfile &&
        this.props.companyProfile.primary_color
        ? this.props.companyProfile.primary_color
        : '#000';
    };
    const { t } = this.props;
    /* Show different design button on mobile and desktop */
    if (desktopDesign()) {
      return (
        <ErrorBoundary tags={{ component: 'renderApplyFormButtonControl' }}>
          {/* Return text that user has already submitted this form */}
          {this.state.applyMethod === 'block' ? (
            <Styled.RightSideButtonControlBlockText>
              {t('alreadyApplied')}
            </Styled.RightSideButtonControlBlockText>
          ) : null}

          <Styled.RightSideButtonControl
            onClick={() => {
              this.applyButtonControl('button_click');
            }}
            customcolor={configureCustomColor()}
            disabled={this.state.applyMethod === 'block'}
          >
            {this.renderApplyText()}
          </Styled.RightSideButtonControl>
        </ErrorBoundary>
      );
    }
    return (
      <ErrorBoundary
        tags={{ component: 'renderApplyFormButtonControl' }}
        sectionName="Apply button"
      >
        {/* Return text that user has already submitted this form */}
        {this.state.applyMethod === 'block' ? (
          <Styled.RightSideButtonControlBlockTextMobile>
            {t('alreadyApplied')}
          </Styled.RightSideButtonControlBlockTextMobile>
        ) : null}
        <Styled.RightSideButtonControlMobile
          onClick={() => {
            this.applyButtonControl('button_click', 'scrollDown');
          }}
          customcolor={configureCustomColor()}
          disabled={this.state.applyMethod === 'block'}
        >
          {this.renderApplyText()}
        </Styled.RightSideButtonControlMobile>
      </ErrorBoundary>
    );
  }

  renderMobileButton = (t) => (
    <Styled.MobileScreen>
      <Styled.ApplyFormButtonMobileWrapper>
        <Styled.ApplyFormButtonContainer>
          {this.renderApplyFormButtonControl()}
        </Styled.ApplyFormButtonContainer>
      </Styled.ApplyFormButtonMobileWrapper>
    </Styled.MobileScreen>
  );

  renderResumeOrDescription() {
    const { t } = this.props;
    return this.props.interviewGuide &&
      this.state.applyMethod !== 'block' &&
      this.props.uploadedResumeFile &&
      this.props.uploadedResumeFile.resume_link &&
      this.state.allowFullFormOpen ? (
      <Grid.Row columns={1}>
        <Grid.Column
          width={this.props.position !== 'leftSideExpanded' ? 16 : 8}
        >
          <Styled.BlockContainer>
            <JobDescriptionFullApplyFormResumeRender />
          </Styled.BlockContainer>
        </Grid.Column>
      </Grid.Row>
    ) : (
      <Grid.Row columns={2}>
        <Grid.Column
          width={this.props.position !== 'leftSideExpanded' ? 16 : 8}
        >
          <Styled.BlockContainer>
            <JobDescriptionScroll name="jobDescriptionScroll" />
            {this.renderMobileButton(t)}
            <ErrorBoundary
              tags={{ component: 'JobDescriptionSummary' }}
              sectionName="Job Description Summary"
            >
              {!!this.props.jobDescription?.job_detail?.salary_min &&
                !!this.props.jobDescription?.job_detail?.salary_max &&
                this.props.jobDescription.job_detail.salary_min !== 0 &&
                this.props.jobDescription.job_detail.salary_max !== 0 && (
                  <JobDescriptionSalary />
                )}
              {!!this.props.jobDescription?.job_detail?.years_of_experience &&
                this.props.jobDescription.job_detail.years_of_experience !==
                  0 && <JobDescriptionExperience />}
              {!!this.props.jobDescription?.job_detail
                ?.min_education_required &&
                this.props.jobDescription.job_detail.min_education_required !==
                  'NONE' && <JobDescriptionLevelOfEducation />}
              <JobDescriptionSummary />
            </ErrorBoundary>
            {this.renderMobileButton(t)}
          </Styled.BlockContainer>
        </Grid.Column>
        <Grid.Column
          width={this.props.position !== 'leftSideExpanded' ? 16 : 8}
        >
          <ErrorBoundary
            tags={{ component: 'JobDescriptionMap' }}
            sectionName="Job Location"
          >
            <Styled.BlockContainer>
              <JobLocationScroll name="jobLocationScroll" />
              <JobDescriptionMap />
            </Styled.BlockContainer>
          </ErrorBoundary>
          {/* Hide Photo and Video if Blind job */}
          {this.props.jobDescription &&
          this.props.jobDescription.show_company_logo ? (
            <ErrorBoundary
              tags={{ component: 'CompanyData' }}
              sectionName="Company data (photos, video)"
            >
              <Styled.BlockContainer>
                <CompanyProfileDescriptionScroll name="companyProfileDescriptionScroll" />
                <JobDescriptionCompanyData />
              </Styled.BlockContainer>
              <Styled.BlockContainer>
                <CompanyProfileVideosScroll name="companyProfileVideosScroll" />
                <CompanyProfileVideo />
              </Styled.BlockContainer>
              <Styled.BlockContainer>
                <CompanyProfilePhotosScroll name="companyProfilePhotosScroll" />
                <CompanyProfileImageGallery />
              </Styled.BlockContainer>
            </ErrorBoundary>
          ) : null}
        </Grid.Column>
      </Grid.Row>
    );
  }

  render() {
    const { t } = this.props;
    return (
      <ErrorBoundary>
        <Styled.FullApplyDescriptionContainer position={this.props.position}>
          <Grid stackable>
            {/* Interview guide can never be shown in position when "leftSideExpanded". Because of that we can hide the whole
            leftSideExpanded when interviewGuide is active and not notice it */}
            {this.props.interviewGuide ? null : (
              <Grid.Row
                columns={this.props.position === 'leftSideExpanded' ? 2 : 1}
              >
                <ErrorBoundary
                  tags={{ component: 'JobDescriptionBrandLogo' }}
                  sectionName="Company Logo"
                >
                  <Grid.Column verticalAlign="middle">
                    <Styled.BrandContainer position={this.props.position}>
                      {/* If company profile logo is not uploaded or if it's a blind job don't render this
                  component don't render this component */}
                      {this.props.jobDescription &&
                        this.props.jobDescription.show_company_logo &&
                        this.props.jobDescription.company_logo_url &&
                        this.props.jobDescription.company_logo_url !==
                          '/public/images/missing-company-logo.png' && (
                          <JobDescriptionBrandLogo />
                        )}
                      <JobDescriptionBrandName />
                    </Styled.BrandContainer>
                  </Grid.Column>
                </ErrorBoundary>

                {this.props.position === 'leftSideExpanded' ? (
                  <ErrorBoundary
                    tags={{ component: 'renderApplyFormButtonControl' }}
                    sectionName="Apply button"
                  >
                    <Grid.Column verticalAlign="middle" only="tablet computer">
                      <Styled.ApplyFormButtonContainer>
                        {this.renderApplyFormButtonControl()}
                      </Styled.ApplyFormButtonContainer>
                    </Grid.Column>
                  </ErrorBoundary>
                ) : null}
              </Grid.Row>
            )}

            {this.props.position !== 'rightSideExpanded' ? (
              <ErrorBoundary tags={{ component: 'renderResumeOrDescription' }}>
                {this.renderResumeOrDescription()}
              </ErrorBoundary>
            ) : null}
          </Grid>
        </Styled.FullApplyDescriptionContainer>
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = (state) => ({
  applicant: state.authenticationReducer.applicant,
  appliedJobsList: state.userReducer.appliedJobsList,
  companyProfile: state.companyProfileReducer.companyProfile,
  jobDescription: state.jobDescriptionReducer.jobDescription,
  uploadedResumeFile: state.jobApplicationReducer.uploadedResumeFile,
});

JobDescriptionFullApplyDescription.propTypes = {
  adjustFullApplyFormResponse: PropTypes.func, // we use this function to adjust the API response that we got from API for fullApplyForm. We extract that from response and make it structured to use throughout the application.
  applicant: PropTypes.object, // comes from authenticationReducer. Contains data about the current logged in user.
  appliedJobsList: PropTypes.array, // shows the list of all the jobs current user applied to, quickApply and fullApply jobs (when logged in).
  companyProfile: PropTypes.object, // comes from companyProfileReducer. Contains data about current active company.
  getAppliedJobsListAction: PropTypes.func, // get list of jobs that user applied to. This is for logged in users and users that .made at least one quick apply.
  getFullApplyFormAction: PropTypes.func, // if we already stated filling out full apply form but we did n't submit we use this function to get the current data.
  handleExpandColumns: PropTypes.func, // we pass this prop from parent 'JobDescriptionFullApply' component. We use it to control column expanding and making full apply form bigger.
  jobDescription: PropTypes.object, // comes from jobDescriptionReducer. Contains data about current active job.
  matchData: PropTypes.object, // we pass this prop from parent 'JobDescriptionFullApply' component. It's a route prop 'match' coming from react-router.
  openRegisterModal: PropTypes.func, // use to open RegisterModal.
  openTimeoutImageModal: PropTypes.func, // used to open TimeoutImageModal.
  position: PropTypes.string, // comes from parent 'JobDescriptionFullApply' component and tells us in which positions are columns (rightSideExpanded, leftSideExpanded or equal).
  postNewFullApplyFormAction: PropTypes.func, // use it to post to API and initiate new full apply form (happens when we open full apply form for the fist time for some job).
  startFullApplyFormActivate: PropTypes.func, // used to change property 'startFullApplyFormActivate(redux) that will signal to start / stop autosave interval and to show full apply form
  interviewGuide: PropTypes.bool, // tells us if we should show resume.
  uploadedResumeFile: PropTypes.object, // link to resume that was uploaded by user. Can be different file type formats
};

export default connect(mapStateToProps, {
  openRegisterModal,
  postNewFullApplyFormAction,
  getFullApplyFormAction,
  getAppliedJobsListAction,
  adjustFullApplyFormResponse,
  startFullApplyFormActivate,
  openTimeoutImageModal,
})(withTranslation('jobDescription')(JobDescriptionFullApplyDescription));
