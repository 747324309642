/* !IMPORTANT:  For now the links that we get in email are taking us to 'https://jobs-staging.apploi.com/resetpassword' and 'https://jobs.apploi.com/resetpassword'.
Since this route is still not enabled in API gateway we go to Churchill. There we can reset the password. When API Gateaway is enabled it will take us to this modal in Bolivar.
Important to remember is that when password is reset or this modal is close we are redirecting page to '/search' route.
This is still not done in Bolivar, only in Churchill so even though we are Logged in in Bolivar we are not in Churchill.
If we go back to Bolivar we see that we are logged in and that data is in local Storage. When '/search' route is done in Bolivar
we can use <Redirect/> from react-router-dom and redirect the page to the '/search' route and by doing it like this save the state in Redux store(which is the same as in localStorage) */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal, Form } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import * as Styled from 'authorizationModals_styled';
import {
  closeNewPasswordModal,
  openTimeoutImageModal,
} from '../../../../actions/modals_actions';
import { postNewPasswordApplicantAction } from '../../../../actions/api_actions';
import spinner from '../../../../../../public/assets/img/spinner.gif';

class NewPasswordModal extends Component {
  state = {
    password: '',
    /* test password condition */
    lowercase: false,
    uppercase: false,
    numeral: false,
    length: false,
    /* test inputs on submit */
    passwordSubmit: null,
    /* show error message if submit not good */
    showNewPasswordErrorMsg: false,
    /* spinner */
    showSpinner: false,
  };

  handleFormSubmit = () => {
    const { password } = this.state;
    const { token } = this.props.modalProps;

    const testPasswordSubmit = () =>
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{8,}$/g.test(password);

    const testInputsOnSubmit = async () => {
      const [passwordSubmit] = await Promise.all([testPasswordSubmit()]);
      this.setState(
        {
          passwordSubmit,
        },
        () => {
          if (this.state.passwordSubmit && token) {
            this.finishFormSubmit();
          }
        },
      );
    };

    testInputsOnSubmit();
  };

  configureRedirect = () => {
    const appliedStatus = JSON.parse(
      localStorage.getItem('easyApplyApplicant'),
    );

    /* Since we don't have /search route we are redirecting to the last job thats saved in localstorage when we did quick apply.
    If there is nothing in local storage then TimeoutImageModal stays open indefinitely and we remove option to close it so user needs to use URL
    to go where he wants  */
    if (appliedStatus) {
      window.location.href = `${window.location.origin}/view/${appliedStatus.value.job_id}${window.location.search}`;
    }
  };

  handleInputChange(value, property) {
    this.setState(
      {
        [property]: value,
      },
      () => {
        if (property === 'password') {
          this.testPassword();
        }
      },
    );
  }

  finishFormSubmit() {
    const { password } = this.state;
    const { token } = this.props.modalProps;
    const { t } = this.props;

    /* Set spinner while data is loading */
    this.setState({
      showSpinner: true,
    });

    this.props
      .postNewPasswordApplicantAction(window.activeEnvironment, password, token)
      .then((response) => {
        this.props.openTimeoutImageModal({
          image: 'success',
          bodyMessage: t('passwordReseted'),
          timeout: null,
          pageReload: false,
          redirect: false,
          removeCloseModalOptions: true,
        });
        this.props.closeNewPasswordModal();
        this.configureRedirect();
      })
      .catch((error) => {
        this.setState({
          showSpinner: false,
        });
        /* If there is property email in error message during register
        it means the email is already taken */
        if (error.errors.general[0] === 'link_already_used') {
          this.setState({
            showNewPasswordErrorMsg: t('passwordErrorMessage'),
          });
        }
      });
  }

  testPassword() {
    const { password } = this.state;

    const testLowercase = () => /[a-z]/g.test(password); // test if there is lowercase
    const testUppercase = () => /[A-Z]/g.test(password); // test if there is uppercase
    const testNumeral = () => /[0-9]/g.test(password); // test if there is numeral
    const testLength = () => /.{8}$/g.test(password); // // test if length is more then 8

    const testPasswordCondition = async () => {
      /* Test password conditions and set state after all tests are done */
      const [lowercase, uppercase, numeral, length] = await Promise.all([
        testLowercase(),
        testUppercase(),
        testNumeral(),
        testLength(),
      ]);
      this.setState(
        {
          lowercase,
          uppercase,
          numeral,
          length,
        },
        () => {
          /* First we check if passwordSubmit state is not null. We only change input
          colors after user clicks Submit for the first time.
          At that point passwordSubmit is false/true */
          if (this.state.passwordSubmit !== null) {
            /* If user clicked "Submit" but password conditions were not met field becomes
          red as a warning. When all conditions are good we remove red color by setting
          passwordSubmit state to false */

            if (
              this.state.lowercase &&
              this.state.uppercase &&
              this.state.numeral &&
              this.state.length
            ) {
              this.setState({
                passwordSubmit: true,
              });
            } else {
              this.setState({
                passwordSubmit: false,
              });
            }
          }
        },
      );
    };

    testPasswordCondition();
  }

  render() {
    const { password, lowercase, uppercase, numeral, length, passwordSubmit } =
      this.state;
    const { t } = this.props;
    return (
      <Styled.ModalContainer
        size="mini"
        open
        /* When /search route is done in Bolivar use <Redirect/> from react router
        instead of window. This way we wont reload the page and redux state
        will be saved when redirecting */
        onClose={() => this.configureRedirect()}
        closeIcon
      >
        <Styled.ModalContent>
          <Modal.Description>
            <Styled.ModalHeader>{t('addNewPassword')}</Styled.ModalHeader>

            {this.state.showNewPasswordErrorMsg && (
              <Styled.PopupWarning>
                {this.state.showNewPasswordErrorMsg}
              </Styled.PopupWarning>
            )}
            <Form onSubmit={this.handleFormSubmit}>
              <Styled.FormField>
                <Styled.FormInputs
                  placeholder="Password"
                  onChange={(e) =>
                    this.handleInputChange(e.target.value, 'password')
                  }
                  value={password}
                  passTest={passwordSubmit}
                  type="password"
                />
              </Styled.FormField>
              <Styled.PasswordTestText>
                {t('registerModal:passwordText1')}
                <Styled.PasswordLengthTest passTest={length}>
                  {t('registerModal:passwordText2')}
                </Styled.PasswordLengthTest>
                {t('registerModal:passwordText3')}
                <Styled.PasswordNumeralTest passTest={numeral}>
                  {t('registerModal:passwordText4')}
                </Styled.PasswordNumeralTest>
                <Styled.PasswordLowercaseTest passTest={lowercase}>
                  {t('registerModal:passwordText5')}
                </Styled.PasswordLowercaseTest>
                {t('registerModal:passwordText6')}
                <Styled.PasswordUppercaseTest passTest={uppercase}>
                  {t('registerModal:passwordText7')}
                </Styled.PasswordUppercaseTest>
                {t('registerModal:passwordText8')}
              </Styled.PasswordTestText>
              {this.state.showSpinner ? (
                <img src={spinner} width="50" />
              ) : (
                <Styled.ButtonModal type="submit" authentication="success">
                  {t('resetPassword')}
                </Styled.ButtonModal>
              )}
            </Form>
          </Modal.Description>
        </Styled.ModalContent>
      </Styled.ModalContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  companyProfile: state.companyProfileReducer.companyProfile,
});

NewPasswordModal.propTypes = {
  closeNewPasswordModal: PropTypes.func,
  modalProps: PropTypes.object,
};

export default connect(mapStateToProps, {
  closeNewPasswordModal,
  openTimeoutImageModal,
  postNewPasswordApplicantAction,
})(withTranslation(['resetNewPassword', 'registerModal'])(NewPasswordModal));
