import styled from 'styled-components';
import {
  Form,
  Container,
  Accordion,
  Icon,
  Button,
  TextArea,
  Checkbox,
  Input,
  Radio,
  Pagination,
} from 'semantic-ui-react';
import TimePicker from 'rc-time-picker';

/* Together */
/* PersonalInformationQuestion, EducationQuestion, ExperienceQuestion, TextQuestion, VideoQuestion, SliderQuestion, CalendarQuestion, TimeAvailabilityQuestion, MultipleChoiceQuestion, SignatureQuestion */
/* ================================= */

export const PersonalQuestionContainer = styled.div`
  background-color: #f7f6f6;
  background-color: ${(props) => {
    if (props.statedesign === 'warning') {
      return '#F4DBDC';
    }
    if (props.statedesign === 'success') {
      return '#D4E9D1';
    }
    if (props.statedesign === 'neutral') {
      return '#F7F6F6;';
    }
  }} !important;
  margin-bottom: 24px;
  padding: 20px;
  transition: background-color 0.3s;
`;

export const InputDesignWrapper = styled.div`
  /* search is for the dropdown elements */
  input,
  .search {
    border: ${(props) => {
      if (props.statedesign === 'warning') {
        return '1px solid #ea4750';
      }
      if (props.statedesign === 'success') {
        return '1px solid #59b283';
      }
      if (props.statedesign === 'neutral') {
        return '1px solid #c98fca';
      }
    }} !important;
    transition: border 0.3s;
  }
`;

export const QuestionContainer = styled.div`
  background-color: #f7f6f6;
  background-color: ${(props) => {
    if (props.statedesign === 'warning') {
      return '#F4DBDC';
    }
    if (props.statedesign === 'success') {
      return '#D4E9D1';
    }
    if (props.statedesign === 'neutral') {
      return '#F7F6F6;';
    }
  }} !important;
  margin-bottom: 24px;
  padding: 20px;
  transition: background-color 0.3s;
`;

export const AccordionTitle = styled(Accordion.Title)`
  display: flex !important;
  justify-content: space-between;
  align-items: center;
`;

export const AccordionContent = styled(Accordion.Content)``;

export const AccordionTitleTextContainer = styled.div`
  align-items: center;
  display: flex;
`;

export const QuestionIconBorder = styled.div`
  border: 1.5px solid #a861a9;
  border-radius: 50%;

  i.icon {
    width: 1.14em !important;
  }
`;

export const QuestionIcon = styled(Icon)`
  padding: 0px !important;
  margin: 10px !important;
  color: #a861a9;
  font-size: 18px !important;
`;
export const ExpandIcon = styled(Icon)`
  font-size: 22px !important;
`;

export const DeleteIcon = styled.span`
  color: #005580;
  font-family: 'OpenSansRegular';
  font-size: 14px;
  font-weight: bold;
  margin-top: 10px;
  &:hover {
    cursor: pointer;
  }
`;

export const AccordionTitleText = styled.p`
  display: inline-block;
  font-family: 'OpenSansBold';
  font-size: 16px;
  line-height: 22px;
  margin: 0 12px;

  span {
    font-family: 'OpenSansRegular';
  }
`;

export const FormGroup = styled(Form.Group)`
  justify-content: space-between;
`;

export const FormField = styled(Form.Field)`
  width: 100%;
  margin-bottom: 10px !important;
`;

export const FormFieldContainer = styled(Form.Field)`
  width: 100%;
  margin-bottom: 15px !important;

  .react-datepicker-wrapper {
    width: 100%;

    input {
      width: 100% !important;
    }

    .react-datepicker__close-icon::after {
      background-color: #005580;
    }
  }
`;

export const FormFieldInputTitle = styled.div`
  font-family: 'Neutraface2TextDemi';
  font-size: 16px;
  margin-bottom: 10px !important;
`;

export const QuestionBlock = styled.div`
  margin-top: 25px;
`;

/* EducationQuestion */
export const AddMoreButton = styled.div`
  color: #005580;
  font-family: 'OpenSansRegular';
  font-size: 14px;
  font-weight: bold;
  margin-top: 20px;
  text-align: right;

  &:hover {
    cursor: pointer;
  }
`;

/* TextQuestion, ExperienceQuestion */

export const TextAreaInput = styled(TextArea)`
  border: ${(props) => {
    if (props.statedesign === 'warning') {
      return '1px solid #ea4750';
    }
    if (props.statedesign === 'success') {
      return '1px solid #59b283';
    }
    if (props.statedesign === 'neutral') {
      return '1px solid #c98fca';
    }
  }} !important;
  transition: border 0.3s;
  min-height: 100px;
`;

/* CalendarQuestion, Education, Experience */

export const DatePickerWrapper = styled.div`
  display: flex;
  justify-content: center;

  .react-datepicker-wrapper {
    display: block !important;
    /* width: 100%; */

    input {
      border: ${(props) => {
        if (props.statedesign === 'warning') {
          return '1px solid #ea4750';
        }
        if (props.statedesign === 'success') {
          return '1px solid #59b283';
        }
        if (props.statedesign === 'neutral') {
          return '1px solid #c98fca';
        }
      }} !important;
      /* background: url("img_tree.gif") no-repeat fixed center !important; */
    }

    .react-datepicker__close-icon::after {
      background-color: #005580;
    }

    /* We use this because on iPad screen size (768px) input is touching the edge of screen */
    @media (max-width: 900px) {
      width: 100%;
    }
  }

  .react-datepicker__day-name,
  .react-datepicker__current-month {
    text-transform: capitalize;
  }

  .react-datepicker-popper {
    left: -5px !important;
  }
`;

/* SignatureQuestion */

export const SignaturePostText = styled.div`
  font-family: 'OpenSansRegular';
  font-size: 16px;
  line-height: 17px;
  margin-bottom: 16px;
  text-align: center;
`;
export const SignatureActionText = styled.div`
  font-family: 'Neutraface2TextDemi';
  text-align: center;
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
`;

export const SignatureCanvasWrapper = styled.div`
  text-align: center;
  canvas {
    background-color: #fff;

    border: ${(props) => {
      if (props.statedesign === 'warning') {
        return '1px solid #ea4750';
      }
      if (props.statedesign === 'success') {
        return '1px solid #59b283';
      }
      if (props.statedesign === 'neutral') {
        return '1px solid #c98fca';
      }
    }} !important;
    max-height: 63px;
    max-width: 253px;

    transition: border 0.3s;
    width: 100%;
  }
`;

/* TimeAvailabilityQuestion */

export const TimeFormGroup = styled(Form.Group)`
  display: flex;
  align-items: center;

  .field {
    margin-bottom: 10px !important;
  }

  @media (max-width: 1400px) {
    margin-bottom: 40px !important;
    flex-wrap: wrap;
  }
`;

export const TimeInputFormField = styled(Form.Field)`
  width: 100%;
  min-width: 100px;

  @media (max-width: 767px) {
    padding: 10px;
  }
`;

export const TimePickerComponent = styled(TimePicker)`
  width: 100%;

  && input {
    border-radius: 5px !important;
    height: 36px !important;
  }

  && a {
    top: 5px;
  }
`;

export const CheckboxDay = styled(Checkbox)`
  width: 110px;
  font-family: 'Neutraface2TextDemi';
`;

export const CheckboxAllDay = styled(Checkbox)`
  width: 100px;
`;
export const PredefinedTimesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-family: 'OpenSansRegular';
  font-size: 14px;
  font-weight: 600;
  justify-content: center;
  line-height: 14px;
  text-align: center;
`;

export const PredefinedTimes = styled.div`
  background-color: ${(props) => {
    if (props.customcolor === 'success') {
      return '#7C2E97';
    }

    if (props.customcolor === 'neutral') {
      return '#989298';
    }
  }} !important;
  border-radius: 15px;
  color: #fff;
  margin: 10px 16px;
  padding: 10px;

  &:hover {
    cursor: pointer;
  }
`;

/* SliderQuestion */

export const TextInput = styled(Input)`
  input {
    border: ${(props) => {
      if (props.statedesign === 'warning') {
        return '1px solid #ea4750';
      }
      if (props.statedesign === 'success') {
        return '1px solid #59b283';
      }
      if (props.statedesign === 'neutral') {
        return '1px solid #c98fca';
      }
    }} !important;
    transition: border 0.3s;
  }
`;

export const PaginationSlider = styled(Pagination)`
  a {
    border: 1px solid #a861a9;
    font-family: 'OpenSansBold';
    color: #000 !important;

    &:focus {
      outline: none;
    }
  }

  a.active {
    background-color: #a861a9 !important;
  }
`;

export const SliderContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-evenly;
`;
export const SliderText = styled.div`
  font-family: 'OpenSansRegular';
  font-size: 12px;
  line-height: 14px;
  margin: 0 10px;
  text-align: center;
  width: 80px;
`;
export const SliderClear = styled.div`
  color: #4a90e2;
  font-family: 'OpenSansRegular';
  font-size: 13px;
  line-height: 14px;
  margin-top: 20px;
  text-align: center;

  &:hover {
    cursor: pointer;
  }
`;

/* MultipleChoiceQuestion */

export const MultipleChoiceRadio = styled(Radio)`
  margin-left: 50px;
  font-family: 'OpenSansRegular';

  label {
    &::before {
      border: 1px solid #000 !important;
    }
  }
`;

/* VideoQuestion */

export const VideoQuestionLimit = styled.div`
  font-family: 'OpenSansRegular';
  font-weight: bold;
  margin-bottom: 20px;
`;

export const VideoQuestionRulesContainer = styled.div`
  font-family: 'OpenSansRegular';

  div {
    margin-bottom: 24px;
  }
  span {
    font-weight: bold;
  }
  ul {
    margin: 15px 0 0 0;
    padding: 0 20px;
  }
`;

export const ZiggeoRecorderContainer = styled.div`
  .ba-videorecorder-chooser-button-0 {
    background-color: #66aa97;
  }

  .ba-videorecorder-chooser-button-1,
  .ba-videorecorder-chooser-button-3 {
    color: #005580 !important;
  }

  .ba-videorecorder-chooser-container {
    background-color: #f7f6f6;
    background-color: ${(props) => {
      if (props.statedesign === 'warning') {
        return '#F4DBDC';
      }
      if (props.statedesign === 'success') {
        return '#D4E9D1';
      }
      if (props.statedesign === 'neutral') {
        return '#F7F6F6';
      }
    }} !important;
  }
`;

export const VideoPlayerContainer = styled.div`
  text-align: center;

  video {
    width: 50%;
  }

  @media only screen and (max-width: 767px) {
    video {
      width: 100%;
    }
  }
`;
export const NewVideoAction = styled.div`
  color: #005580 !important;
  font-family: 'OpenSansRegular';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 19px;
  margin-top: 24px;

  &:hover {
    cursor: pointer;
  }
`;

/* JobDescriptionFullApplyForm */
/* ================================= */

export const FullApplyFormContainer = styled(Container)`
  overflow: ${(props) => {
    if (props.customdesign === 'mobile') {
      return 'hidden';
    }
    if (props.customdesign === 'desktop') {
      return 'auto';
    }
  }} !important;

  height: ${(props) => {
    if (props.customdesign === 'mobile') {
      return 'auto';
    }
    /* We need to take 150px so that window overflow doesn't appear */
    if (props.customdesign === 'desktop') {
      return 'calc(100vh - 150px)';
    }
  }} !important;
  padding: ${(props) => {
    if (props.customdesign === 'mobile') {
      return '0px';
    }
    if (props.customdesign === 'desktop') {
      return '20px';
    }
  }} !important;

  display: ${(props) => {
    if (props.hidden === true) {
      return 'block';
    }
    if (props.hidden === false) {
      return 'none';
    }
  }} !important;

  input,
  textarea,
  .dropdown {
    border-radius: 0px !important;
    outline: none !important;
  }
`;

export const FullApplyFormHeader = styled.div`
  font-family: 'Neutraface2TextBold';
  font-size: 24px;
  letter-spacing: 1.14px;
  line-height: 29px;
  margin-bottom: 30px;
  text-align: left;
`;

export const ExpandQuestionAction = styled.div`
  font-family: 'Neutraface2TextDemi';
  font-size: 16px;
  letter-spacing: 1.14px;
  line-height: 19px;
  margin-bottom: 20px;
  margin-top: 40px;
  text-align: right;
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
`;
export const AutosaveCounter = styled.div`
  font-family: 'Neutraface2TextDemi';
  font-size: 16px;
  letter-spacing: 1.14px;
  line-height: 19px;
  margin-bottom: 20px;
  text-align: left;

  &:hover {
    cursor: pointer;
  }
`;

export const SubmitButtonContainer = styled.div`
  text-align: center;
`;

export const SubmitTermsAndConditions = styled.div`
  font-family: 'Neutraface2TextDemi';
  margin-top: 10px;
  display: flex;
  gap: 10px;
  align-items: flex-start;
  text-align: left;
`;

export const SubmitButton = styled(Button)`
  background-color: ${(props) => props.customcolor} !important;
  color: #fff !important;
  font-family: 'Neutraface2TextBold' !important;
  font-size: 18px !important;
  line-height: 14px !important;
  border-radius: 0px !important;
  margin-top: 38px !important;
  margin-right: 0px !important;
`;
export const BackToTopContainer = styled.div`
  font-family: 'OpenSansBold';

  margin-top: 24px;
  text-align: center;

  a {
    font-size: 14px !important;
    color: #000 !important;
  }

  &:hover {
    cursor: pointer;
  }
`;
