import axios from 'axios';
import * as Sentry from '@sentry/browser';

import { apiUrl } from '../../constants/urlConstants';
import { companyProfileRudderstack } from '../rudderstack/rudderstack';
import { logError } from '../../config/sentry';

/* GET REQUEST */
/* ================================================================================= */

/* getCompanyProfile */
/* ------------------------- */

axios.interceptors.request.use(
  (config) => config,
  (error) => {
    Sentry.captureException(error);
    Promise.reject(error);
  },
);

axios.interceptors.response.use(
  (config) => config,
  (error) => {
    logError(Sentry)(error);
    const { data } = error.response;
    console.log('error in axios middlware');
    if (data.developer_message.toLowerCase().includes('token has expired')) {
      console.log('data.developer_message matches our case');
      localStorage.removeItem('token');
      localStorage.removeItem('applicant');
      window.location.reload();
    }
    return Promise.reject(error);
  },
);

export const integrationsToken =
  typeof window !== 'undefined'
    ? localStorage.getItem('token')
      ? JSON.parse(localStorage.getItem('token')).value
      : ''
    : '';

export const getCompanyProfile = (environment, slug) =>
  apiUrl(environment, 'baseUrl', 'companyProfiles').then((url) =>
    axios
      .get(`${url}${slug}`)
      .then((response) => response.data.data)
      .catch((error) => {
        // handle error
        if (error.response.status === 404 || error.response.status === 403) {
          console.log(
            'ERROR COMPANY PROFILE',
            error.response.data.developer_message,
          );

          /* If there is an 404, 403 error with company profile (it doesn't exist, wrong name, unpublished) we use location to push it to unknown page */
          window.location.replace('/profile/company-profile-unknown');
        }
        return error;
      }),
  );

/* getCompanyProfileById
=> same as getCompanyProfile but instead of using 'slug'(name of the company) to fetch
the data we use 'id'(comes from the job description). This way when we got directly to
job description route we can still get company profile data */

/* ------------------------- */
export const getCompanyProfileById = (environment, slug) =>
  apiUrl(environment, 'baseUrl', 'companyProfiles').then((url) =>
    axios
      .get(`${url}?job_id=${slug}`)
      .then((response) => response.data.data)
      .catch((error) => {
        // handle error
        if (error.response.status === 404 || error.response.status === 403) {
          console.log('ERROR COMPANY PROFILE BY ID', error);
          /* If there is an 404, 403 error with fetching the job (it doesn't exist, wrong id, unpublished) we use location to push it to unknown page */
          window.location.replace('/view/job-description-unknown');
        }

        return error;
      }),
  );

/* getCompanyProfileJobs */
/* ------------------------- */

export const getCompanyProfileJobs = (
  environment,
  page,
  searchbar,
  latitude,
  longitude,
  teams,
  filteredIndustries,
  backfilledJobs,
  debug,
  language,
  city,
  state,
  radius,
  utm,
) => {
  const params = {
    utm_source: '',
    utm_campaign: '',
    utm_medium: '',
    language,
    source: backfilledJobs
      ? 'bolivar_internal_search_page'
      : 'company_profile_page',
    location_filter: backfilledJobs ? 1 : 0,
  };
  page && (params.page = page);
  searchbar && (params.searchbar = searchbar);
  latitude && longitude && (params.location = `${latitude},${longitude}`);
  teams && (params.teams = teams);
  filteredIndustries && (params.industry = filteredIndustries);
  backfilledJobs && (params.include_backfilling = backfilledJobs && 1);
  debug && (params.debug = debug && 1);
  city && (params.city = city);
  state && (params.state = state);
  radius && (params.radius = radius);
  utm && (params.utm_source = utm?.utm_source);
  utm && (params.utm_campaign = utm?.utm_campaign);
  utm && (params.utm_medium = utm?.utm_medium);

  return apiUrl(environment, 'integrations', 'searchJobs').then((url) =>
    // We can also use "radius=[number]" (miles) if needed to override parameter on backend
    axios
      .get(`${url}`, {
        params,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${integrationsToken}`,
        },
      })
      .then((response) => {
        // handle success
        /* If page is bigger then 1 that means we are scrolling and loading new jobs */
        if (page > 1 && response.data.data.length > 0) {
          return {
            data: response,
            type: 'saveMoreCompanyProfileJobs',
          };
          /* If page is bigger then 1 and there is no data that means there are no more
          jobs to load so we just return */
        }

        // Validate if the first load is getting jobs from API
        // if not, trigger an alert to Sentry with that info
        if (page === 1 && response.data.data.length === 0) {
          const error = new Error('There are no jobs to load.');
          Sentry.withScope((scope) => {
            scope.setTag('logger', 'company_profile_page');
            scope.setTag('action', 'get_company_profile_page');
            logError(Sentry)(error);
          });
        }

        if (page > 1 && response.data.data.length === 0) {
          return false;
        }
        /* RUDDERSTACK */
        /* ===================================== */
        if (teams) {
          companyProfileRudderstack();
        }
        /* ===================================== */

        /* If page is 1 that means its the first load for that location, keyword, page load... */
        return {
          data: response,
          type: 'saveCompanyProfileJobs',
        };
      })
      .catch((error) => {
        Sentry.withScope((scope) => {
          scope.setTag('logger', 'company_profile_page');
          scope.setTag('action', 'get_company_profile_page');
          logError(Sentry)(error);
        });
        console.log('ERROR OPEN POSITIONS', error);
        return error;
      }),
  );
};

/* getIPInfo */
/* ------------------------- */

const getClientIp = () => axios.get('https://api.ipify.org/?format=json');

const geolocationIntegrationsRequest = (url, ip = '') =>
  axios.get(`${url}${ip}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${integrationsToken}`,
    },
  });

export const getIPInfo = async (environment) => {
  const url = await apiUrl(environment, 'integrations', 'ipInfo');

  try {
    const response = await geolocationIntegrationsRequest(url);

    return response.data.data;
  } catch (e) {
    console.log(e);
    try {
      const { data } = await getClientIp();

      const response = await geolocationIntegrationsRequest(url, data.ip);

      return response.data.data;
    } catch (error) {
      console.log(error);
      const defaultIPInfo = {
        ip: 'default',
        hostname: 'blae.cksjwo.com',
        city: 'New York City',
        region: 'New York',
        country: 'US',
        loc: '40.7185,-74.0025',
        postal: '10013',
        timezone: 'America/New_York',
        asn: {
          asn: 'AS9009',
          name: 'M247 Ltd',
          domain: 'm247.com',
          route: '193.148.18.0/24',
          type: 'isp',
        },
        company: {
          name: 'M247 LTD New York Infrastructure',
          domain: 'm247.com',
          type: 'isp',
        },
        privacy: {
          vpn: true,
          proxy: false,
          tor: false,
          hosting: false,
        },
        abuse: {
          address:
            'Sos. Fabrica de Glucoza, Nr 11B, etaj 1, Sector 2, Bucuresti Romania',
          country: 'RO',
          email: 'abuse@m247.ro',
          name: 'M247 Europe',
          network: '193.148.18.0/24',
          phone: '',
        },
        domains: {
          total: 0,
          domains: [],
        },
        country_name: 'United States',
        latitude: '40.7185',
        longitude: '-74.0025',
      };
      return defaultIPInfo;
    }
  }
};

/* getIndustries */
/* ------------------------- */

export const getIndustries = (environment, city, state, location, radius) =>
  apiUrl(environment, 'integrations', 'industries').then((url) =>
    axios
      .get(`${url}`, {
        params: {
          city,
          state,
          location,
          radius,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${integrationsToken}`,
        },
      })
      .then((response) => response?.data)
      .catch((error) => {
        // handle error
        console.log('ERROR IP INFO', error);
        return error;
      }),
  );

/* getShortenCompanyUrl */
/* ------------------------- */

export const getShortenCompanyUrl = (environment, slug, protocol, hostname) =>
  apiUrl(environment, 'baseUrl', 'shortenLink').then((url) => {
    const getShortenWebsite = (slug) =>
      axios.get(
        `${url}${protocol}%2F%2F${hostname}%2Fprofile%2F${slug}%3Futm_campaign%3Dbolivar%26utm_source%3Dcompany-profile-page`,
      );

    const getShortenFacebook = (slug) =>
      axios.get(
        `${url}${protocol}%2F%2F${hostname}%2Fprofile%2F${slug}%3Futm_campaign%3Dbolivar%26utm_source%3Dfacebook%26utm_medium%3Dsocial-media%26utm_term%3Dcompany-profile-share-link`,
      );

    const getShortenTwitter = (slug) =>
      axios.get(
        `${url}${protocol}%2F%2F${hostname}%2Fprofile%2F${slug}%3Futm_campaign%3Dbolivar%26utm_source%3Dtwitter%26utm_medium%3Dsocial-media%26utm_term%3Dcompany-profile-share-link`,
      );

    const getShortenLinkedin = (slug) =>
      axios.get(
        `${url}${protocol}%2F%2F${hostname}%2Fprofile%2F${slug}%3Futm_campaign%3Dbolivar%26utm_source%3Dlinkedin%26utm_medium%3Dsocial-media%26utm_term%3Dcompany-profile-share-link`,
      );

    return axios
      .all([
        getShortenWebsite(slug),
        getShortenFacebook(slug),
        getShortenTwitter(slug),
        getShortenLinkedin(slug),
      ])
      .then(
        axios.spread((website, facebook, twitter, linkedin) => ({
          website,
          facebook,
          twitter,
          linkedin,
        })),
      );
  });

/* getShortenJobDescriptionUrl */
/* ------------------------- */

export const getShortenJobDescriptionUrl = (
  environment,
  slug,
  protocol,
  hostname,
) =>
  apiUrl(environment, 'baseUrl', 'shortenLink').then((url) => {
    const getShortenWebsite = (slug) =>
      axios.get(
        `${url}${protocol}%2F%2F${hostname}%2Fview%2F${slug}%3Futm_campaign%3Dbolivar%26utm_source%3Djob-description-share-link%26utm_medium%26utm_term`,
      );

    const getShortenFacebook = (slug) =>
      axios.get(
        `${url}${protocol}%2F%2F${hostname}%2Fview%2F${slug}%3Futm_campaign%3Dbolivar%26utm_source%3Dfacebook%26utm_medium%3Dsocial-media%26utm_term%3Djob-description-share-link`,
      );

    const getShortenTwitter = (slug) =>
      axios.get(
        `${url}${protocol}%2F%2F${hostname}%2Fview%2F${slug}%3Futm_campaign%3Dbolivar%26utm_source%3Dtwitter%26utm_medium%3Dsocial-media%26utm_term%3Djob-description-share-link`,
      );

    const getShortenLinkedin = (slug) =>
      axios.get(
        `${url}${protocol}%2F%2F${hostname}%2Fview%2F${slug}%3Futm_campaign%3Dbolivar%26utm_source%3Dlinkedin%26utm_medium%3Dsocial-media%26utm_term%3Djob-description-share-link`,
      );

    return axios
      .all([
        getShortenWebsite(slug),
        getShortenFacebook(slug),
        getShortenTwitter(slug),
        getShortenLinkedin(slug),
      ])
      .then(
        axios.spread((website, facebook, twitter, linkedin) => ({
          website,
          facebook,
          twitter,
          linkedin,
        })),
      );
  });

/* getJobDescription */
/* ------------------------- */

export const getJobDescription = (
  environment,
  slug,
  protocol,
  hostname,
  interviewGuide,
) =>
  apiUrl(environment, 'baseUrl', 'jobDescription').then((url) =>
    axios
      .get(`${url}${slug}`, {
        params: {
          add_job_posting_spec: 1,
          interview_guide: interviewGuide ? '1' : '0',
        },
      })
      .then((response) => response.data.data)
      .catch((error) => {
        // handle error

        if (error.response.status === 404 || error.response.status === 403) {
          console.log('ERROR JOB DESCRIPTION', error);
          /* If there is an 404, 403 error with fetching the job (it doesn't exist, wrong id, unpublished) we use location to push it to unknown page */
          window.location.replace('/view/job-description-unknown');
        }
        return error;
      }),
  );

/* getAppliedJobsList */
/* ------------------------- */
export const getAppliedJobsList = (
  environment,
  slug,
  applicantId,
  interviewGuide,
  token,
) =>
  apiUrl(environment, 'baseUrl', 'appliedJobsList').then((url) =>
    axios
      .get(`${url}?applicant_id=${applicantId}`, {
        /* If interviewGuide is true we call this API with additional property `interview_guide=1`.
          This means that in the response if that job was submitted we will not get submitted=true but false so question temaplate will not be blocked */
        params: {
          interview_guide: interviewGuide ? '1' : '0',
        },
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const appliedJobsList = {
          appliedJobsList: response.data.data,
        };
        return appliedJobsList;
      })
      .catch((error) => {
        console.log('ERROR APPLIED JOBS LIST', error);
        return error;
      }),
  );

/* getNumberOfQuestions */
/* ------------------------- */

export const getNumberOfQuestions = (environment, slug, token) =>
  apiUrl(environment, 'baseUrl', 'numberOfQuestions').then((url) =>
    axios
      .get(`${url}${slug}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => response.data.data)
      .catch((error) => {
        // handle error

        if (error.response.status === 404 || error.response.status === 403) {
          console.log('ERROR NUMBER OF QUESTIONS', error);
          /* If there is an 404, 403 error with fetching the job (it doesn't exist, wrong id, unpublished) we use location to push it to unknown page */
          window.location.replace('/view/job-description-unknown');
        }
        return error;
      }),
  );

/* getFullApplyForm */
/* ------------------------- */

export const getFullApplyForm = (
  environment,
  slug,
  applyFormId,
  token,
  interviewGuide,
  utmData,
) =>
  apiUrl(environment, 'baseUrl', 'appliedJobsList').then((url) =>
    axios
      .get(`${url}/${applyFormId}`, {
        params: {
          interview_guide: interviewGuide ? '1' : '0',
          ...utmData,
        },
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => response.data.data)
      .catch((error) => {
        console.log('ERROR GET FULL APPLY FORM', error);
        throw error;
      }),
  );

/* getUploadedResumeFile */
/* ------------------------- */

export const getUploadedResumeFile = (environment, applicantId, jobId, token) =>
  apiUrl(environment, 'baseUrl', 'processedApplications').then((url) =>
    axios
      .get(`${url}/${applicantId}/${jobId}/resume`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => response.data.data)
      .catch((error) => {
        console.log('ERROR GET UPLOADED RESUME FILE', error);
        return error;
      }),
  );

/* getLicenseVerificationData */
/* ------------------------- */

export const getLicenseVerificationData = (environment, personId) =>
  apiUrl(environment, 'integrations', 'credentialingJobTypeAndState').then(
    (url) =>
      axios
        .get(`${url}`, {
          params: {
            person_id: personId,
          },
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((response) => response.data.credentialData)
        .catch((error) => {
          console.log('ERROR GET LICENSE VERIFICATION DATA', error);
          throw error;
        }),
  );

/* getCredentialingStateFullName */
/* ------------------------- */

export const getCredentialingStateFullName = (environment, state, type) =>
  apiUrl(environment, 'integrations', 'credentialingStateFullName').then(
    (url) =>
      axios
        .get(`${url}`, {
          params: {
            state,
            type,
          },
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((response) => response.data.state)
        .catch((error) => {
          console.log(
            'Error getting state full name via role type, state availability check',
            error,
          );
          throw error;
        }),
  );

/* getWotcData */
/* ------------------------- */
export const getWotcData = (environment, token, id) =>
  apiUrl(environment, 'baseUrl', 'appliedJobsList').then((url) =>
    axios
      .get(`${url}/wotc_data/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => response.data.data)
      .catch((error) => {
        console.error('ERROR GETTING WOTC DATA', error.response.data);
        throw error;
      }),
  );

/* POST REQUEST */
/* ================================================================================= */

/* postNewFullApplyForm - we use this API to initiaze new full apply form for the first time */

export const postNewFullApplyForm = (
  environment,
  jobId,
  token,
  utmData,
  interviewGuide,
) =>
  apiUrl(environment, 'baseUrl', 'appliedJobsList').then((url) =>
    axios
      .post(
        url,
        {
          job_id: Number(jobId),
          ...utmData,
          // interview_guide: interviewGuide ? '1' : '0',
        },
        { headers: { Authorization: `Bearer ${token}` } },
      )
      .then((response) => response.data.data)
      .catch((error) => {
        if (error.response.status === 400) {
          Sentry.withScope((scope) => {
            scope.setTag('logger', 'full_apply');
            logError(Sentry)(error);
          });
        }
        console.log('ERROR POST NEW FULL APPLY FORM', error.response.data);
        throw error;
      }),
  );

/* postJobDescriptionQuickApplyForm - instead of here we are using 'then' and 'catch' on the place
where we are calling this function and using message that comes from API to show it to the user */

export const postJobDescriptionQuickApplyForm = (
  environment,
  easyApplyData,
  jobId,
) =>
  apiUrl(environment, 'integrations', 'easyApply').then((url) =>
    axios
      .post(
        url,
        {
          /* we format it like this to stay consistent with churchill */
          applicant: {
            email: easyApplyData.email,
            fullName: `${easyApplyData.firstName} ${easyApplyData.lastName}`,
            phoneNumber: easyApplyData.phoneNumber,
            resume: easyApplyData.resume,
            source: easyApplyData.applyMethod,
            ...easyApplyData.utmData,
          },
          job: {
            jobId,
          },
          enqueue: false, // this is a property needed on backend in order to quickly process this post request. There was some timeout error bug before this.
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${integrationsToken}`,
          },
        },
      )
      .then((response) => {
        /* Prepare object that we are gonna save to local storage */
        /* -------------------------------------------- */
        const easyApplyApplicant = {
          value: {
            first_name: easyApplyData.firstName,
            last_name: easyApplyData.lastName,
            email: easyApplyData.email,
            phone_number: easyApplyData.phoneNumber,
            apply_method: easyApplyData.applyMethod,
            resume: easyApplyData.resume.file
              ? easyApplyData.resume.file.data
              : null,
            resume_content_type: easyApplyData.resume.file
              ? easyApplyData.resume.file.contentType
              : null,
            resume_file_name: easyApplyData.resume.file
              ? easyApplyData.resume.file.fileName
              : null,
            /* We get this two back in response when user submits application. `person_id` is
          generated based on email and user doesn't need to be logged in to get it */
            job_id: response.data.info.job_id,
            person_id: response.data.info.person_id,
            /* This properties are used in old Churchill app, they have no use but we put them
          just in case */
            device: 'Web',
            job_code: '',
            notify: true,
          },
          /* This properties are also used in old Churchill app, they have no use but we put them
          just in case */
          expire: true,
          owner: 'apploi',
        };
        /* save quick apply data to local storage */
        if (typeof window !== 'undefined' && window.localStorage) {
          localStorage.setItem(
            'easyApplyApplicant',
            JSON.stringify(easyApplyApplicant),
          );
        }

        return easyApplyApplicant;
      })
      .catch((error) => {
        if (error.response.status === 400) {
          Sentry.withScope((scope) => {
            scope.setTag('logger', 'quick_apply');
            logError(Sentry)(error);
          });
        }
        console.log(
          'ERROR POST JOB DESCRIPTION QUICK APPLY FORM',
          error.response.data,
        );
        throw error;
      }),
  );

/* postRegisterApplicant */
/* ======================= */
export const postRegisterApplicant = (environment, email, password) =>
  apiUrl(environment, 'baseUrl', 'applicantsRegister').then((url) =>
    axios
      .post(`${url}`, {
        email,
        password,
      })
      .then((response) => {
        /*
      We get back the same response when user registers for the first time or when he logs
      in so we are saving them to localStorage and redux under same name(property).
      Only thing we are adding extra is the `action` property in case we need to know at one
      point which action took place */
        const authenticationData = {
          expire: true,
          owner: 'apploi',
          action: 'register',
          value: response.data.data.applicant,
        };
        /* save user login data and token to local storage */
        if (typeof window !== 'undefined' && window.localStorage) {
          const tokenData = {
            expire: true,
            owner: 'apploi',
            value: response.data.data.token,
          };

          localStorage.setItem('applicant', JSON.stringify(authenticationData));
          localStorage.setItem('token', JSON.stringify(tokenData));
        }

        return {
          ...authenticationData,
          token: response.data.data.token,
        };
      })
      .catch((error) => {
        console.log('ERROR REGISTER APPLICANT', error.response.data);
        throw error;
      }),
  );

export const postLoginApplicant = (environment, email, password, token) =>
  apiUrl(environment, 'baseUrl', 'applicantsLogin').then((url) => {
    const loginProps = {};

    /* We can login just with token that we get on email or through form with
   email and password. If token is null/undefined it means we are logging in
   through form. Users that auto login through token don't have password and
   after jwt expires or local storage is deleted they will need to go to "forget
    password" in order to create one. */
    if (token === null || token === undefined) {
      loginProps.email = email;
      loginProps.password = password;
    } else {
      loginProps.token = token;
    }

    return axios
      .post(`${url}`, { ...loginProps })
      .then((response) => {
        /*
      We get back the same response when user registers for the first time or when he logs
      in so we are saving them to localStorage and redux under same name(property).
      Only thing we are adding extra is the `action` property in case we need to know at one
      point which action took place. If there is a token that means that user used a link that
      he got on email to do auto login */
        const authenticationData = {
          expire: true,
          owner: 'apploi',
          action: token === null || token === undefined ? 'login' : 'autoLogin',
          value: response.data.data.applicant,
        };

        /* save user login data and token to local storage */
        if (typeof window !== 'undefined' && window.localStorage) {
          const tokenData = {
            expire: true,
            owner: 'apploi',
            value: response.data.data.token,
          };

          localStorage.setItem('applicant', JSON.stringify(authenticationData));
          localStorage.setItem('token', JSON.stringify(tokenData));
        }

        return {
          ...authenticationData,
          token: response.data.data.token,
        };
      })
      .catch((error) => {
        console.log('ERROR LOGIN APPLICANT', error.response.data);
        throw error;
      });
  });

/* postResetPasswordApplicant */
/* ======================= */

export const postResetPasswordApplicant = (environment, email) =>
  apiUrl(environment, 'baseUrl', 'applicantsResetPassword').then((url) =>
    axios
      .post(`${url}`, { email })
      .then((response) => response.data.data)
      .catch((error) => {
        console.log('ERROR RESET PASSWORD APPLICANT', error.response.data);
        throw error;
      }),
  );

/* postNewPasswordApplicant */
/* ======================= */
export const postNewPasswordApplicant = (environment, newpassword, token) =>
  apiUrl(environment, 'baseUrl', 'applicantsNewPassword').then((url) =>
    axios
      .post(`${url}`, {
        newpassword,
        token,
      })
      .then((response) => {
        /*
      We get back the same response when user registers for the first time or when he logs
      in so we are saving them to localStorage and redux under same name(property).
      Only thing we are adding extra is the `action` property in case we need to know at one
      point which action took place */
        const authenticationData = {
          expire: true,
          owner: 'apploi',
          action: 'newPassword',
          value: response.data.data.applicant,
        };

        /* save user login data and token to local storage */
        if (typeof window !== 'undefined' && window.localStorage) {
          const tokenData = {
            expire: true,
            owner: 'apploi',
            value: response.data.data.token,
          };
          localStorage.setItem('applicant', JSON.stringify(authenticationData));
          localStorage.setItem('token', JSON.stringify(tokenData));
        }

        return {
          ...authenticationData,
          token: response.data.data.token,
        };
      })
      .catch((error) => {
        // handle error
        console.log('ERROR NEW PASSWORD APPLICANT', error.response.data);
        throw error;
      }),
  );

/* postFullApplyFormQuestionsAutosave */
/* ======================= */

export const postFullApplyFormQuestionsAutosave = (
  environment,
  answers,
  id,
  token,
  interviewGuide,
) =>
  apiUrl(environment, 'baseUrl', 'appliedJobsList').then((url) => {
    /* turn object to array of values */
    const arrayTransform = Object.values(answers);

    /* Configure payload structure */
    const fullApplicationFormData = {
      answers: arrayTransform,
    };
    /* ----------------------------------------------------------------- */

    return axios
      .post(
        `${url}/${id}/answers?interview_guide=${interviewGuide ? '1' : '0'}`,
        { ...fullApplicationFormData },
        { headers: { Authorization: `Bearer ${token}` } },
      )
      .then((response) => response.data.data)
      .catch((error) => {
        if (error.response.status === 400) {
          Sentry.withScope((scope) => {
            scope.setTag('logger', 'full_apply');
            logError(Sentry)(error);
          });
        }
        console.log(
          'ERROR POST NEW FULL APPLY QUESTIONS AUTOSAVE FORM',
          error.response.data,
        );
        throw error;
      });
  });

/* putFullApplyFormPersonalQuestionsAutosave */
/* ======================= */

export const postFullApplyFormPersonalQuestionsAutosave = ({
  environment,
  personalAnswers,
  id,
  token,
}) =>
  apiUrl(environment, 'baseUrl', 'applicants').then((url) => {
    let dataObject = {};

    dataObject = {
      ...personalAnswers.personQuestion,
      education: personalAnswers.educationQuestion,
      workhistory: personalAnswers.experienceQuestion,
    };

    /* We remove credentialing info from person object since we don't want to overwrite data */
    delete dataObject.credentialing_role;
    delete dataObject.credentialing_expiry_date;
    delete dataObject.credentialing_issue_date;
    delete dataObject.credentialing_verified;
    delete dataObject.credentialing_status;
    delete dataObject.credentialing_license_number;

    return axios
      .put(
        `${url}/${id}`,
        {
          ...dataObject,
        },
        { headers: { Authorization: `Bearer ${token}` } },
      )
      .then((response) => {
        /* Every time we save data of personal questions we update local storage applicant */
        const personObject = { ...response.data.data };
        /* We remove education and workhistory from person object since we wanna save them separately */
        delete personObject.education;
        delete personObject.workhistory;

        const authenticationData = {
          expire: true,
          owner: 'apploi',
          action: 'data update',
          value: personObject,
        };
        /* save user login data and token to local storage */
        if (typeof window !== 'undefined' && window.localStorage) {
          localStorage.setItem('applicant', JSON.stringify(authenticationData));
        }

        /* We need authentication data to save it to redux store. The same data the we update local storage with.
      First time we change something in education, workhistory we send it without `id'`. In response we get back `id's`. */
        return {
          authenticationData,
          fullResponse: response.data.data,
        };
      })
      .catch((error) => {
        if (error.response.status === 400) {
          Sentry.withScope((scope) => {
            scope.setTag('logger', 'full_apply');
            logError(Sentry)(error);
          });
        }
        console.log(
          'POST FULL APPLY FORM PERSONAL QUESTION AUTOSAVE',
          error.response.data,
        );
        throw error;
      });
  });

/* postFullApplyFormQuestions - submit the form */
/* ======================= */

export const postFullApplyFormQuestions = (
  environment,
  id,
  token,
  interviewGuide,
) =>
  axios.all([
    getWotcData(environment, token, id),
    apiUrl(environment, 'baseUrl', 'appliedJobsList').then((url) =>
      axios
        .post(
          `${url}/${id}/submit?interview_guide=${interviewGuide ? '1' : '0'}`,
          {},
          { headers: { Authorization: `Bearer ${token}` } },
        )
        .then((response) => response.data.data)
        .catch((error) => {
          if (error.response.status === 400) {
            Sentry.withScope((scope) => {
              scope.setTag('logger', 'full_apply');
              logError(Sentry)(error);
            });
          }
          console.log('ERROR POST NEW FULL APPLY FORM', error.response.data);
          throw error;
        }),
    ),
  ]);

/* postFullApplyFormResume - submit the uploaded resume for automatic input filling */
/* ======================= */

export const postFullApplyFormResume = (environment, formFile, token) =>
  apiUrl(environment, 'baseUrl', 'resumeParsing').then((url) =>
    axios
      .post(`${url}`, formFile, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        /* Every time we save data of personal questions we update local storage applicant */
        const personObject = { ...response.data.data.person };
        /* We remove education and workhistory from person object since we wanna save them separately */
        delete personObject.education;
        delete personObject.workhistory;

        const authenticationData = {
          expire: true,
          owner: 'apploi',
          action: 'resume upload',
          value: personObject,
        };
        /* save user login data and token to local storage */
        if (typeof window !== 'undefined' && window.localStorage) {
          localStorage.setItem('applicant', JSON.stringify(authenticationData));
        }
        /* We need authentication data to save it to redux store. The same data the we update local storage with.
      We also need full response that still has education and workhistory to save it to redux store for user input fields automatic fill out. */
        return {
          authenticationData,
          fullResponse: response.data.data.person,
        };
      })
      .catch((error) => {
        console.log('ERROR POST FULL APPLY FORM RESUME', error.response.data);
        throw error;
      }),
  );

/* postLicenseVerificationData - submit the form */
/* ======================= */

export const postLicenseVerificationData = (
  environment,
  personId,
  state,
  jobId,
  verificationType,
  licenseNumber,
  ssnNumber,
  birthYear,
  rudderstackFields,
) =>
  apiUrl(environment, 'integrations', 'licenseVerification').then((url) => {
    const dataObject = {
      person_id: personId,
      state,
      job_id: jobId,
      type: verificationType.toUpperCase(),
      license_number: licenseNumber,
      last_four_ssn: ssnNumber,
      birth_year: birthYear,
      rudderstack_fields: {
        ...rudderstackFields,
      },
    };
    return axios
      .post(
        `${url}`,
        { ...dataObject },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
      .then((response) => response.data.validation)
      .catch((error) => {
        console.log(
          'ERROR POST LICENSE VERIFICATION DATA',
          error.response.data,
        );
        throw error;
      });
  });

/* postLicenseVerificationData - submit the form */
/**
 *
 * @param {object} streamlineFields First Name, Last name and Certificate Number, as object in case more
 * values are added
 *
 * The function takes the data provided from the applicant/employee to verify it against
 * Streamline verify
 * @returns Validation data confirmed/waiting for review or error
 */
/* ======================= */

export const postStreamlineVerificationData = ({
  environment,
  personId,
  state,
  jobId,
  verificationType,
  streamlineFields,
  rudderstackFields,
}) =>
  apiUrl(environment, 'integrations', 'licenseVerification').then((url) => {
    const dataObject = {
      person_id: personId,
      state,
      job_id: jobId,
      type: verificationType.toUpperCase(),
      ...streamlineFields,
      rudderstack_fields: {
        ...rudderstackFields,
      },
    };
    return axios
      .post(
        `${url}`,
        { ...dataObject },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${integrationsToken}`,
          },
        },
      )
      .then((response) => {
        return response.data.status === 202
          ? response.data
          : response.data.validation;
      })
      .catch((error) => {
        console.error(
          'ERROR POST LICENSE VERIFICATION DATA',
          error.response?.data,
        );
        return Promise.reject(error);
      });
  });

/* postVerificationFileUpload - submit the form */
/* ======================= */
export const postVerificationFileUpload = (environment, fileData) =>
  apiUrl(environment, 'integrations', 'uploadLicenseFile').then((url) =>
    axios
      .post(
        `${url}`,
        fileData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${integrationsToken}`,
          },
        },
      )
      .then((response) => response.data.validation)
      .catch((error) => {
        console.log('ERROR POST VERIFICATION FILE UPLOAD', error.response.data);
        throw error;
      }),
  );
