const saveAuthenticationData = (authenticationData, type) => ({
  type: 'SAVE_AUTHENTICATION_DATA',
  /* userData is object that can have different properties so we just spread them */
  payload: {
    authenticationData,
    type,
  },
});

export { saveAuthenticationData };
