import styled from 'styled-components';
import { Button } from 'semantic-ui-react';

export const QuickApplyFormContainer = styled.div.attrs({
  'data-cy': 'quick-apply-form-container',
})``;

export const FormContainer = styled.div`
  background-color: #f6f3f3;
  color: #000;
  padding: 24px;
  text-align: center;

  input {
    &::placeholder {
      color: #423f3d !important;
    }
    border: 1px solid #7c2e97 !important;
  }
`;

export const QuickApplyInfoContainer = styled.div`
  font-family: 'OpenSansRegular';
  font-size: 14px;
  text-align: center;
`;

export const QuickApplyInfoTitle = styled.div.attrs({
  'data-cy': 'quick-apply-info-title',
})`
  font-family: 'Neutraface2TextDemi';
  font-size: 24px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 24px;
`;

export const StartOverText = styled.span`
  color: #005580;
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
`;

export const QuickApplyInfo = styled.div`
  border: 1px solid #7c2e97;
  line-height: 14px;
  margin: 0 5% 16px 5%;
  padding: 10px;
  text-align: left;

  span {
    font-weight: bold;
  }
`;
export const QuickApplyInfoText = styled.div`
  margin-bottom: 24px;
`;

export const InfoContainer = styled.div`
  padding: 8px;
`;

export const ActionButton = styled(Button)`
  background-color: ${(props) => props.customcolor} !important;
  color: #fff !important;
  border-radius: 0px !important;
  font-size: 14px !important;
  font-family: 'Neutraface2TextBold' !important;
`;
export const SubmitButton = styled.button`
  background-color: ${(props) => props.customcolor} !important;
  border: none;
  color: #fff;
  font-family: 'Neutraface2TextBold' !important;
  font-size: 14px;
  font-weight: bold;
  height: 28px;
  letter-spacing: normal;
  line-height: 1;
  width: 208px;

  &:hover {
    cursor: pointer;
  }
`;

export const SubmitButtonText = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
`;

export const FooterText = styled.p`
  font-size: 12px;
  line-height: 14px;
  margin-top: 12px;

  a {
    color: #005580;
  }
`;
export const FileInputContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;

  label {
    input {
      display: none;
    }
  }
`;

export const FileInputText = styled.span`
  color: ${(props) => props.resumeUploadedColor} !important;
  font-family: 'NeutrafaceBold';
  margin: 0 2px;
  text-align: center;
  &:hover {
    cursor: pointer;
  }
`;

export const WarningPopupText = styled.div`
  color: red !important;
  font-family: 'NeutrafaceBold';
  font-size: 16px !important;
  margin-bottom: 5px;
  text-align: center;
`;

export const FileInputSubText = styled.span`
  color: ${(props) => props.resumeUploadedColor} !important;
  font-family: 'Neutraface';
  margin: 0 2px;
  text-align: center;
`;

export const SubmitButtonContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  text-align: center;
`;

export const SectionHeader = styled.div`
  font-family: 'Neutraface2TextBold';
  font-size: 18px;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    font-size: 24px;
  }
`;
export const ButtonAndCheckboxContainer = styled.div`
  margin-top: 10px;
  display: flex;
  gap: 10px;
  align-items: flex-start;
  text-align: left;
`;
