import styled from 'styled-components';
import { Dropdown, Loader } from 'semantic-ui-react';

export const IndustriesFilterContainer = styled.div``;

export const IndustriesHeaderTitle = styled.h3`
  border-bottom: 2px solid #795548;
  color: #939598;
  font-size: 1.2rem;
  letter-spacing: 0.2rem;
  margin-top: 3rem;
  margin-top: 1.3rem;
  padding: 0.8rem;
  padding-left: 1.3rem;
`;

export const IndustriesBodyCheckboxes = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 30rem;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const FilterContainer = styled.div`
  margin: 0.5rem;
  width: 100%;
`;

export const FilterDropdown = styled(Dropdown)`
  &&& {
    margin-right: 0.5rem;
    white-space: nowrap;
    i.dropdown.icon {
      vertical-align: text-top;
    }
    div.text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    div.visible.menu.transition {
      max-height: 25rem;
      overflow-y: auto;
    }
  }
`;

export const DropdownLoader = styled(Loader)`
  &&&&&& {
    width: 5rem;
  }
`;
