import React from 'react';
import { useSelector } from 'react-redux';
import { JobDescriptionField } from 'jobDescriptionField_styled';
import { useTranslation } from 'react-i18next';

const levelOfEducation = {
  CERTIFICATE: 'certificate',
  HIGH_SCHOOL: 'highSchool',
  ASSOCIATE: 'associate',
  BACHELORS: 'bachelors',
  MASTER: 'master',
  OTHER: 'other',
  NONE: 'none',
};

const JobDescriptionLevelOfEducation = () => {
  const jobDetail = useSelector(
    (state) => state.jobDescriptionReducer.jobDescription?.job_detail,
  );
  const { t } = useTranslation('jobLevelOfEducation');

  return (
    <JobDescriptionField>
      <h3>{t('educationTitle')}</h3>
      <p>{t(levelOfEducation[jobDetail?.min_education_required])}</p>
    </JobDescriptionField>
  );
};

export default JobDescriptionLevelOfEducation;
